import React from "react";

import { useAuth } from "@/auth";
import { localisedPath } from "@/components";
import routes from "@/constants/routes";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { ChevronBack } from "@/assets/images";

import { Button, Progress } from "@/components/ui";

import { handleBackClick } from "./SessionScheduling";
import { useSteps } from "./SessionScheduling";

const StepHeader: React.FC<{ fromSessionsList?: boolean }> = ({ fromSessionsList }) => {
  const steps = ["step1", "step2", "step3"];
  const [progress, setProgress] = React.useState(5);
  const totalSteps = steps.length;
  const { t } = useTranslation();
  const { currentStep, setCurrentStep } = useSteps();
  const navigate = useNavigate();
  const { specialistId, serviceId, slot, visitId } = useParams<{
    specialistId: string;
    serviceId: string;
    slot: string;
    visitId: string;
  }>();
  const { isAuthenticated, login, authLoading } = useAuth();

  React.useEffect(() => {
    const currentStepIndex = steps.indexOf(currentStep) + 1;
    const progress = 5 + ((currentStepIndex - 1) / (totalSteps - 1)) * 90;
    setProgress(progress);
  }, [currentStep]);

  type StepTexts = {
    [key: string]: string;
  };

  const stepTexts: StepTexts = {
    step1: "sessionDetails",
    step2: "patientData",
    step3: "paymentDetails",
  };

  const backToVisitsList = () => {
    navigate(localisedPath(routes.myVisits));
  };

  return (
    <>
      <header>
        <div className="flex flex-row items-center justify-between bg-white p-3">
          <div className="flex flex-row items-center gap-3.5">
            <img
              src={ChevronBack}
              onClick={() =>
                fromSessionsList
                  ? backToVisitsList()
                  : handleBackClick(currentStep, setCurrentStep, navigate, specialistId, serviceId, slot, visitId)
              }
              className="min-h-3.5 min-w-3.5 cursor-pointer lg:h-10"
              alt="Back"
            />
            <div className="flex flex-col">
              {!fromSessionsList && (
                <span className="font-normal uppercase leading-[15px] text-secondaryText lg:text-base lg:leading-relaxed">
                  {t("sessionScheduling.step")} {steps.indexOf(currentStep) + 1}/{totalSteps}
                </span>
              )}
              <span className="text-base font-semibold lg:text-2xl lg:leading-tight">
                {fromSessionsList
                  ? t(`sessionScheduling.paymentDetails`)
                  : t(`sessionScheduling.${stepTexts[currentStep]}`)}
              </span>
            </div>
          </div>
          {!authLoading && !isAuthenticated() && (
            <Button
              variant="yellow"
              className="mr-4 hidden min-w-40 rounded-lg uppercase shadow-yellow lg:block"
              onClick={login}
            >
              {t("navbar.logIn")}
            </Button>
          )}
        </div>
      </header>
      <Progress value={progress} className="w-[100%]" />
    </>
  );
};

export { StepHeader };
