enum Role {
  Patient = "patient",
  Admin = "admin",
  Manager = "manager",
  Specialist = "specialist",
  Client = "client",
}

enum SessionStatus {
  Draft = "draft",
  Booked = "booked",
  Confirmed = "confirmed",
  Started = "started",
  Finished = "finished",
  Missed = "missed",
  Cancelled = "cancelled",
}

enum PaymentStatus {
  Pending = "pending",
  Completed = "completed",
  Cancelled = "canceled",
}

enum CommunicationMode {
  Video = "video_call",
  Phone = "phone_call",
  Chat = "text_chat",
}

enum VoucherStatus {
  Active = "active",
  Inactive = "inactive",
}

enum VoucherUsage {
  Yes = "yes",
  No = "no",
}

enum SpecialistsView {
  Grid = "grid",
  List = "list",
}

export { Role, SessionStatus, PaymentStatus, CommunicationMode, VoucherStatus, VoucherUsage, SpecialistsView };
