import { useState } from "react";

import { Button, Input } from "@/components";
import { setSearchTerm } from "@/store/slices";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { SearchIcon, XMark } from "@/assets/images";

import { cn } from "@/lib/utils";

type SearchProps = {
  onClose?: () => void;
  handleSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function Search({ onClose, handleSearchChange }: SearchProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    handleSearchChange && handleSearchChange(e);
  };

  const handleClearSearch = () => {
    setInputValue("");
    dispatch(setSearchTerm(""));
    onClose && onClose();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setSearchTerm(inputValue));
  };

  return (
    <form onSubmit={handleSubmit} className="flex w-full items-center gap-2">
      <div className="border-1 flex h-[40px] w-full items-center rounded-lg border border-veryLightGray bg-white lg:max-w-60">
        <div className="h-full w-full items-center text-sm font-medium">
          <Input
            autoFocus
            type="text"
            value={inputValue}
            placeholder={t("specialistsPage.search.searchPlaceholder")}
            className="lg:w-stretch h-9 w-full border-0 bg-inherit"
            onChange={handleChange}
          />
        </div>
        <div className={cn("hidden", inputValue == "" ? "" : "lg:block")}>
          <Button
            variant="ghost"
            type="button"
            size="xs"
            className="self-start rounded-full hover:bg-white"
            onClick={handleClearSearch}
          >
            <img src={XMark} alt={t("general.alt.close")} className="min-w-[14px]" />
          </Button>
        </div>
      </div>
      <Button variant="yellow" className="mr-1 hidden p-3 lg:block">
        <img src={SearchIcon} alt={t("general.alt.search")} className="min-h-3.5 min-w-3.5" />
      </Button>
      <Button
        type="button"
        variant="gray"
        size="xs"
        className="rounded-full py-2 lg:hidden"
        onClick={handleClearSearch}
      >
        <img src={XMark} alt={t("general.alt.close")} className="min-w-[14px]" />
      </Button>
    </form>
  );
}

export { Search };
