import { Trash2 } from "lucide-react";
import { useTranslation } from "react-i18next";

import { ThinXMark, XCircle } from "@/assets/images";

import {
  Button,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui";

type CancelSessionDrawerProps = {
  eligibleForRefund: boolean;
  cancel: () => void;
  apiError: string | undefined;
  visitCancelled: boolean;
  handleVisitCancel: () => void;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
};

const CancelSessionDrawer: React.FC<CancelSessionDrawerProps> = ({
  eligibleForRefund,
  cancel,
  apiError,
  visitCancelled,
  handleVisitCancel,
  drawerOpen,
  setDrawerOpen,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer onOpenChange={(open) => setDrawerOpen(open)} open={drawerOpen}>
      <DrawerTrigger asChild>
        <Button variant="link" className="m-auto block max-w-sm text-orange-800 underline hover:text-orange-950">
          {t("sessionButtons.cancelSession")}
        </Button>
      </DrawerTrigger>
      <DrawerContent className="text-secondaryText">
        <div className="mx-auto w-full p-6">
          <div className="flex flex-row justify-between md:justify-end">
            <DrawerHeader>
              <DrawerTitle>
                <div className="pb-6">
                  <DrawerClose asChild>
                    <img
                      src={ThinXMark}
                      alt={t("specialistsPage.filters.close")}
                      className="min-h-6 min-w-6 cursor-pointer"
                      onClick={cancel}
                    />
                  </DrawerClose>
                </div>
              </DrawerTitle>
            </DrawerHeader>
          </div>
          {!visitCancelled && (
            <div className="space-y-3 px-6 text-center">
              <Trash2 color="#a6a6a6" size={36} strokeWidth={1} className="m-auto" />
              <h3 className="text-lg font-bold">{t("sessionDetails.cancelCofirmationTitle")}</h3>
              {eligibleForRefund ? (
                <p>{t("sessionDetails.cancelSessionInfo")} </p>
              ) : (
                <p>{t("sessionDetails.cancelSessionInfoNoRefund")}</p>
              )}
              {apiError && <div className=" text-redApp">{apiError}</div>}
            </div>
          )}
          {visitCancelled && (
            <div className="space-y-3 px-6 text-center">
              <img src={XCircle} className="m-auto" />
              <h3 className=" text-lg font-bold  ">{t("sessionDetails.cancelCofirmation")}</h3>
              {eligibleForRefund ? (
                <p className="">{t("sessionDetails.cancelCofirmationWithRefund")}</p>
              ) : (
                <p>{t("sessionDetails.cancelCofirmationWithoutRefund")}</p>
              )}
            </div>
          )}
          <DrawerFooter className="flex w-full gap-2 pt-5">
            <DrawerClose asChild>
              <Button className={`${visitCancelled ? "grow" : ""} text-secondaryText`} onClick={cancel}>
                {t("specialistsPage.filters.close")}
              </Button>
            </DrawerClose>
            {!visitCancelled && (
              <Button variant="destructive" className="grow py-2" onClick={handleVisitCancel}>
                {t("sessionButtons.cancelSession")}
              </Button>
            )}
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export { CancelSessionDrawer };
