import { useState } from "react";

import {
  SpecialistCardDesktopGrid,
  SpecialistCardDesktopList,
  SpecialistCardGridSkeleton,
  SpecialistCardListSkeleton,
  SpecialistCardMobile,
  SpecialistCardMobileSkeleton,
  localisedPath,
} from "@/components";
import routes from "@/constants/routes";
import { useGetSpecialistsQuery } from "@/store";
import { selectFilteredSpecialists } from "@/store/selectors";
import { selectView } from "@/store/slices";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { isDesktop } from "@/lib/mediaQuery";
import { ApiError, formatErrorMessage } from "@/lib/utils";

import { SpecialistsView } from "@/types/enums";

const SpecialistsList = () => {
  const { i18n } = useTranslation();
  const view = useSelector(selectView);
  const navigate = useNavigate();
  const desktop = isDesktop();
  const { error, isLoading } = useGetSpecialistsQuery({
    lang: i18n.language,
  });

  const specialists = useSelector(selectFilteredSpecialists);

  const [activeSpecialistId, setActiveSpecialistId] = useState<number | null>(null);
  const onSpecialistSlotClick = (specialistId: number | null) => {
    setActiveSpecialistId(specialistId);
  };

  function isSpecialistSelected(specialistId: number) {
    return specialistId === activeSpecialistId;
  }

  const handleCardClick = (slug: string) => {
    navigate(localisedPath(routes.specialistDetails(slug)));
  };

  if (desktop && view === SpecialistsView.Grid) {
    return (
      <div data-testid="specialist-list-grid" className="grid w-full grid-cols-4 gap-x-1 gap-y-8">
        {isLoading && (
          <>
            {Array.from({ length: 8 }).map((_, index) => (
              <SpecialistCardGridSkeleton key={index} />
            ))}
          </>
        )}
        {specialists?.map((specialist) => (
          <SpecialistCardDesktopGrid
            specialist={specialist}
            key={specialist.id}
            onClick={() => handleCardClick(specialist.slug)}
          />
        ))}
      </div>
    );
  } else if (desktop && view === SpecialistsView.List) {
    return (
      <div data-testid="specialist-list-list" className="w-full space-y-5">
        {isLoading && (
          <>
            <SpecialistCardListSkeleton />
            <SpecialistCardListSkeleton />
            <SpecialistCardListSkeleton />
          </>
        )}
        {error && formatErrorMessage(error as ApiError)}
        {specialists?.map((specialist) => (
          <SpecialistCardDesktopList
            specialist={specialist}
            key={specialist.id}
            selected={isSpecialistSelected(specialist.id)}
            onSpecialistSlotClick={onSpecialistSlotClick}
          />
        ))}
      </div>
    );
  }

  return (
    <div data-testid="specialist-list-mobile" className="w-full space-y-5">
      {isLoading && (
        <>
          <SpecialistCardMobileSkeleton />
          <SpecialistCardMobileSkeleton />
          <SpecialistCardMobileSkeleton />
        </>
      )}
      {error && formatErrorMessage(error as ApiError)}
      {specialists?.map((specialist) => (
        <SpecialistCardMobile
          specialist={specialist}
          key={specialist.id}
          selected={isSpecialistSelected(specialist.id)}
          onSpecialistSlotClick={onSpecialistSlotClick}
        />
      ))}
    </div>
  );
};

export { SpecialistsList };
