import { AuthGuard, oktaAuth } from "@/auth";
import {
  LanguageHandler,
  PatientDataForm,
  PaymentDetailsForm,
  SessionDetailsForm,
  SetDocumentTitle,
} from "@/components";
import routes from "@/constants/routes";
import {
  BookingConfirmationPage,
  LoginRedirectPage,
  MainLayout,
  MySessionsPage,
  MyVouchersPage,
  NotFoundPage,
  PaymentFromVisitsPage,
  PaymentLoadingPage,
  PaymentPage,
  SessionDetailsPage,
  SpecialistIframePage,
  SpecialistPage,
  SpecialistsIframePage,
  SpecialistsListPage,
} from "@/pages";
import { LoginCallback, Security } from "@okta/okta-react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { restoreOriginalUri } from "./main";

function App() {
  return (
    <Router>
      <SetDocumentTitle />
      <LanguageHandler />
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Routes>
          <Route path={routes.authCallback} element={<LoginCallback />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route element={<MainLayout />}>
            <Route
              path={routes.bookingConfirmation()}
              element={
                <AuthGuard>
                  <BookingConfirmationPage />
                </AuthGuard>
              }
            />
            <Route path={routes.root} element={<SpecialistsListPage />} />
            <Route path={routes.specialists} element={<SpecialistsListPage />} />
            <Route path={routes.specialistDetails()} element={<SpecialistPage />} />
            <Route path={routes.specialistPreview()} element={<SpecialistPage />} />
            <Route
              path={routes.myVisits}
              element={
                <AuthGuard>
                  <MySessionsPage />
                </AuthGuard>
              }
            />
            <Route
              path={routes.myVouchers}
              element={
                <AuthGuard>
                  <MyVouchersPage />
                </AuthGuard>
              }
            />
          </Route>
          <Route
            path={routes.paymentFromVisitsPage()}
            element={
              <AuthGuard>
                <PaymentFromVisitsPage />
              </AuthGuard>
            }
          />
          <Route
            path={routes.sessionDetails()}
            element={
              <AuthGuard>
                <SessionDetailsPage />
              </AuthGuard>
            }
          />
          <Route element={<PaymentPage />}>
            <Route path={routes.scheduleSessionDetails()} element={<SessionDetailsForm />} />
            <Route
              path={routes.schedulePatientData()}
              element={
                <AuthGuard>
                  <PatientDataForm />
                </AuthGuard>
              }
            />
            <Route
              path={routes.schedulePaymentDetails()}
              element={
                <AuthGuard>
                  <PaymentDetailsForm />
                </AuthGuard>
              }
            />
          </Route>
          <Route path={routes.specialistsListIframe} element={<SpecialistsIframePage />} />
          <Route path={routes.specialistIframe()} element={<SpecialistIframePage />} />
          <Route path={routes.payment()} element={<PaymentLoadingPage />} />
          <Route path={routes.login} element={<LoginRedirectPage />} />
        </Routes>
      </Security>
    </Router>
  );
}

export default App;
