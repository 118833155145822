import { selectSpecialistQueryParams } from "@/store/slices";
import { createSelector } from "@reduxjs/toolkit";

export const selectSpecialistsFilters = createSelector(selectSpecialistQueryParams, (state) => ({
  specialisations: state.specialisations,
  languages: state.languages,
}));

export const selectSpecialistsSortOptions = createSelector(selectSpecialistQueryParams, (state) => ({
  sortBy: state.sortBy,
  sortOrder: state.sortOrder,
}));
