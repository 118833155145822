import { useState } from "react";
import React from "react";

import { useCreateReviewMutation } from "@/store/apis";
import { selectUser } from "@/store/slices";
import { zodResolver } from "@hookform/resolvers/zod";
import { Separator } from "@radix-ui/react-menubar";
import { Rating } from "@smastrom/react-rating";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { z } from "zod";

import { ThinXMark } from "@/assets/images";
import { customStyles } from "@/assets/styles/rating";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogTrigger,
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Textarea,
} from "@/components/ui";

import { ApiError, formatErrorMessage } from "@/lib/utils";

import { VisitSpecialistDto } from "@/types/types";

const formSchema = z.object({
  rating: z.number({ required_error: t("ratingDrawer.rateSpecialist") }),
  feedback: z.string().optional(),
});

type formType = z.infer<typeof formSchema>;

const RatingDrawer = ({
  specialist,
  triggerButtonClassName,
}: {
  specialist: VisitSpecialistDto;
  triggerButtonClassName: string;
}) => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [createReview] = useCreateReviewMutation();
  const user = useSelector(selectUser);
  const [apiError, setApiError] = useState<string | undefined>("");

  const handleSubmit = async (data: formType) => {
    if (!user || user.patient_id == null) return;
    try {
      await createReview({
        review: {
          author_id: user.patient_id,
          specialist_id: specialist.id,
          rating: data.rating,
          content: data.feedback || "",
        },
      });
      setDrawerOpen(false);
      setDialogOpen(false);
    } catch (error) {
      setApiError(formatErrorMessage(error as ApiError));
    }
  };

  const cancel = () => {
    setDrawerOpen(false);
    setDialogOpen(false);
    form.clearErrors("rating");
    form.reset();
  };

  const form = useForm<formType>({
    defaultValues: {},
    mode: "onBlur",
    resolver: zodResolver(formSchema),
  });

  const ratingForm = () => {
    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col space-y-8">
          <div className="mx-auto w-full p-6">
            <div className="flex flex-row justify-between md:justify-end">
              <div className="mb-4 flex flex-row gap-3">
                {drawerOpen && (
                  <DrawerClose asChild onClick={cancel}>
                    <img
                      src={ThinXMark}
                      alt={t("specialistsPage.filters.close")}
                      className="min-h-6 min-w-6 cursor-pointer"
                    />
                  </DrawerClose>
                )}
                {dialogOpen && (
                  <AlertDialogCancel asChild onClick={cancel} className="border-none">
                    <img
                      src={ThinXMark}
                      alt={t("specialistsPage.filters.close")}
                      className="min-h-6 min-w-6 cursor-pointer"
                    />
                  </AlertDialogCancel>
                )}
              </div>
            </div>
            <div className="md:px-6">
              <div className="flex flex-wrap content-start items-start gap-[10px_8px]">
                <div className="flex gap-3 ">
                  <Avatar className="h-12 w-12 rounded lg:rounded-lg">
                    <AvatarImage
                      src={specialist?.listing_photo}
                      alt={t("general.alt.photo")}
                      className="h-12 w-12 object-cover"
                    />
                    <AvatarFallback>CN</AvatarFallback>
                  </Avatar>
                  {dialogOpen && (
                    <AlertDialogDescription>
                      <div className="flex flex-col text-sm font-normal">
                        <span className="font-semibold text-primaryText">
                          {specialist?.first_name} {specialist?.last_name}
                        </span>
                        <span className="text-secondaryText">
                          {specialist.specialisations?.map((s) => s.display_name).join(", ")}
                        </span>
                      </div>
                    </AlertDialogDescription>
                  )}
                  {drawerOpen && (
                    <div className="flex flex-col text-sm font-normal">
                      <span className="font-semibold text-primaryText">
                        {specialist?.first_name} {specialist?.last_name}
                      </span>
                      <span className="text-secondaryText">
                        {specialist.specialisations?.map((s) => s.display_name).join(", ")}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <Separator className="my-3 h-px bg-veryLightGray" />
              {drawerOpen && (
                <div className="px-3 text-center text-lg/[18px] font-bold text-primaryText">
                  {t("ratingDrawer.rateAppointment")}
                </div>
              )}
              {dialogOpen && (
                <AlertDialogTitle>
                  <div className="px-3 text-center text-lg/[18px] font-bold text-primaryText">
                    {t("ratingDrawer.rateAppointment")}
                  </div>
                </AlertDialogTitle>
              )}
              <div className="flex justify-center py-2">
                <FormField
                  control={form.control}
                  name="rating"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormControl>
                        <Rating
                          className={"!w-fit focus-within:outline-none [&>div]:aspect-square [&>div]:h-10 [&>div]:w-10"}
                          itemStyles={customStyles}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage className="text-sm font-normal" />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="feedback"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormControl>
                      <Textarea className="text-base" placeholder={t("ratingDrawer.typeFeedback")} {...field} />
                    </FormControl>
                    <FormMessage className="text-base font-normal" />
                  </FormItem>
                )}
              />
              {apiError && <p className="text-redApp">{apiError}</p>}
              {drawerOpen && (
                <DrawerFooter className="flex w-full gap-2">
                  <DrawerClose asChild>
                    <Button variant="outline" className="text-secondaryText" onClick={cancel}>
                      {t("specialistsPage.filters.close")}
                    </Button>
                  </DrawerClose>
                  <Button variant="yellow" className="grow" onSubmit={form.handleSubmit(handleSubmit)}>
                    {t("ratingDrawer.submitReview")}
                  </Button>
                </DrawerFooter>
              )}
              {dialogOpen && (
                <AlertDialogFooter className="flex w-full gap-2 pt-3">
                  <AlertDialogCancel asChild>
                    <Button variant="outline" className="text-secondaryText" onClick={cancel}>
                      {t("specialistsPage.filters.close")}
                    </Button>
                  </AlertDialogCancel>
                  <Button variant="yellow" className="grow" onSubmit={form.handleSubmit(handleSubmit)}>
                    {t("ratingDrawer.submitReview")}
                  </Button>
                </AlertDialogFooter>
              )}
            </div>
          </div>
        </form>
      </Form>
    );
  };

  return (
    <>
      <div className="block w-1/2 md:hidden">
        <Drawer
          open={drawerOpen}
          onOpenChange={(open) => {
            setDrawerOpen(open);
            if (!open) {
              form.reset();
              form.clearErrors("rating");
            }
          }}
        >
          <DrawerTrigger asChild>
            <Button variant="outline" className={`${triggerButtonClassName}`}>
              {t("sessionButtons.reviewSession")}
            </Button>
          </DrawerTrigger>
          <DrawerContent className="text-secondaryText">{ratingForm()}</DrawerContent>
        </Drawer>
      </div>
      <div className="hidden md:block">
        <AlertDialog
          open={dialogOpen}
          onOpenChange={(open) => {
            setDialogOpen(open);
            if (!open) {
              form.reset();
              form.clearErrors("rating");
            }
          }}
        >
          <AlertDialogTrigger asChild>
            <Button variant="outline" className={`${triggerButtonClassName}`}>
              {t("sessionButtons.reviewSession")}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent className="text-secondaryText">{ratingForm()}</AlertDialogContent>
        </AlertDialog>
      </div>
    </>
  );
};

export { RatingDrawer };
