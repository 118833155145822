import { useTranslation } from "react-i18next";

import { isDesktop } from "@/lib/mediaQuery";

import { QueryBar } from "./QueryBar";
import { QueryBarDesktop } from "./QueryBarDesktop";

type SpecialistsListHeroProps = {
  className?: string;
};

const SpecialistsListHero: React.FC<SpecialistsListHeroProps> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col justify-center gap-3.5 lg:w-full">
      <div>
        <h2 className="text-xl font-semibold lg:text-2xl">{t("specialistsPage.pickASpecialist")}</h2>
        <p className="text-base">{t("specialistsPage.description")}</p>
      </div>
      {(isDesktop() && <QueryBarDesktop className={className} />) || <QueryBar />}
    </div>
  );
};
export { SpecialistsListHero };
