import { VisitDto } from "@/types/types";

import { mhhlApi as api } from "./mhhlApi";

export type CreateVisitApiArg = {
  lang?: string;
  visit: {
    start_time: string;
    specialist_id: number;
    service_id: number;
    status: string;
    additional_info: string;
    patient_ids: number[];
    language?: string;
    communication_mode?: string;
  };
};

export type CreateVisitApiResponse = {
  visit: VisitDto;
  lang?: string;
  phone_number: string;
  phone_number_confirmed: boolean;
};

export type UpdateVisitApiArg = {
  id: number;
  language?: string;
  communication_mode?: string;
  lang?: string;
};

export type UpdatePatientDetailsApiArg = {
  id: number;
  status?: string;
  additional_info?: string;
  phone_number?: string;
  lang?: string;
};

export type BookVisitApiArg = {
  id: number;
  status: string;
  voucher: string;
  lang?: string;
};

type UpdateVisitApiResponse = CreateVisitApiResponse;

type BookVisitApiResponse = {
  reference_id: string;
  status: string;
  payment_method: string;
  redirect_url: string;
};

type GetVisitApiArg = {
  id: number;
  lang?: string;
};

type GetVisitApiResponse = CreateVisitApiResponse;

type GetPastVisitsApiResponse = {
  visits: VisitDto[];
  next_page?: number;
};

type GetPastVisitsApiArg = {
  page?: number;
  lang?: string;
};

type GetUpcomingVisitsApiResponse = GetPastVisitsApiResponse;

type GetUpcomingVisitsApiArg = GetPastVisitsApiArg;

type ValidateVoucherApiResponse = {
  code: string;
  code_valid: boolean;
  discount_amount: string;
  total_amount: string;
};

type ValidateVoucherApiArg = {
  id: number;
  code: string;
};

type ValidatePhoneApiResponse = string;

type ValidatePhoneApiArg = {
  phone_number: string;
  code: string;
};

type GetVisitMeetingApiResponse = {
  user_meeting_url: string;
  phone_code: number;
  phone_numbers: string[];
};

const visitsApi = api.injectEndpoints({
  endpoints: (build) => ({
    createVisit: build.mutation<CreateVisitApiResponse, CreateVisitApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/visits`,
        method: "POST",
        body: queryArg,
        params: { locale: queryArg.lang || "pl" },
      }),
      invalidatesTags: (result) =>
        result
          ? [{ type: "Specialists" }, { type: "SpecialistTimeSlots", id: result?.visit.specialist.id.toString() }]
          : [],
    }),
    updateVisit: build.mutation<UpdateVisitApiResponse, UpdateVisitApiArg>({
      query: ({ id, lang, ...patchData }) => ({
        url: `/api/v1/visits/${id}`,
        method: "PATCH",
        body: { visit: patchData },
        params: { lang: lang || "pl" },
      }),
    }),
    updatePatientDetails: build.mutation<UpdateVisitApiResponse, UpdatePatientDetailsApiArg>({
      query: ({ id, lang, ...patchData }) => ({
        url: `/api/v1/visits/${id}/update_patient_details`,
        method: "PATCH",
        body: { visit: patchData },
        params: { lang: lang || "pl" },
      }),
    }),
    bookVisit: build.mutation<BookVisitApiResponse, BookVisitApiArg>({
      query: ({ id, lang, ...patchData }) => ({
        url: `/api/v1/visits/${id}/book`,
        method: "PATCH",
        body: { visit: patchData },
        params: { lang: lang || "pl" },
      }),
    }),
    getVisit: build.query<VisitDto, GetVisitApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/v1/visits/${queryArg.id}`,
          params: { locale: queryArg.lang || "pl" },
        };
      },
      transformResponse: (response: GetVisitApiResponse) => response.visit,
    }),
    cancelVisit: build.mutation<UpdateVisitApiResponse, UpdateVisitApiArg>({
      query: ({ id }) => ({
        url: `/api/v1/visits/${id}/cancel`,
        method: "PATCH",
      }),
      invalidatesTags: (result) =>
        result
          ? [{ type: "Specialists" }, { type: "SpecialistTimeSlots", id: result?.visit.specialist.id.toString() }]
          : [],
    }),
    getPastVisits: build.query<GetPastVisitsApiResponse, GetPastVisitsApiArg>({
      query: ({ page = 1, lang = "pl" }) => ({
        url: `/api/v1/visits/past`,
        params: { page, locale: lang },
      }),

      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (currentCache.visits) {
          currentCache.visits.push(...newItems.visits);
          currentCache.next_page = newItems.next_page;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getUpcomingVisits: build.query<GetUpcomingVisitsApiResponse, GetUpcomingVisitsApiArg>({
      query: ({ page = 1, lang = "pl" }) => ({
        url: `/api/v1/visits/upcoming`,
        params: { page, locale: lang },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (currentCache.visits) {
          currentCache.visits.push(...newItems.visits);
          currentCache.next_page = newItems.next_page;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    validateVoucherCode: build.query<ValidateVoucherApiResponse, ValidateVoucherApiArg>({
      query: ({ id, code }) => ({
        url: `/api/v1/visits/${id}/validate_voucher_code/${code}`,
        method: "GET",
      }),
    }),
    validatePhoneNumber: build.mutation<ValidatePhoneApiResponse, ValidatePhoneApiArg>({
      query: (body) => ({
        url: `/api/v1/phone_number_confirmation`,
        method: "POST",
        body: body,
      }),
    }),
    getVisitMeeting: build.query<GetVisitMeetingApiResponse, { id: number }>({
      query: ({ id }) => ({
        url: `/api/v1/visits/${id}/meeting`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export { visitsApi };
export const {
  useCreateVisitMutation,
  useUpdateVisitMutation,
  useUpdatePatientDetailsMutation,
  useBookVisitMutation,
  useGetVisitQuery,
  useGetPastVisitsQuery,
  useLazyGetPastVisitsQuery,
  useGetUpcomingVisitsQuery,
  useLazyGetUpcomingVisitsQuery,
  useValidateVoucherCodeQuery,
  useLazyValidateVoucherCodeQuery,
  useValidatePhoneNumberMutation,
  useCancelVisitMutation,
  useGetVisitMeetingQuery,
} = visitsApi;
