import React, { useState } from "react";

import { selectView, setView } from "@/store/slices";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ChevronDown } from "@/assets/images";

import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarSeparator, MenubarTrigger } from "@/components/ui";

import { SpecialistsView } from "@/types/enums";

const ViewSelectDesktop: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialView = useSelector(selectView);

  const [specialistsView, setSpecialistsView] = useState<SpecialistsView>(initialView || SpecialistsView.Grid);

  const changeView = (viewType: SpecialistsView) => {
    setSpecialistsView(viewType);
    dispatch(setView({ viewType }));
  };

  return (
    <Menubar>
      <MenubarMenu>
        <MenubarTrigger
          data-testid="view-switcher"
          className="h-[40px] w-min cursor-pointer text-nowrap rounded-lg border border-veryLightGray px-4 focus:bg-inherit focus:text-secondaryText data-[state=open]:bg-inherit data-[state=open]:text-secondaryText"
        >
          {t(`specialistsPage.filters.${specialistsView}View`)}
          <img src={ChevronDown} className="px-4 opacity-80" />
        </MenubarTrigger>
        <MenubarContent alignOffset={0} sideOffset={4} className="shadow-sm">
          <MenubarItem data-testid="list-view" onClick={() => changeView(SpecialistsView.List)}>
            {t("specialistsPage.filters.listView")}
          </MenubarItem>
          <MenubarSeparator />
          <MenubarItem data-testid="grid-view" onClick={() => changeView(SpecialistsView.Grid)}>
            {t("specialistsPage.filters.gridView")}
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  );
};

export { ViewSelectDesktop };
