import { useEffect, useState } from "react";

import { localisedPath } from "@/components";
import routes from "@/constants/routes";
import { useOktaAuth } from "@okta/okta-react";

import { authLogin } from "./authUtils";

const useAuth = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    if (authState) {
      setAuthLoading(false);
    }
  }, [authState]);

  const login = async () => {
    await authLogin();
  };

  const logout = async () => {
    try {
      const redirectPath = localisedPath(routes.root);
      await oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + redirectPath });
    } catch (error) {
      console.error("Logout failed", error);
      throw error;
    }
  };

  const isAuthenticated = () => {
    return authState?.isAuthenticated;
  };

  return { isAuthenticated, login, logout, authLoading };
};

export { useAuth };
