import { useEffect, useRef, useState } from "react";

import { Separator } from "@radix-ui/react-menubar";
import { Rating } from "@smastrom/react-rating";
import { useTranslation } from "react-i18next";

import { Check } from "@/assets/images";
import { customStyles } from "@/assets/styles/rating";

import { Button } from "@/components/ui";

import { isDesktop } from "@/lib/mediaQuery";
import { formatYearDateFromISO } from "@/lib/utils";

import { SpecialistReviewDto } from "@/types/types";

const ReviewCard = (review: SpecialistReviewDto) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [truncatedText, setTruncatedText] = useState<string | null>(null);
  const [showReadMore, setShowReadMore] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);
  const desktop = isDesktop();

  useEffect(() => {
    if (desktop && textRef.current) {
      const fullText = review.content;
      const containerWidth = textRef.current.clientWidth;
      const charWidth = 8;
      const charsPerLine = Math.floor(containerWidth / charWidth);
      const charsForThreeAndHalfLines = Math.floor(charsPerLine * 3.5);

      if (fullText.length > charsForThreeAndHalfLines) {
        const truncated = fullText.slice(0, charsForThreeAndHalfLines) + "...";
        setTruncatedText(truncated);
        setShowReadMore(true);
      } else {
        setTruncatedText(fullText);
        setShowReadMore(false);
      }
    } else {
      setTruncatedText(review.content);
    }
  }, [review.content, desktop]);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div data-testid="review-card" className="flex flex-col">
      <div className="flex flex-row items-center justify-between lg:justify-normal lg:gap-10">
        <div className="flex flex-row items-center gap-2">
          <img src={Check} className="h-5 w-5" />
          <span className="text-sm font-semibold text-neutral-800 lg:text-base">{review.author.first_name}</span>
        </div>
        <Rating
          readOnly
          value={review.rating}
          className={"!w-fit [&>div]:aspect-square [&>div]:h-4 [&>div]:w-4 lg:[&>div]:h-5 lg:[&>div]:w-5"}
          itemStyles={customStyles}
        />
      </div>

      <div className="py-1">{formatYearDateFromISO(review.created_at)}</div>
      <div className="mt-3">
        <p ref={textRef} className="lg:text-base">
          {isExpanded ? review.content : truncatedText}
          {!isExpanded && showReadMore && desktop && (
            <Button variant="link" onClick={toggleReadMore} className="p-0 font-bold text-secondaryText lg:text-base">
              {t("sessionScheduling.readMore")}
            </Button>
          )}
        </p>
        {isExpanded && desktop && (
          <Button variant="link" onClick={toggleReadMore} className="p-0 font-bold text-secondaryText lg:text-base">
            {t("sessionScheduling.readLess")}
          </Button>
        )}
      </div>
      <Separator className="my-4 h-px bg-veryLightGray" />
    </div>
  );
};

export { ReviewCard };
