import React from "react";

import { VoucherCard } from "@/components";

import { VoucherStatus } from "@/types/enums";
import { VoucherDto } from "@/types/types";

type VouchersListProps = {
  vouchers: VoucherDto[];
  status: VoucherStatus;
  onVoucherClick?: (voucher: VoucherDto) => void;
  selectedVoucherId?: number;
};

const VouchersList = React.memo<VouchersListProps>(
  ({ vouchers, status, onVoucherClick, selectedVoucherId }: VouchersListProps) => {
    if (!vouchers.length) {
      return null;
    }

    return (
      <div className="space-y-5 py-2" data-testid="voucher-list">
        {vouchers.map((voucher: VoucherDto) => (
          <VoucherCard
            key={voucher.id}
            voucher={voucher}
            status={status}
            onClick={onVoucherClick}
            selected={voucher.id === selectedVoucherId}
          />
        ))}
      </div>
    );
  }
);

export { VouchersList };
