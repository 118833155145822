import React from "react";

import { LocalisedLink, RatingDrawer, StatusBadge } from "@/components";
import { localisedPath } from "@/components";
import routes from "@/constants/routes";
import { useGetVisitMeetingQuery } from "@/store/apis";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { More, avatarImage } from "@/assets/images";

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Separator,
} from "@/components/ui";

import { formatTimeInTimeZone, formatYearDateFromISO } from "@/lib/utils";

import { SessionStatus } from "@/types/enums";
import { VisitDto } from "@/types/types";

type SessionCardProps = {
  visit: VisitDto;
};

const SessionCard = React.memo<SessionCardProps>(({ visit }: SessionCardProps) => {
  const { t } = useTranslation();
  const specialist = visit.specialist;
  const { data: meetingData } = useGetVisitMeetingQuery({ id: visit.id });
  const visitStatus = visit.status;
  const canBeReviewed = visit.reviewed;
  const navigate = useNavigate();

  const handleStartSessionClick = () => {
    if (meetingData?.user_meeting_url) {
      window.location.href = meetingData?.user_meeting_url;
    }
  };

  const navigateToPayment = () => {
    const path = routes.paymentFromVisitsPage(
      visit.specialist.id.toString(),
      visit.service.id.toString(),
      visit.start_time,
      visit.id.toString()
    );
    navigate(localisedPath(path));
  };

  return (
    <Card className="mx-auto flex max-w-sm flex-col gap-2 rounded-lg border border-solid border-veryLightGray p-4 sm:max-w-md md:max-w-full md:gap-4">
      <CardHeader className="flex flex-row items-center justify-between gap-12 border-b p-0 pb-2">
        <div className="flex flex-row gap-2">
          <div className="hidden w-28 md:block">
            <StatusBadge status={visitStatus} className="-mt-0.5" communicationMode={visit.communication_mode[0]} />
          </div>
          <span>{formatYearDateFromISO(visit.start_time)}</span>
          <Separator orientation="vertical" className="h-5 bg-veryLightGray" />
          <span className="text-sm font-semibold text-primaryText">{formatTimeInTimeZone(visit.start_time)}</span>
        </div>
        <div className="flex flex-row gap-3">
          <StatusBadge status={visitStatus} className="md:hidden" communicationMode={visit.communication_mode[0]} />
          <Button className="w-auto rounded-full border-0 p-1 px-0 md:h-8 md:w-8 md:border md:py-2" asChild>
            <img src={More} alt={t("general.alt.more")} />
          </Button>
        </div>
      </CardHeader>
      <CardContent className="p-0">
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-row items-start gap-3.5 p-0 md:grow md:items-center">
            <Avatar className="rounded lg:rounded-lg">
              <AvatarImage
                src={visit.specialist.listing_photo ?? avatarImage}
                alt={t("general.alt.photo")}
                className="h-10 w-10 object-cover md:h-[72px] md:w-[72px]"
              />
              <AvatarFallback>CN</AvatarFallback>
            </Avatar>
            <div className="m-0 flex flex-col gap-1">
              <CardTitle className="truncate text-base font-semibold md:text-lg">
                {specialist.first_name} {specialist.last_name}
              </CardTitle>
              <CardDescription className="max-w-60 break-words font-normal text-secondaryText md:max-w-full md:text-sm">
                {specialist.specialisations?.map((s) => s.display_name).join(", ")}
              </CardDescription>
            </div>
          </div>
          <div className="flex gap-2 py-2 md:ml-auto md:w-40 md:flex-col-reverse md:place-content-end md:py-0">
            <Button className="grow text-sm font-bold md:grow-0" asChild>
              <LocalisedLink to={routes.sessionDetails(visit.id.toString())}>
                {t("sessionButtons.details")}
              </LocalisedLink>
            </Button>
            {visitStatus === SessionStatus.Started && (
              <Button variant="yellow" className="grow text-sm font-bold" onClick={handleStartSessionClick}>
                {t("sessionButtons.joinSession")}
              </Button>
            )}
            {visitStatus === SessionStatus.Confirmed && (
              <Button variant="yellow" size="lg" className="grow text-sm font-bold" onClick={handleStartSessionClick}>
                {t("sessionButtons.startSession")}
              </Button>
            )}
            {visitStatus === SessionStatus.Booked && (
              <Button variant="yellow" className={"grow text-sm font-bold"} onClick={navigateToPayment}>
                {t("sessionButtons.payForSession")}
              </Button>
            )}
            {visitStatus === SessionStatus.Finished && canBeReviewed && (
              <RatingDrawer
                specialist={specialist}
                triggerButtonClassName="px-4 py-2 w-full text-sm font-bold md:w-full"
              />
            )}
          </div>
        </div>
        {visitStatus === SessionStatus.Cancelled && (
          <div className="text-sm font-normal text-secondaryText md:mt-4 md:rounded-lg md:bg-backgroundApp md:p-2 md:text-center">
            {t("mySessionsPage.refund")}
          </div>
        )}
      </CardContent>
    </Card>
  );
});
export { SessionCard };
