import { mhhlApi as api } from "./mhhlApi";

const specialisationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSpecialisations: build.query<GetSpecialisationsApiResponse, GetSpecialisationsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/specialisations`,
        params: {
          locale: queryArg.lang || "pl",
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { specialisationsApi };
export type GetSpecialisationsApiResponse = /** status 200 OK */ {
  specialisations?: SpecialisationListDto[];
};

export type GetSpecialisationsApiArg = { lang?: string };
export type SpecialisationListDto = {
  id: number;
  universal_name: string;
};

export const { useGetSpecialisationsQuery } = specialisationsApi;
