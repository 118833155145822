import { MessageSquare, Phone } from "lucide-react";
import { useTranslation } from "react-i18next";

import { Camera } from "@/assets/images";

import { Badge } from "@/components/ui";

import { CommunicationMode, SessionStatus, VoucherStatus } from "@/types/enums";

type StatusBadgeProps = {
  status?: SessionStatus | VoucherStatus | "onlineSession";
  className?: string;
  communicationMode?: string | undefined;
};

const StatusBadge: React.FC<StatusBadgeProps> = ({ status, className, communicationMode }) => {
  const { t } = useTranslation();

  switch (status) {
    case "onlineSession":
      return (
        <Badge variant="green" className={className}>
          {t("sessionStatus.onlineSession")}
          <img src={Camera} alt={t("general.alt.camera")} className="inline pl-1" />
        </Badge>
      );
    case SessionStatus.Booked:
      return (
        <Badge variant="grey" className={className}>
          {t("sessionStatus.booked")}
        </Badge>
      );
    case SessionStatus.Cancelled:
      return (
        <Badge variant="red" className={className}>
          {t("sessionStatus.cancelled")}
        </Badge>
      );
    case SessionStatus.Finished:
      return (
        <Badge variant="grey" className={className}>
          {t("sessionStatus.completed")}
        </Badge>
      );
    case SessionStatus.Missed:
      return (
        <Badge variant="grey" className={className}>
          {t("sessionStatus.missed")}
        </Badge>
      );
    case VoucherStatus.Active:
      return (
        <Badge variant="green" className={className}>
          {t("sessionStatus.active")}
        </Badge>
      );
    case VoucherStatus.Inactive:
      return (
        <Badge variant="red" className={className}>
          {t("sessionStatus.inactive")}
        </Badge>
      );
    case SessionStatus.Confirmed:
      return (
        <Badge variant="green" className={className}>
          {t("sessionStatus.confirmed")}
          {communicationMode === CommunicationMode.Phone && (
            <Phone className="-scale-x-100 transform pr-1" fill="#7CB342" strokeWidth={1.2} size={17} />
          )}
          {communicationMode === CommunicationMode.Chat && (
            <MessageSquare className="inline pl-1" fill="#7CB342" strokeWidth={1.2} size={17} />
          )}
          {communicationMode === CommunicationMode.Video && (
            <img src={Camera} alt={t("general.alt.camera")} className="inline pl-1" />
          )}
        </Badge>
      );
    case SessionStatus.Started:
      return (
        <Badge variant="green" className={className}>
          {t("sessionStatus.started")}
          {communicationMode === CommunicationMode.Phone && (
            <Phone className="-scale-x-100 transform pr-1" fill="#7CB342" strokeWidth={1.2} size={17} />
          )}
          {communicationMode === CommunicationMode.Chat && (
            <MessageSquare className="inline pl-1" fill="#7CB342" strokeWidth={1.2} size={17} />
          )}
          {communicationMode === CommunicationMode.Video && (
            <img src={Camera} alt={t("general.alt.camera")} className="inline pl-1" />
          )}
        </Badge>
      );
    default:
      return (
        <Badge variant="green" className={className}>
          {t("sessionStatus.online")}
          <img src={Camera} alt={t("general.alt.camera")} className="inline pl-1" />
        </Badge>
      );
  }
};

export { StatusBadge };
