import { Header, LocalisedLink } from "@/components";
import { SessionDetails, SessionDetailsSkeleton } from "@/components";
import routes from "@/constants/routes";
import { useGetVisitQuery } from "@/store/apis";
import { ChevronLeft } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui";

function SessionDetailsPage() {
  const { t, i18n } = useTranslation();
  const { visitId } = useParams();
  const { data: session, isLoading } = useGetVisitQuery({
    id: Number(visitId),
    lang: i18n.language,
  });

  return (
    <>
      <div className="hidden lg:block">
        <Header />
      </div>
      <div className="w-screen border-b border-veryLightGray p-5 lg:border-none lg:bg-backgroundApp lg:pl-32">
        <LocalisedLink
          to={routes.myVisits}
          className="flex items-center text-sm font-bold text-secondaryText hover:text-primaryText lg:text-base"
        >
          <ChevronLeft className="-ml-2" strokeWidth="1.2" />
          {t("sessionDetails.backToSessions")}
        </LocalisedLink>
      </div>
      <div className="min-h-screen space-y-4 bg-backgroundApp p-5 text-sm font-normal text-secondaryText lg:h-auto lg:px-56">
        {isLoading && <SessionDetailsSkeleton />}
        {session && <SessionDetails session={session} />}
        {/* TODO: Add static content once decided and unify with accordion on BookingConfirmation, for now it's just a placeholder */}
        <Accordion type="single" collapsible>
          <AccordionItem value="FAQ" className="">
            <AccordionTrigger className="py-3 lg:text-lg">{t("booking.FAQ")}</AccordionTrigger>
            <AccordionContent>
              <p>Common question 1?</p>
              <p className="ml-10">Common answer 1 .....</p>
              <br />
              <p>Common question 2?</p>
              <p className="ml-10">Common answer 2 .....</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="howToConnect">
            <AccordionTrigger className="mt-4 py-3 lg:text-lg">{t("booking.howToConnect")}</AccordionTrigger>
            <AccordionContent>Here should be some content</AccordionContent>
          </AccordionItem>
          <AccordionItem value="paymentAndReturns">
            <AccordionTrigger className="mt-4 py-3 lg:text-lg">{t("booking.paymentAndReturns")}</AccordionTrigger>
            <AccordionContent>Here should be some content</AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
}

export { SessionDetailsPage };
