import { useState } from "react";

import {
  Button,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components";
import { selectSpecialistsSortOptions } from "@/store/selectors";
import { SortOptions, setSort } from "@/store/slices";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ChevronDown, ThinXMark } from "@/assets/images";

import { cn } from "@/lib/utils";

const SortingDrawer = () => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const initSortOptions = useSelector(selectSpecialistsSortOptions);
  const [sortOptions, setSortOptions] = useState<SortOptions>(initSortOptions);
  const dispatch = useDispatch();

  const handleSort = () => {
    dispatch(setSort(sortOptions));
    setDrawerOpen(false);
  };

  const sortMap: SortOptions[] = [
    { sortBy: "last_name", sortOrder: "asc" },
    { sortBy: "last_name", sortOrder: "desc" },
    { sortBy: "availability", sortOrder: "asc" },
  ];

  const translationForSort = (sortItem: SortOptions) => {
    let translationKey = "";
    if (sortItem.sortBy === "last_name") {
      translationKey = sortItem.sortOrder === "asc" ? "az" : "za";
    } else if (sortItem.sortBy === "availability") {
      translationKey = "availability";
    }
    return t(`specialistsPage.sort.options.${translationKey}`);
  };

  const handleOptionClick = (option: SortOptions) => {
    setSortOptions(option);
  };

  const cancel = () => {
    setDrawerOpen(false);
  };

  const isSortOptionSelected = (sortItem: SortOptions) => {
    return sortOptions.sortBy === sortItem.sortBy && sortOptions.sortOrder === sortItem.sortOrder;
  };

  return (
    <Drawer onOpenChange={(open) => setDrawerOpen(open)} open={drawerOpen}>
      <DrawerTrigger asChild>
        <Button className="max-w-[180px] grow">
          <div className="flex items-center gap-2">
            <span className="overflow-hidden text-ellipsis whitespace-nowrap">
              {t("specialistsPage.sort.sortBy")} {translationForSort(initSortOptions)}
            </span>
            <img src={ChevronDown} />
          </div>
        </Button>
      </DrawerTrigger>
      <DrawerContent className="text-secondaryText">
        <div className="mx-auto w-full max-w-sm p-6">
          <DrawerHeader>
            <DrawerTitle>
              <div className="flex flex-row justify-between">
                <div className="mb-4 flex flex-row gap-3">
                  <DrawerClose asChild>
                    <img
                      src={ThinXMark}
                      alt={t("specialistsPage.filters.close")}
                      className="min-h-6 min-w-6 cursor-pointer"
                    />
                  </DrawerClose>
                  <h3 className="text-secondaryText2 text-lg font-normal">{t("specialistsPage.sort.sort")}</h3>
                </div>
              </div>
            </DrawerTitle>
          </DrawerHeader>

          <div className="flex flex-wrap content-start items-start gap-[10px_8px]">
            {sortMap.map((sortItem) => (
              <Button
                key={`${sortItem.sortBy}-${sortItem.sortOrder}`}
                className={cn(" ", isSortOptionSelected(sortItem) ? "bg-veryLightGray" : "")}
                onClick={() => handleOptionClick(sortItem)}
              >
                {translationForSort(sortItem)}
              </Button>
            ))}
          </div>

          <DrawerFooter className="flex w-full gap-2">
            <DrawerClose asChild>
              <Button className="text-secondaryText" onClick={cancel}>
                {t("specialistsPage.filters.close")}
              </Button>
            </DrawerClose>
            <Button variant="yellow" className="grow py-2" onClick={handleSort}>
              {t("specialistsPage.filters.showResults")}
            </Button>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export { SortingDrawer };
