import { useEffect, useRef, useState } from "react";

import {
  LocalisedLink,
  SpecialistAboutTab,
  SpecialistCalendarTab,
  SpecialistProfileHero,
  SpecialistProfileHeroSkeleton,
  SpecialistReviewsTab,
  localisedPath,
} from "@/components";
import routes from "@/constants/routes";
import { useGetSpecialistPreviewQuery, useGetSpecialistQuery } from "@/store/apis";
import { ChevronLeft, Pencil } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Button, Skeleton, Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui";

import { ApiError, formatErrorMessage } from "@/lib/utils";

function SpecialistPage() {
  const { t, i18n } = useTranslation();
  const { slug = "", id = "" } = useParams<{ slug: string; id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const tabsContentRef = useRef<HTMLDivElement>(null);
  const isSpecialistDetails = !location.pathname.includes("specialist_preview");

  const getTabHash = () => {
    return location.hash.substring(1);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeTab, setActiveTab] = useState<string>(getTabHash() || "calendar");

  useEffect(() => {
    const handleHashChange = () => {
      setActiveTab(getTabHash());
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [location.hash]);

  const {
    data: specialist,
    isLoading,
    error,
  } = isSpecialistDetails
    ? useGetSpecialistQuery({ id: slug?.toString(), lang: i18n.language })
    : useGetSpecialistPreviewQuery({ id: id, lang: i18n.language });

  const onTabChange = (tab: string) => {
    setActiveTab(tab);
    const navigateTo = isSpecialistDetails ? routes.specialistDetails(slug, tab) : routes.specialistPreview(id, tab);
    navigate(localisedPath(navigateTo));
    if (tab === "calendar" && tabsContentRef.current) {
      tabsContentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const specialist_name = `${specialist?.first_name} ${specialist?.last_name}`;

  const triggerClassName =
    "rounded-none px-0 text-center text-sm font-medium text-primaryText hover:text-secondaryText data-[state=active]:border-b-2 data-[state=active]:border-primaryApp data-[state=active]:bg-inherit data-[state=active]:text-primaryText data-[state=active]:shadow-none lg:basis-1/6 lg:pb-[15px] lg:text-secondaryText";
  const tabClassName = "w-full self-stretch lg:rounded-lg lg:py-6";

  return (
    <>
      {!isSpecialistDetails && (
        <div className="mt-5 hidden rounded-md border border-veryLightGray bg-backgroundApp p-3 text-center font-medium text-secondaryText lg:block">
          <Pencil className="mr-2 inline" size="16" />
          {t("specialistProfilePage.previewInfo")}
        </div>
      )}
      <div className="w-full flex-none justify-center space-y-2 overflow-auto pt-5 lg:flex lg:flex-row lg:justify-start lg:space-x-8 lg:px-0">
        <div>
          <LocalisedLink
            to={routes.specialists}
            data-testid="back-to-experts"
            className="-ml-0.5 flex items-center text-sm font-normal text-primaryText hover:text-secondaryText lg:py-6"
          >
            <ChevronLeft strokeWidth={1.2} />
            {t("specialistProfilePage.backToExperts")}
          </LocalisedLink>
          {!isSpecialistDetails && (
            <div className="rounded-md border border-veryLightGray bg-backgroundApp p-3 text-center font-medium text-secondaryText lg:hidden">
              <Pencil className="mr-2 inline" size="16" />
              {t("specialistProfilePage.previewInfo")}
            </div>
          )}

          <div className="space-y-2 pt-5 lg:pt-2">
            {isLoading && <SpecialistProfileHeroSkeleton />}
            {error && formatErrorMessage(error as ApiError)}
            {specialist && (
              <SpecialistProfileHero
                specialist_name={specialist_name}
                main_photo={specialist.main_photo}
                specialisations={specialist.specialisations}
                average_rating={specialist.average_rating}
                reviews_count={specialist.reviews_count}
              />
            )}
            <div className="text-center">
              {isLoading && (
                <Skeleton className={`${activeTab === "calendar" ? "lg:hidden" : ""} mx-auto h-10 w-full`} />
              )}
              {specialist && (
                <Button
                  data-testid="schedule-session"
                  onClick={() => onTabChange("calendar")}
                  variant="yellow"
                  className={`${activeTab === "calendar" ? "lg:hidden" : ""} mx-auto w-full grow`}
                >
                  {t("specialistProfilePage.scheduleSession")}
                </Button>
              )}
            </div>
          </div>
        </div>
        {isLoading && <Skeleton className="hidden lg:block lg:h-[50px] lg:w-full lg:rounded-lg lg:border lg:pt-1.5" />}
        {specialist && (
          <Tabs
            key={activeTab}
            defaultValue={activeTab}
            className="w-full space-y-4 overflow-hidden pt-5 lg:space-y-0 lg:pt-0"
            onValueChange={onTabChange}
          >
            <TabsList
              data-testid="specialist-tabs"
              className="inline-flex items-center justify-start gap-5 bg-inherit p-0 lg:h-[50px] lg:w-full lg:justify-center lg:rounded-lg lg:border lg:bg-white lg:px-8 lg:pt-1.5"
            >
              <TabsTrigger data-testid="calendar-tab" id="calendar" value="calendar" className={triggerClassName}>
                {t("specialistProfilePage.calendar")}
              </TabsTrigger>
              <TabsTrigger data-testid="about-tab" id="about" value="about" className={triggerClassName}>
                {t("specialistProfilePage.about")}
              </TabsTrigger>
              <TabsTrigger data-testid="reviews-tab" id="reviews" value="reviews" className={triggerClassName}>
                {t("specialistProfilePage.reviews")}
              </TabsTrigger>
              <div className="hidden lg:basis-1/2" />
            </TabsList>
            <TabsContent ref={tabsContentRef} className={tabClassName} value="calendar">
              <SpecialistCalendarTab specialist={specialist} iframe={false} />
            </TabsContent>
            <TabsContent className={tabClassName} value="about">
              <SpecialistAboutTab specialist={specialist} />
            </TabsContent>
            <TabsContent className={tabClassName} value="reviews">
              <SpecialistReviewsTab reviews={specialist.reviews} reviews_count={specialist.reviews_count} />
            </TabsContent>
          </Tabs>
        )}
      </div>
    </>
  );
}

export { SpecialistPage };
