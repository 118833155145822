import React, { useEffect, useState } from "react";

import { LocalisedLink, SlotButton, StatusBadge, localisedPath } from "@/components";
import routes from "@/constants/routes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { avatarImage } from "@/assets/images";

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui";

import { formatYearDateFromISO, groupSlotsByDate } from "@/lib/utils";

import { SlotDto, SpecialistListDto } from "@/types/types";

type SpecialistCardProps = {
  specialist: SpecialistListDto;
  selected: boolean;
  onSpecialistSlotClick: (specialistId: number | null) => void;
};

const SpecialistCardMobile = React.memo<SpecialistCardProps>(
  ({ specialist, selected, onSpecialistSlotClick }: SpecialistCardProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [activeSlot, setActiveSlot] = useState<SlotDto | null>(null);
    const isValidUrl = (string: string): boolean => {
      try {
        new URL(string);
      } catch (_) {
        return false;
      }

      return true;
    };

    useEffect(() => {
      if (!selected) {
        setActiveSlot(null);
      }
    }, [selected]);

    const onSlotClick = (slot: SlotDto) => {
      if (activeSlot === slot) {
        setActiveSlot(null);
        onSpecialistSlotClick(null);
      } else {
        setActiveSlot(slot);
        onSpecialistSlotClick(specialist.id);
      }
    };
    const isActiveSlot = (slot: SlotDto) => {
      return activeSlot === slot;
    };

    const slotsByDate = groupSlotsByDate(specialist.available_slots ?? []);
    const sortedDates = Object.keys(slotsByDate).sort();
    const closestDate = sortedDates[0];
    const closestSlots = slotsByDate[closestDate]?.slice(0, 3);

    const isScheduleSessionEnabled = () => {
      return !activeSlot;
    };

    const onScheduleSessionClick = () => {
      const specialistId = specialist.id.toString();
      const serviceId = specialist.services[0].id.toString();
      const slot = activeSlot?.start_time ?? "";

      navigate(localisedPath(routes.scheduleSessionDetails(specialistId, serviceId, slot as "slot", "")));
    };

    return (
      <Card
        data-testid="specialist-card-mobile"
        className="flex w-full min-w-60 flex-col gap-2 rounded-lg border border-solid border-veryLightGray p-4"
      >
        <CardHeader className="flex flex-row items-start gap-3.5">
          <Avatar className="rounded lg:rounded-lg">
            <AvatarImage
              src={isValidUrl(specialist.listing_photo ?? "") ? specialist.listing_photo : avatarImage}
              alt={t("general.alt.photo")}
              className="h-12 w-12 object-cover"
            />
            <AvatarFallback>CN</AvatarFallback>
          </Avatar>
          <div className="flex flex-col gap-1">
            <CardTitle className="truncate text-base font-semibold">
              {specialist.first_name} {specialist.last_name}
            </CardTitle>
            <CardDescription className="max-w-60 text-primaryText">
              {specialist.specialisations?.map((s) => s.display_name).join(", ")}
            </CardDescription>
            <div className="flex w-full flex-row items-center gap-3"></div>
          </div>
        </CardHeader>

        <hr />

        <CardContent className="flex flex-col gap-4 p-0">
          {specialist.available_slots.length <= 0 && (
            <p data-testid="no-available-slots" className="px-1">
              {t("specialistsPage.specialistCard.noAvailableSessions")}
            </p>
          )}
          {closestSlots && (
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-center gap-1">
                <p className="px-1">{t("specialistsPage.specialistCard.nextSession")}:</p>
                <p className="px-1 font-bold">{formatYearDateFromISO(closestDate)}</p>
                <div className="px-1">
                  <StatusBadge />
                </div>
              </div>
              <div className="flex flex-row flex-wrap gap-2">
                {closestSlots?.map((slot) => {
                  return (
                    <SlotButton
                      slot={slot}
                      key={slot.start_time}
                      active={isActiveSlot(slot)}
                      onSlotClick={onSlotClick}
                    />
                  );
                })}
                <Button variant="gray" size="xs" asChild>
                  <LocalisedLink to={routes.specialistDetails(specialist.slug.toString(), "calendar")}>
                    {t("specialistsPage.specialistCard.more")} &gt;
                  </LocalisedLink>
                </Button>
              </div>
            </div>
          )}
          <div className="flex gap-2">
            <Button className="px-6" asChild>
              <LocalisedLink to={routes.specialistDetails(specialist.slug, "about")}>
                {t("specialistsPage.specialistCard.view")}
              </LocalisedLink>
            </Button>
            {specialist.available_slots.length > 0 && (
              <Button
                data-testid="schedule-session-button"
                variant="yellow"
                disabled={isScheduleSessionEnabled()}
                onClick={onScheduleSessionClick}
                className={"grow"}
              >
                {t("specialistsPage.specialistCard.scheduleSession")}
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    );
  }
);

export { SpecialistCardMobile };
