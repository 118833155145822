import { useCallback, useEffect, useState } from "react";
import React from "react";

import { useGetLanguagesQuery, useGetSpecialisationsQuery } from "@/store/apis";
import { selectSpecialisations, selectSpecialistsFilters, selectTranslatedAvailableLanguage } from "@/store/selectors";
import { setFilters } from "@/store/slices";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ChevronDown } from "@/assets/images";

import {
  Menubar,
  MenubarCheckboxItem,
  MenubarContent,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from "@/components/ui";

import { toggleInArray } from "@/lib/utils";

const FiltersSelect: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const selectedFilters = useSelector(selectSpecialistsFilters);
  const specialisations = useSelector(selectSpecialisations);
  const languages = useSelector(selectTranslatedAvailableLanguage);

  const [filtersState, setFiltersState] = useState(selectedFilters);

  const { isLoading: isLoadingSpecialisations } = useGetSpecialisationsQuery({
    lang: i18n.language,
  });
  const { isLoading: isLoadingLanguages } = useGetLanguagesQuery();

  const toggleLanguage = useCallback(
    (language: string) => {
      const newFilters = { ...filtersState };
      newFilters.languages = toggleInArray(newFilters.languages, language);
      dispatch(setFilters(newFilters));
    },
    [filtersState, dispatch]
  );

  const toggleSpecialisation = useCallback(
    (specialisationId: number) => {
      const newFilters = { ...filtersState };
      newFilters.specialisations = toggleInArray(newFilters.specialisations, specialisationId);
      dispatch(setFilters(newFilters));
    },
    [filtersState, dispatch]
  );

  useEffect(() => {
    setFiltersState(selectedFilters);
  }, [selectedFilters]);

  return (
    <>
      <Menubar className="space-x-0 p-0">
        <MenubarMenu>
          <MenubarTrigger className="w-min cursor-pointer rounded-lg border border-veryLightGray px-4 py-2.5 leading-[18px] focus:bg-inherit focus:text-secondaryText data-[state=open]:bg-inherit data-[state=open]:text-secondaryText">
            {t("specialistsPage.filters.specialisations")}
            <img src={ChevronDown} className="px-4 opacity-80" />
          </MenubarTrigger>
          <MenubarContent alignOffset={0} sideOffset={4} className="shadow-sm ">
            {isLoadingSpecialisations ? "Loading..." : <></>}
            {specialisations.map((specialisation, index) => (
              <React.Fragment key={`specialisation-fragment-${specialisation.id}`}>
                <MenubarCheckboxItem
                  onSelect={(e) => e.preventDefault()}
                  onCheckedChange={() => toggleSpecialisation(specialisation.id)}
                  checked={filtersState.specialisations.includes(specialisation.id)}
                  className="font-medium focus:bg-veryLightGray/50 focus:text-secondaryText"
                >
                  {specialisation.universal_name}
                </MenubarCheckboxItem>
                {index < specialisations.length - 1 && <MenubarSeparator />}
              </React.Fragment>
            ))}
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
      <Menubar className="space-x-0 rounded-lg p-0">
        <MenubarMenu>
          <MenubarTrigger className="cursor-pointer rounded-lg border border-veryLightGray px-4 py-2.5 leading-[18px] focus:bg-inherit focus:text-secondaryText data-[state=open]:bg-inherit data-[state=open]:text-secondaryText">
            {t("specialistsPage.filters.languages")}
            <img src={ChevronDown} className="px-4 opacity-80" />
          </MenubarTrigger>
          <MenubarContent alignOffset={0} sideOffset={4} className="shadow-sm">
            {isLoadingLanguages ? "Loading..." : <></>}
            {languages.map((language, index) => (
              <React.Fragment key={`language-fragment-${language.id}`}>
                <MenubarCheckboxItem
                  onSelect={(e) => e.preventDefault()}
                  onCheckedChange={() => toggleLanguage(language.id)}
                  checked={filtersState.languages.includes(language.id)}
                  className="font-medium focus:bg-veryLightGray/50 focus:text-secondaryText"
                >
                  {language.name}
                </MenubarCheckboxItem>
                {index < languages.length - 1 && <MenubarSeparator />}
              </React.Fragment>
            ))}
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </>
  );
};
export { FiltersSelect };
