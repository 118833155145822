import { useUpdateAuthState } from "@/auth";
import { Footer, Header } from "@/components";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  useUpdateAuthState();
  return (
    <div className="flex min-h-screen min-w-80 flex-col items-center justify-center bg-backgroundApp">
      <Header />
      <div className="h-full w-full flex-grow px-6 lg:mx-auto lg:w-4/5 lg:max-w-screen-xl lg:px-0">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export { MainLayout };
