import i18n from "@/i18n";
import { specialistsApi } from "@/store/apis";
import { selectSpecialistQueryParams } from "@/store/slices";
import { createSelector } from "@reduxjs/toolkit";

import { SpecialistListDto } from "@/types/types";

const selectSpecialistsResponse = createSelector([(state) => state], (state) => {
  return specialistsApi.endpoints.getSpecialists.select({ lang: i18n.language })(state);
});

const selectSpecialists = createSelector(selectSpecialistsResponse, (specialistsResponse) => {
  return specialistsResponse.data?.specialists ?? [];
});

const filterBySearchTerm = (specialists: SpecialistListDto[], searchTerm: string) => {
  return specialists.filter((specialist) => {
    const specialistName = specialist.first_name + " " + specialist.last_name;
    return specialistName.toLowerCase().includes(searchTerm.toLowerCase());
  });
};

const filterByLanguages = (specialists: SpecialistListDto[], languages: string[]) => {
  return specialists.filter((specialist) => {
    if (languages.length !== 0) {
      return specialist.languages.some((language) => languages.includes(language));
    }
    return true;
  });
};

const filterBySpecialisations = (specialists: SpecialistListDto[], specialisations: number[]) => {
  return specialists.filter((specialist) => {
    if (specialisations.length !== 0) {
      return specialist.specialisations.some((specialisation) => specialisations.includes(specialisation.id));
    }
    return true;
  });
};

const sortByLastName = (specialists: SpecialistListDto[], sortOrder: string) => {
  return specialists.sort((a, b) => {
    return sortOrder === "asc" ? a.last_name.localeCompare(b.last_name) : b.last_name.localeCompare(a.last_name);
  });
};

function sortByAvailabilityThenLastName(
  specialists: SpecialistListDto[],
  sortOrder: "asc" | "desc"
): SpecialistListDto[] {
  return specialists.sort((a, b) => {
    if (a.available_slots.length === 0 && b.available_slots.length === 0) {
      return a.last_name.localeCompare(b.last_name);
    }

    if (a.available_slots.length === 0) return 1;
    if (b.available_slots.length === 0) return -1;

    if (a.available_slots[0] === b.available_slots[0]) {
      return a.last_name.localeCompare(b.last_name);
    }

    if (sortOrder === "asc") {
      return a.available_slots[0] < b.available_slots[0] ? -1 : 1;
    } else {
      return a.available_slots[0] > b.available_slots[0] ? -1 : 1;
    }
  });
}

const selectFilteredSpecialists = createSelector(
  [selectSpecialists, selectSpecialistQueryParams],
  (specialists, filters) => {
    let filteredSpecialists = specialists;

    filteredSpecialists = filterBySearchTerm(filteredSpecialists, filters.searchTerm);
    filteredSpecialists = filterByLanguages(filteredSpecialists, filters.languages);
    filteredSpecialists = filterBySpecialisations(filteredSpecialists, filters.specialisations);

    if (filters.sortBy === "availability") {
      filteredSpecialists = sortByAvailabilityThenLastName(filteredSpecialists, filters.sortOrder);
    } else {
      filteredSpecialists = sortByLastName(filteredSpecialists, filters.sortOrder);
    }

    return filteredSpecialists;
  }
);

export { selectFilteredSpecialists, selectSpecialists, selectSpecialistsResponse };
