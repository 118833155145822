import { mhhlApi } from "@/store/apis";
import { SpecialistsQueryParamsSlice, authSlice, visitsSlice } from "@/store/slices";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: {
    [authSlice.reducerPath]: authSlice.reducer,
    [mhhlApi.reducerPath]: mhhlApi.reducer,
    [SpecialistsQueryParamsSlice.reducerPath]: SpecialistsQueryParamsSlice.reducer,
    [visitsSlice.reducerPath]: visitsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(mhhlApi.middleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
export type AppStore = typeof store;

export type AppDispatch = AppStore["dispatch"];

export { useGetSpecialistsQuery, useGetSpecialistQuery } from "./apis";
