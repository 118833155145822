import React, { useEffect, useMemo, useState } from "react";

import { VoucherCardSkeleton, VouchersList } from "@/components";
import { RootState } from "@/store";
import {
  useBookVisitMutation,
  useConnectVoucherMutation,
  useGetActiveVouchersQuery,
  useLazyValidateVoucherCodeQuery,
  vouchersApi,
} from "@/store/apis";
import { selectUser } from "@/store/slices";
import { zodResolver } from "@hookform/resolvers/zod";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  RadioGroup,
  RadioGroupItem,
  Separator,
} from "@/components/ui";

import { ApiError, formatErrorMessage } from "@/lib/utils";

import { VoucherStatus, VoucherUsage } from "@/types/enums";
import { VoucherDto } from "@/types/types";

import { clearFormStorage, handleBackClick, useSession, useSteps } from "./SessionScheduling";

const FormSchema = z.object({
  voucherUsage: z.nativeEnum(VoucherUsage),
  voucherCode: z.string().optional(),
});

interface VoucherData {
  totalAmount: string | null;
  discountAmount: string | null;
  selectedVoucherId: number | undefined;
  voucherCode: string;
}

const PaymentDetailsForm: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { setCurrentStep, currentStep } = useSteps();
  const { visitId, serviceId, slot } = useParams();

  const specialist = useSession().specialist;

  const dispatch: ThunkDispatch<RootState, void, never> = useDispatch();
  const storageKeyVoucherUsageStatus = "sessionBooking_voucherUsageStatus";
  const storageKeySelectedVoucherId = "sessionBooking_selectedVoucherId";

  const [voucherInputCode, setVoucherInputCode] = useState<string>("");
  const [voucherUsageStatus, setVoucherUsageStatus] = useState<VoucherUsage>(
    (localStorage.getItem(storageKeyVoucherUsageStatus) as VoucherUsage) ?? VoucherUsage.No
  );
  const [bookVisit] = useBookVisitMutation();
  const [triggerValidateVoucher] = useLazyValidateVoucherCodeQuery();
  const [connectVoucher] = useConnectVoucherMutation();

  const [apiError, setApiError] = useState<string | undefined>("");
  const [connectVoucherError, setConnectVoucherError] = useState<string | undefined>("");

  const selectedService = useMemo(() => {
    return specialist?.services.find((service) => service.id === Number(serviceId));
  }, [specialist, serviceId]);

  const [{ totalAmount, discountAmount, selectedVoucherId }, setVoucherData] = useState<VoucherData>({
    totalAmount: selectedService?.price || "",
    discountAmount: null,
    selectedVoucherId: undefined,
    voucherCode: "",
  });

  const patientId = useSelector(selectUser)?.patient_id;

  const {
    data: activeVouchers,
    error: activeError,
    isLoading: activeLoading,
    isSuccess: activeSuccess,
  } = useGetActiveVouchersQuery(patientId?.toString() || "", {
    skip: !patientId,
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      voucherUsage: voucherUsageStatus as VoucherUsage,
      voucherCode: "",
    },
  });

  useEffect(() => {
    setCurrentStep("step3");
  }, []);

  const handleChangeVoucherUsageStatus = (value: VoucherUsage) => {
    setVoucherUsageStatus(value);
    localStorage.setItem(storageKeyVoucherUsageStatus, value);
    if (value === VoucherUsage.No) {
      setVoucherData({
        discountAmount: null,
        selectedVoucherId: undefined,
        voucherCode: "",
        totalAmount: selectedService?.price || "",
      });
      localStorage.removeItem(storageKeySelectedVoucherId);
      setConnectVoucherError("");
    } else {
      automaticVoucherSelection();
    }
  };

  const automaticVoucherSelection = () => {
    const voucherId = localStorage.getItem(storageKeySelectedVoucherId);

    const selectedVoucher = activeVouchers?.vouchers.filter(({ id }) => id === Number(voucherId));

    if (selectedVoucher && selectedVoucher.length > 0) {
      validateVoucherData(selectedVoucher[0].code, voucherId);
    } else if (activeVouchers && activeVouchers?.vouchers.length > 0) {
      validateVoucherData(activeVouchers?.vouchers[0].code, activeVouchers?.vouchers[0].id.toString());
    }
  };

  const validateVoucherData = async (code: string, voucherId: string | null) => {
    const response = await triggerValidateVoucher({
      id: Number(visitId),
      code: code,
    }).unwrap();

    if (voucherId) {
      setVoucherData({
        voucherCode: response.code,
        selectedVoucherId: Number(voucherId),
        discountAmount: response.discount_amount,
        totalAmount: response.total_amount,
      });
      localStorage.setItem(storageKeySelectedVoucherId, voucherId);
    }
  };

  useEffect(() => {
    if (voucherUsageStatus === VoucherUsage.Yes && activeSuccess) {
      automaticVoucherSelection();
    }
  }, [activeSuccess]);

  const handleBookVisit = async (visitId: number) => {
    try {
      const voucherToUse =
        selectedVoucherId !== undefined && activeVouchers
          ? activeVouchers.vouchers.find((voucher: VoucherDto) => voucher.id === selectedVoucherId)?.code || ""
          : "";

      const resultAction = await bookVisit({
        id: visitId,
        status: "booked",
        voucher: voucherToUse,
        lang: i18n.language,
      }).unwrap();

      window.location.href = resultAction.redirect_url;

      clearFormStorage();
    } catch (error) {
      setApiError(formatErrorMessage(error as ApiError));
    }
  };

  const handleFormSubmit = async () => {
    if (!visitId) return;
    try {
      await handleBookVisit(Number(visitId));
    } catch (error) {
      setApiError(formatErrorMessage(error as ApiError));
    }
  };

  const connectVoucherCode = async () => {
    const patient_id = patientId?.toString() || "";
    try {
      const response = await triggerValidateVoucher({
        id: Number(visitId),
        code: voucherInputCode,
      }).unwrap();
      const newVoucher = await connectVoucher({
        id: patient_id,
        code: voucherInputCode,
      }).unwrap();
      dispatch(
        vouchersApi.util.updateQueryData("getActiveVouchers", patient_id, (draft) => {
          draft.vouchers.push(newVoucher);
        })
      );

      if (response.code_valid) {
        setVoucherData({
          discountAmount: response.discount_amount,
          selectedVoucherId: newVoucher.id,
          totalAmount: response.total_amount,
          voucherCode: response.code,
        });
        localStorage.setItem(storageKeySelectedVoucherId, newVoucher.id.toString());
        setVoucherInputCode("");
        setApiError("");
      } else {
        setConnectVoucherError(t("sessionScheduling.paymentForm.voucherInvalid"));
      }
    } catch (error) {
      setConnectVoucherError(formatErrorMessage(error as ApiError));
    }
  };

  const handleVoucherClick = async (voucher: VoucherDto) => {
    try {
      const response = await triggerValidateVoucher({
        id: Number(visitId),
        code: voucher.code,
      }).unwrap();
      if (response.code_valid) {
        setVoucherData(() => ({
          discountAmount: response.discount_amount,
          selectedVoucherId: voucher.id,
          totalAmount: response.total_amount,
          voucherCode: response.code,
        }));
        localStorage.setItem(storageKeySelectedVoucherId, voucher.id.toString());
        setConnectVoucherError("");
      } else {
        setConnectVoucherError(t("sessionScheduling.paymentForm.voucherInvalid"));
      }
    } catch (error) {
      setConnectVoucherError(formatErrorMessage(error as ApiError));
    }
  };

  const handleInputClick = () => {
    setVoucherData((prevState) => ({
      ...prevState,
      discountAmount: null,
      voucherCode: "",
      totalAmount: selectedService?.price || "",
    }));
    localStorage.removeItem(storageKeySelectedVoucherId);
    setConnectVoucherError("");
    setVoucherInputCode("");
  };

  const handleVoucherInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      connectVoucherCode();
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleFormSubmit)} className="text-sm lg:text-base">
        <div className="flex h-full flex-grow flex-col gap-3 lg:gap-7" data-testid="payment-details-form">
          <h3 className="text-base font-bold text-primaryText lg:text-xl">
            {t("sessionScheduling.paymentForm.voucher")}
          </h3>
          <FormField
            control={form.control}
            name="voucherUsage"
            render={() => (
              <FormItem className="space-y-3">
                <FormLabel className="block font-normal lg:text-base">
                  {t("sessionScheduling.paymentForm.voucherUse")}
                </FormLabel>
                <FormControl>
                  <RadioGroup
                    data-testid="radio-group"
                    onValueChange={(value) => handleChangeVoucherUsageStatus(value as VoucherUsage)}
                    defaultValue={voucherUsageStatus}
                    className="flex gap-2"
                  >
                    <FormItem className="flex items-center space-x-1 space-y-0">
                      <FormControl>
                        <RadioGroupItem data-testid="radio-button-yes" value={VoucherUsage.Yes} />
                      </FormControl>
                      <FormLabel className="text-sm font-normal lg:text-base">
                        {t("sessionScheduling.paymentForm.yes")}
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-1 space-y-0">
                      <FormControl>
                        <RadioGroupItem data-testid="radio-button-no" value={VoucherUsage.No} />
                      </FormControl>
                      <FormLabel className="text-sm font-normal lg:text-base">
                        {t("sessionScheduling.paymentForm.no")}
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {voucherUsageStatus === VoucherUsage.Yes && (
            <>
              {activeLoading && (
                <div className="py-2">
                  <VoucherCardSkeleton />
                </div>
              )}
              {activeError && <div>{t("myVouchersPage.errorLoadingActiveVouchers")}</div>}
              {(activeVouchers?.vouchers.length ?? 0) > 0 && (
                <>
                  <div>
                    <h3 className="pt-2 text-sm text-secondaryText">
                      {t("sessionScheduling.paymentForm.connectedVouchers")}
                    </h3>
                    <VouchersList
                      vouchers={activeVouchers?.vouchers ?? []}
                      status={VoucherStatus.Active}
                      onVoucherClick={handleVoucherClick}
                      selectedVoucherId={selectedVoucherId}
                    />
                  </div>
                  <div className="flex w-full flex-row items-center">
                    <Separator className="h-px flex-1 bg-veryLightGray" />
                    <span className="px-4 text-center text-sm text-secondaryText">
                      {t("sessionScheduling.paymentForm.useAnotherCode")}
                    </span>
                    <Separator className="h-px flex-1 bg-veryLightGray" />
                  </div>
                </>
              )}
              <FormField
                control={form.control}
                name="voucherCode"
                render={() => (
                  <FormItem>
                    <FormControl>
                      <div>
                        <h3 className="py-1 text-sm font-medium">{t("sessionScheduling.paymentForm.voucherCode")}</h3>
                        <div className="lg:max-w-100 my-1 flex h-12 w-full items-center rounded-lg border border-veryLightGray bg-white px-2">
                          <div className="h-full w-full items-center">
                            <Input
                              type="text"
                              placeholder={t("sessionScheduling.paymentForm.enterVoucherCode")}
                              value={voucherInputCode}
                              onChange={(e) => setVoucherInputCode(e.target.value)}
                              onClick={handleInputClick}
                              onKeyDown={handleVoucherInputKeyDown}
                              className="lg:w-stretch h-11 w-full border-0"
                            />
                          </div>
                          {voucherInputCode && (
                            <Button
                              type="button"
                              onClick={connectVoucherCode}
                              variant="gray"
                              className="ml-2 font-bold"
                            >
                              {t("myVouchersPage.connectVoucher")}
                            </Button>
                          )}
                        </div>
                        <div className="h-[20px]">
                          {connectVoucherError && <span className="text-sm text-redApp">{connectVoucherError}</span>}
                        </div>
                      </div>
                    </FormControl>
                    <FormMessage className="font-normal text-redApp" />
                  </FormItem>
                )}
              />
            </>
          )}
          <div className="mt-auto py-4">
            <h3 className="text-base font-bold text-primaryText lg:text-xl">
              {t("sessionScheduling.paymentForm.summary")}
            </h3>
            <div className="flex justify-between pb-3 pt-2">
              <span className="text-primaryText">{t("sessionScheduling.paymentForm.sessionCost")}</span>
              <span className="text-primaryText">{selectedService?.price}</span>
            </div>
            {voucherUsageStatus === VoucherUsage.Yes && discountAmount !== null && (
              <div className="flex justify-between pb-4 pt-2">
                <span className="text-primaryText">{t("sessionScheduling.paymentForm.voucherUsed")}</span>
                <span className="text-mossGreen">-{discountAmount}</span>
              </div>
            )}
            <Separator className="mb-4 h-px bg-veryLightGray" />
            <div className="flex justify-between">
              <span className="text-primaryText">{t("sessionScheduling.paymentForm.VAT")}</span>
              <span className="text-primaryText">{totalAmount}</span>
            </div>
          </div>
          <div className="w-full space-y-3">
            {apiError && <p className="text-redApp">{apiError}</p>}
            <div className="flex gap-2">
              <Button
                type="button"
                className="px-6 lg:text-base"
                onClick={() =>
                  handleBackClick(
                    currentStep,
                    setCurrentStep,
                    navigate,
                    specialist?.id?.toString(),
                    serviceId?.toString(),
                    slot?.toString(),
                    visitId?.toString()
                  )
                }
              >
                {t("sessionScheduling.back")}
              </Button>
              <Button
                data-testid="pay-and-book-button"
                variant="yellow"
                className="w-full lg:w-52 lg:text-base"
                type="submit"
              >
                {t("sessionScheduling.payAndBook")}
              </Button>
            </div>
            <p className="text-center text-secondaryText lg:text-left">
              {/* # TODO: set proper links once we have them */}
              <Trans
                t={t}
                i18nKey="sessionScheduling.paymentForm.bookingInfo"
                components={{
                  TCLink: <Link className="font-medium underline hover:text-primaryText" to="/terms" />,
                  PrivacyLink: <Link className="font-medium underline hover:text-primaryText" to="/privacy" />,
                }}
              />
            </p>
          </div>
        </div>
      </form>
    </Form>
  );
};

export { PaymentDetailsForm };
