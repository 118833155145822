import React, { useEffect, useMemo, useState } from "react";

import { LocalisedLink, SlotButton, StatusBadge, localisedPath } from "@/components";
import routes from "@/constants/routes";
import { Rating } from "@smastrom/react-rating";
import { ExternalLink } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { customStyles } from "@/assets/styles/rating";

import { Button, Separator } from "@/components/ui";

import { formatDate, formatDayOfWeek, groupSlotsByDate } from "@/lib/utils";

import { SlotDto, SpecialistListDto } from "@/types/types";

type SpecialistCardProps = {
  specialist: SpecialistListDto;
  selected: boolean;
  onSpecialistSlotClick: (specialistId: number | null) => void;
};

const SpecialistCardDesktopList = React.memo<SpecialistCardProps>(
  ({ specialist, selected, onSpecialistSlotClick }: SpecialistCardProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [activeSlot, setActiveSlot] = useState<SlotDto | null>(null);
    const slotsByDate = useMemo(() => groupSlotsByDate(specialist.available_slots || []), [specialist]);
    const sortedDates = Object.keys(slotsByDate).sort().slice(0, 3);
    const reviews_count = specialist.reviews_count;

    useEffect(() => {
      if (!selected) {
        setActiveSlot(null);
      }
    }, [selected]);

    const onSlotClick = (slot: SlotDto) => {
      if (activeSlot === slot) {
        setActiveSlot(null);
        onSpecialistSlotClick(null);
      } else {
        setActiveSlot(slot);
        onSpecialistSlotClick(specialist.id);
      }
    };
    const isActiveSlot = (slot: SlotDto) => {
      return activeSlot === slot;
    };

    const isScheduleSessionEnabled = () => {
      return !activeSlot;
    };

    const onScheduleSessionClick = () => {
      const specialistId = specialist.id.toString();
      const serviceId = specialist.services[0].id.toString();
      const slot = activeSlot?.start_time ?? "";
      navigate(localisedPath(routes.scheduleSessionDetails(specialistId, serviceId, slot as "slot", "")));
    };

    return (
      <div
        data-testid="specialist-card-desktop-list"
        className="flex gap-7 rounded-lg border border-solid border-veryLightGray bg-white p-4"
      >
        <div className="flex-shrink-0">
          <img
            className="h-[200px] w-[200px] rounded-lg object-cover grayscale transition duration-300 hover:grayscale-0"
            alt={t("general.alt.photo")}
            src={specialist.listing_photo}
          />
        </div>
        <div className="flex grow gap-7">
          <div className="flex basis-2/3 flex-col gap-1 py-2">
            <div>
              <StatusBadge status="onlineSession" />
              <h3 className="text-base font-semibold">
                {specialist.first_name} {specialist.last_name}
              </h3>
              <p className="text-sm font-normal text-secondaryText">
                {specialist.specialisations?.map((s) => s.display_name).join(", ")}
              </p>
            </div>
            <div className="flex w-full flex-row items-center gap-3 pb-3">
              <Rating
                readOnly
                value={specialist.average_rating}
                className={"!w-fit [&>div]:aspect-square [&>div]:h-5 [&>div]:w-5"}
                itemStyles={customStyles}
              />
              <div className="  font-bold text-secondaryText">
                {reviews_count} {t("specialistsPage.rating", { count: reviews_count })}
              </div>
            </div>
            {specialist.help_areas && (
              <div>
                <p className="font-normal text-secondaryText">{t("specialistsPage.specialistCard.willHelpYouWith")}:</p>
                <div
                  className="inner-html font-normal text-secondaryText"
                  dangerouslySetInnerHTML={{ __html: specialist.help_areas }}
                />
              </div>
            )}

            <div className="flex grow flex-col-reverse pt-4">
              <div className="space-x-2">
                <Button className="px-5" asChild>
                  <LocalisedLink to={routes.specialistDetails(specialist.slug.toString(), "about")}>
                    {t("specialistsPage.specialistCard.viewExpert")}
                  </LocalisedLink>
                </Button>
                <Button
                  data-testid="schedule-session-button"
                  variant="yellow"
                  className="px-5"
                  disabled={isScheduleSessionEnabled()}
                  onClick={onScheduleSessionClick}
                >
                  {t("specialistsPage.specialistCard.scheduleSession")}
                </Button>
              </div>
            </div>
          </div>
          <Separator orientation="vertical" className="h-auto bg-backgroundApp" />
          <div className="grow-2 flex basis-1/3 flex-col justify-between py-2">
            <div className="flex h-full flex-col">
              {sortedDates.length > 0 && (
                <>
                  <p className="text-center text-secondaryText">
                    {t("specialistsPage.specialistCard.earliestSessions")}:
                  </p>
                  <div className="overflow-y-auto">
                    <div className="grid max-h-[280px] auto-cols-max grid-flow-col justify-center gap-4 py-4">
                      {sortedDates.map((dayStr) => {
                        const slots = slotsByDate[dayStr];
                        const day = formatDate(dayStr);
                        const dayOfWeek = formatDayOfWeek(slots);
                        return (
                          <div className="flex flex-col gap-4" key={dayStr}>
                            <div>
                              <span className="block text-center font-bold text-primaryText">{`${day}`}</span>
                              <span className="block text-center text-[8px] font-bold uppercase leading-3 text-secondaryText">{`${dayOfWeek}`}</span>
                            </div>
                            {slots.map((slot) => {
                              return (
                                <SlotButton
                                  slot={slot}
                                  key={slot.start_time}
                                  active={isActiveSlot(slot)}
                                  onSlotClick={onSlotClick}
                                />
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
              {sortedDates.length == 0 && (
                <div className="flex h-full items-center text-center text-secondaryText">
                  <span>{t("specialistsPage.specialistCard.noAvailableSessions")}</span>
                </div>
              )}
            </div>
            <div className="pt-4 text-secondaryText hover:text-primaryText">
              <LocalisedLink
                to={routes.specialistDetails(specialist.slug, "calendar")}
                className="flex grow flex-row items-center justify-center gap-2 text-center font-medium text-secondaryText underline underline-offset-4 hover:text-primaryText"
              >
                {t("specialistsPage.specialistCard.showMoreHours")}
                <ExternalLink size={16} strokeWidth={2} />
              </LocalisedLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export { SpecialistCardDesktopList };
