import React, { useEffect, useState } from "react";

import { PhoneConfirmation, localisedPath, useSession, useSteps } from "@/components";
import routes from "@/constants/routes";
import { useUpdatePatientDetailsMutation } from "@/store/apis";
import { selectUser } from "@/store/slices";
import { zodResolver } from "@hookform/resolvers/zod";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PhoneInput } from "react-international-phone";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { z } from "zod";

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Label,
  Textarea,
} from "@/components/ui";

import { ApiError, formatErrorMessage } from "@/lib/utils";

import { handleBackClick } from "./SessionScheduling";

import "react-international-phone/style.css";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phone);
    const verified = phoneUtil.isValidNumber(parsedNumber);
    return verified;
  } catch (error) {
    return false;
  }
};

const PatientDataForm: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { setCurrentStep, currentStep } = useSteps();
  const navigate = useNavigate();
  const { specialist, serviceId, slot } = useSession();
  const { visitId } = useParams();
  const [apiError, setApiError] = useState<string | undefined>("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setCurrentStep("step2");
  }, [setCurrentStep]);

  useEffect(() => {
    setApiError("");
  }, [setIsOpen]);

  const [updatePatientDetails] = useUpdatePatientDetailsMutation();
  const user = useSelector(selectUser);

  const formSchema = z.object({
    phoneNumber: z.string().refine((value) => isPhoneValid(value), {
      message: t("sessionScheduling.patientDataForm.enterValidPhoneNumber"),
    }),
    additionalInfo: z.string(),
  });

  const storageKeyPhoneNumber = `sessionBooking_phoneNumber_${visitId}`;
  const storageKeyAdditionalInfo = `sessionBooking_additionalInfo_${visitId}`;

  const phone_number = localStorage.getItem(storageKeyPhoneNumber);
  const phone_number_with = `+${phone_number}`;

  const defaultValues: Partial<z.infer<typeof formSchema>> = {
    additionalInfo: localStorage.getItem(storageKeyAdditionalInfo) || "",
  };

  if (phone_number_with != "+") {
    defaultValues.phoneNumber = phone_number_with;
  }

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: "onBlur",
  });

  const onPhoneNumberChange = (field: string) => {
    localStorage.setItem(storageKeyPhoneNumber, field || "");
    setApiError("");
  };

  const onAdditionalInfoChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    localStorage.setItem(storageKeyAdditionalInfo, event.target.value || "");
  };

  const handleSubmit = async () => {
    setApiError("");
    if (visitId) {
      try {
        const resultAction = await updatePatientDetails({
          id: Number(visitId),
          phone_number: form.getValues().phoneNumber,
          additional_info: form.getValues().additionalInfo,
          lang: i18n.language,
        });
        if (resultAction.error) {
          throw resultAction.error;
        }

        if (!resultAction.data.phone_number_confirmed) {
          setIsOpen(true);
        }

        if (specialist && resultAction.data.phone_number_confirmed) {
          const nextRoute = routes.schedulePaymentDetails(
            specialist.id.toString(),
            serviceId?.toString(),
            slot?.toString(),
            visitId?.toString()
          );
          navigate(localisedPath(nextRoute));
        }
      } catch (error) {
        setApiError(formatErrorMessage(error as ApiError));
      }
    }
  };

  return (
    <>
      <PhoneConfirmation isOpen={isOpen} onOpenChange={setIsOpen} phone_number={form.getValues().phoneNumber} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="w-full">
          <div className="flex h-full flex-grow flex-col gap-3 lg:gap-7" data-testid="patient-data-form">
            <div className="space-y-2">
              <h3 className="text-base font-bold text-primaryText lg:text-xl">
                {t("sessionScheduling.patientDataForm.yourData")}
              </h3>
              <Label className="block pt-2 lg:text-base">{t("sessionScheduling.patientDataForm.firstName")}</Label>
              <Input
                value={user?.first_name ?? ""}
                disabled={true}
                className="disabled:border-gray-300 disabled:bg-veryLightGray disabled:opacity-100 lg:text-base"
              ></Input>
              <Label className="block pt-2 lg:text-base">{t("sessionScheduling.patientDataForm.lastName")}</Label>
              <Input
                value={user?.last_name ?? ""}
                disabled={true}
                className="disabled:border-gray-300 disabled:bg-veryLightGray disabled:opacity-100 lg:text-base"
              ></Input>
            </div>

            <div className="space-y-2">
              <h3 className="text-base font-bold text-primaryText lg:text-xl">
                {t("sessionScheduling.patientDataForm.contactInformation")}
              </h3>
              <FormField
                data-testid="phone-number-field"
                control={form.control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="block pt-2 lg:text-base">
                      {t("sessionScheduling.patientDataForm.phoneNumber")}
                    </FormLabel>
                    <FormControl>
                      <PhoneInput
                        defaultCountry="pl"
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);
                          onPhoneNumberChange(value);
                        }}
                        placeholder="+48 123 456 789"
                        inputClassName="w-full px-3 py-2 file:font-medium focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 lg:w-36 lg:text-base"
                      />
                    </FormControl>
                    <FormMessage className="text-sm font-normal" />
                  </FormItem>
                )}
              />
              <div className="text-secondaryText">{t("sessionScheduling.patientDataForm.phoneNumberUse")}</div>
            </div>

            <div className="space-y-2">
              <h3 className="text-base font-medium text-primaryText lg:text-xl">
                {t("sessionScheduling.patientDataForm.additionalInfo")}{" "}
                <span className="font-normal">({t("sessionScheduling.patientDataForm.optional")})</span>
              </h3>
              <FormField
                control={form.control}
                name="additionalInfo"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        placeholder={t("sessionScheduling.patientDataForm.exampleAdditionalInfo")}
                        {...field}
                        className="border-gray-300 lg:text-base"
                        onChange={(e) => {
                          field.onChange(e);
                          onAdditionalInfoChange(e);
                        }}
                      ></Textarea>
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <div className="mt-auto w-full space-y-3">
              {apiError && <p className="text-redApp">{apiError}</p>}
              <div className="flex gap-2">
                <Button
                  type="button"
                  className="px-6 lg:text-base"
                  onClick={() =>
                    handleBackClick(
                      currentStep,
                      setCurrentStep,
                      navigate,
                      specialist?.id?.toString(),
                      serviceId?.toString(),
                      slot?.toString(),
                      visitId?.toString()
                    )
                  }
                >
                  {t("sessionScheduling.back")}
                </Button>
                <Button
                  data-testid="next-button"
                  variant="yellow"
                  className="w-full lg:w-52 lg:text-base"
                  type="submit"
                >
                  {t("sessionScheduling.next")}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </>
  );
};

export { PatientDataForm };
