import { Dispatch, SetStateAction, useState } from "react";

import { useCancelVisitMutation } from "@/store/apis";
import { useParams } from "react-router-dom";

import { isDesktop } from "@/lib/mediaQuery";
import { ApiError, formatErrorMessage } from "@/lib/utils";

import { SessionStatus } from "@/types/enums";

import { CancelSessionDialog } from "./CancelSessionDialog";
import { CancelSessionDrawer } from "./CancelSessionDrawer";

type CancelSessionProps = {
  setSessionStatus: Dispatch<SetStateAction<string>>;
  eligibleForRefund: boolean;
};

const CancelSession: React.FC<CancelSessionProps> = ({ setSessionStatus, eligibleForRefund }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [apiError, setApiError] = useState<string | undefined>("");
  const [visitCancelled, setVisitCancelled] = useState(false);
  const { visitId } = useParams();
  const [cancelVisit] = useCancelVisitMutation();

  const handleVisitCancel = async () => {
    try {
      const resultAction = await cancelVisit({
        id: Number(visitId),
      });
      if (resultAction.error) {
        throw resultAction.error;
      }
      setVisitCancelled(true);
    } catch (error) {
      setApiError(formatErrorMessage(error as ApiError));
      setVisitCancelled(false);
    }
  };

  const cancel = () => {
    setDrawerOpen(false);
    setDialogOpen(false);
    if (visitCancelled) {
      setSessionStatus(SessionStatus.Cancelled);
    }
  };

  const drawerProps = {
    cancel,
    eligibleForRefund,
    handleVisitCancel,
    apiError,
    visitCancelled,
    drawerOpen,
    setDrawerOpen,
  };

  const dialogProps = {
    cancel,
    eligibleForRefund,
    handleVisitCancel,
    apiError,
    visitCancelled,
    dialogOpen,
    setDialogOpen,
  };

  if (isDesktop()) {
    return <CancelSessionDialog {...dialogProps} />;
  }

  return <CancelSessionDrawer {...drawerProps} />;
};

export { CancelSession };
