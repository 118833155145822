import { SlotDto, SpecialistDto, SpecialistListDto } from "@/types/types";

import { mhhlApi as api } from "./mhhlApi";

const specialistApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSpecialists: build.query<GetSpecialistsApiResponse, GetSpecialistsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/specialists`,
        params: {
          locale: queryArg.lang || "pl",
        },
      }),
      providesTags: ["Specialists"],
    }),
    getSpecialist: build.query<GetSpecialistApiResponse, GetSpecialistApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/specialists/${queryArg.id}`,
        params: {
          locale: queryArg.lang || "pl",
        },
      }),
    }),
    getSpecialistPreview: build.query<GetSpecialistApiResponse, GetSpecialistApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/specialist_profiles/${queryArg.id}`,
        params: {
          locale: queryArg.lang || "pl",
        },
      }),
    }),
    getSpecialistTimeSlots: build.query<GetSpecialistTimeSlotsApiResponse, GetSpecialistTimeSlotsApiArg>({
      query: (queryArg) => {
        const params = queryArg.serviceId ? { service: queryArg.serviceId } : {};
        return {
          url: `/api/v1/specialists/${queryArg.id}/time_slots`,
          params,
        };
      },
      providesTags: (_result, _error, queryArg) => [{ type: "SpecialistTimeSlots", id: queryArg.id.toString() }],
    }),
  }),
  overrideExisting: false,
});

type GetSpecialistsApiResponse = /** status 200 OK */ {
  specialists: SpecialistListDto[];
};
type GetSpecialistsApiArg = { lang?: string };
type GetSpecialistApiResponse = /** status 200 OK */ SpecialistDto;

type GetSpecialistApiArg = {
  id: number | string;
  lang?: string;
};

type GetSpecialistTimeSlotsApiArg = {
  id: number;
  serviceId?: number;
};
type GetSpecialistTimeSlotsApiResponse = { time_slots: SlotDto[] };

export { specialistApi as specialistsApi };
export const {
  useGetSpecialistsQuery,
  useGetSpecialistQuery,
  useGetSpecialistPreviewQuery,
  useGetSpecialistTimeSlotsQuery,
} = specialistApi;
