import { Skeleton } from "@/components/ui";

const SpecialistProfileHeroSkeleton = () => {
  return (
    <div className="w-full justify-center space-y-4 pb-3">
      <Skeleton className="relative h-[200px] w-full rounded-lg object-cover lg:min-h-64 lg:min-w-64" />
      <div className="space-y-2 self-stretch">
        <Skeleton className="h-6 w-40" />
        <Skeleton className="h-5 w-60" />
        <div className="flex w-full flex-row items-center gap-3">
          <Skeleton className="h-5 w-40" />
        </div>
      </div>
    </div>
  );
};
export { SpecialistProfileHeroSkeleton };
