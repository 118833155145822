import { mhhlApi as api } from "./mhhlApi";

export const availableLanguagesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLanguages: build.query<GetLanguagesApiResponse, GetLanguagesApiArg>({
      query: () => ({ url: `/api/v1/languages` }),
    }),
  }),
  overrideExisting: false,
});
export type GetLanguagesApiResponse = /** status 200 OK */ {
  languages: string[];
};
export type GetLanguagesApiArg = void;
export const { useGetLanguagesQuery } = availableLanguagesApi;
