import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { SpecialistsView } from "@/types/enums";

export type SortOptions = {
  sortBy: "last_name" | "availability";
  sortOrder: "asc" | "desc";
};

type FilterOptions = {
  specialisations: number[];
  languages: string[];
};

type SearchOptions = {
  searchTerm: string;
};

type ViewOptions = {
  viewType: SpecialistsView;
};

type SpecialistsQueryParamsState = SortOptions & FilterOptions & SearchOptions & ViewOptions;

const initialSortOptionsState: SortOptions = {
  sortBy: "last_name",
  sortOrder: "asc",
};

const initialFilterOptionsState: FilterOptions = {
  specialisations: [],
  languages: [],
};

const initialSearchOptionsState: SearchOptions = {
  searchTerm: "",
};

const initialView: ViewOptions = {
  viewType: SpecialistsView.Grid,
};

const initialState: SpecialistsQueryParamsState = {
  ...initialSearchOptionsState,
  ...initialFilterOptionsState,
  ...initialSortOptionsState,
  ...initialView,
};

export const SpecialistsQueryParamsSlice = createSlice({
  name: "specialistsQueryParams",
  initialState,

  reducers: {
    setFilters: (state, { payload }: PayloadAction<FilterOptions>) => {
      state.specialisations = payload.specialisations;
      state.languages = payload.languages;
    },
    setSort: (state, { payload }: PayloadAction<SortOptions>) => {
      state.sortBy = payload.sortBy;
      state.sortOrder = payload.sortOrder;
    },
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
    setView: (state, { payload }: PayloadAction<ViewOptions>) => {
      state.viewType = payload.viewType;
    },
  },

  selectors: {
    selectSpecialistQueryParams: (state) => state,
  },
});

export const { setFilters, setSort, setSearchTerm, setView } = SpecialistsQueryParamsSlice.actions;

export const { selectSpecialistQueryParams } = SpecialistsQueryParamsSlice.selectors;

export const selectView = createSelector(
  (state: { specialistsQueryParams: SpecialistsQueryParamsState }) => state.specialistsQueryParams.viewType,
  (view) => view
);

export {
  initialState as initialSpecialistsQueryParamsState,
  initialFilterOptionsState as initialSpecialistsFilterState,
};
