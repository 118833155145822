import { useCallback } from "react";

import { Button, Separator } from "@/components";
import { selectSpecialisations, selectSpecialistsFilters } from "@/store/selectors";
import { initialSpecialistsFilterState, setFilters } from "@/store/slices";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { XMark } from "@/assets/images";

import { toggleInArray } from "@/lib/utils";

const FiltersBar: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedFilters = useSelector(selectSpecialistsFilters);
  const specialisations = useSelector(selectSpecialisations);
  const languages = selectedFilters.languages.reduce((acc: { [key: string]: string }, lang) => {
    acc[lang] = t(`general.languages.${lang}`);
    return acc;
  }, {});

  const clearFilters = useCallback(() => {
    dispatch(setFilters(initialSpecialistsFilterState));
  }, [dispatch]);

  const toggleLanguage = useCallback(
    (language: string) => {
      const newFilters = { ...selectedFilters };
      newFilters.languages = toggleInArray(newFilters.languages, language);
      dispatch(setFilters(newFilters));
    },
    [selectedFilters, dispatch]
  );

  const toggleSpecialisation = useCallback(
    (specialisationId: number) => {
      const newFilters = { ...selectedFilters };
      newFilters.specialisations = toggleInArray(newFilters.specialisations, specialisationId);
      dispatch(setFilters(newFilters));
    },
    [selectedFilters, dispatch]
  );

  return (
    <>
      {(selectedFilters.languages.length > 0 || selectedFilters.specialisations.length > 0) && (
        <div className="flex items-center gap-2.5 overflow-x-scroll text-nowrap scrollbar-hide lg:flex-wrap">
          <Button variant="ghost" className="px-0 text-secondaryText hover:bg-inherit" onClick={clearFilters}>
            {t("specialistsPage.filters.resetFilters")}
          </Button>
          <Separator orientation="vertical" className="h-3.5 bg-veryLightGray" />

          {selectedFilters.specialisations.map((specialisation) => (
            <Button variant="gray" className="px-2" key={specialisation}>
              {specialisations.find((s) => s.id === specialisation)?.universal_name}
              <img
                src={XMark}
                className="ml-1 min-w-[12px]"
                onClick={() => toggleSpecialisation(specialisation)}
                alt={t("specialistsPage.filters.removeFilter")}
              />
            </Button>
          ))}
          {Object.keys(languages).map((language) => (
            <Button variant="gray" className="px-2" key={language}>
              {languages[language]}
              <img
                src={XMark}
                className="ml-1 min-w-[12px]"
                onClick={() => toggleLanguage(language)}
                alt={t("specialistsPage.filters.removeFilter")}
              />
            </Button>
          ))}
        </div>
      )}
    </>
  );
};
export { FiltersBar };
