import React from "react";

import { useAuth } from "@/auth";
import { LocalisedLink } from "@/components";
import { apiPaths, apiRoute } from "@/constants/apiRoutes";
import routes from "@/constants/routes";
import { ChevronDown } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarSeparator, MenubarTrigger } from "@/components/ui";

const NavigationMenu: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { logout } = useAuth();

  return (
    <Menubar className="lg:-mr-5">
      <MenubarMenu>
        <MenubarTrigger
          data-testid="user-menu"
          className="border-none px-0 focus:bg-inherit data-[state=open]:bg-inherit data-[state=open]:text-secondaryText lg:px-3"
        >
          {t("navbar.account")}
          <ChevronDown strokeWidth={1.5} className="ml-1" />
        </MenubarTrigger>
        <MenubarContent className="min-w-40 text-base lg:mr-5" align="end">
          <MenubarItem asChild>
            <LocalisedLink to={routes.myVisits}>{t("navbar.myVisits")}</LocalisedLink>
          </MenubarItem>
          <MenubarSeparator />
          <MenubarItem asChild>
            <LocalisedLink to={routes.myVouchers}>{t("navbar.myVouchers")}</LocalisedLink>
          </MenubarItem>
          <MenubarSeparator />
          <MenubarItem asChild>
            <Link to={apiRoute(apiPaths.account)}>{t("navbar.accountSettings")}</Link>
          </MenubarItem>
          <MenubarSeparator />
          <MenubarItem onClick={logout}>{t("navbar.logOut")} </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  );
});

export { NavigationMenu };
