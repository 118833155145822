import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import pl from "./locales/pl.json";
import uk from "./locales/uk.json";

const availableLanguages = ["pl", "en", "uk"];
const defaultLanguage = "pl";

if (
  localStorage.getItem("i18nextLng") === null ||
  !availableLanguages.includes(localStorage.getItem("i18nextLng") as string)
) {
  localStorage.setItem("i18nextLng", defaultLanguage);
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: en,
      pl: pl,
      uk: uk,
    },
    fallbackLng: defaultLanguage,
    debug: true,
    supportedLngs: availableLanguages,
    nonExplicitSupportedLngs: true,
    detection: {
      order: ["path", "localStorage", "navigator"],
      lookupFromPathIndex: 0,
      caches: ["localStorage"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
export { availableLanguages, defaultLanguage };
