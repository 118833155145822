import React from "react";

import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App.tsx";
import "./assets/styles/globals.css";
import "./i18n";
import "./sentry";
import { store } from "./store";

import "@smastrom/react-rating/style.css";

export const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
  window.location.replace(toRelativeUrl(originalUri || "/", window.location.origin));
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
