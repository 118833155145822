import { oktaAuth } from "@/auth/auth";
import { authLogin } from "@/auth/authUtils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const mhhlApi = createApi({
  baseQuery: async (args, api, extraOptions) => {
    const baseQuery = fetchBaseQuery({
      baseUrl: import.meta.env.VITE_MHHL_API_ENDPOINT,
      prepareHeaders: async (headers) => {
        const accessToken = await oktaAuth.getAccessToken();
        if (accessToken) {
          headers.set("Authorization", `Bearer ${accessToken}`);
        }
      },
    });

    const result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
      console.warn("401 Unauthorized - Redirecting to login");
      await authLogin();
    }

    return result;
  },
  tagTypes: ["Specialists", "SpecialistTimeSlots"],
  endpoints: () => ({}),
});
