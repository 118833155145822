import { useEffect } from "react";

import { useAuth } from "@/auth";

const LoginRedirectPage = () => {
  const { login } = useAuth();

  useEffect(() => {
    login();
  }, [login]);

  return (
    <div className="flex min-h-screen min-w-80 flex-col items-center justify-center bg-backgroundApp">
      Redirecting...
    </div>
  );
};

export { LoginRedirectPage };
