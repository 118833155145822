import { useEffect } from "react";

import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

// noinspection JSUnusedGlobalSymbols
Sentry.init({
  dsn: "https://ebcc94c7137bda180705c3ec0d60aeb8@o4507451881816064.ingest.de.sentry.io/4507451906326608",
  integrations: [
    Sentry.feedbackIntegration({
      triggerLabel: "Zgłoś uwagi",
      formTitle: "Zgłoś uwagi",
      submitButtonLabel: "Wyślij",
      cancelButtonLabel: "Anuluj",
      addScreenshotButtonLabel: "Dodaj zrzut ekranu",
      removeScreenshotButtonLabel: "Usuń zrzut ekranu",
      nameLabel: "Imię",
      namePlaceholder: "Twoje imię",
      emailLabel: "Adres email",
      messageLabel: "Treść",
      messagePlaceholder: "Chcesz podzielić się opinią lub zgłosić sugestię? A może znalazłeś błąd? Napisz nam o tym!",
      successMessageText: "Dziękujemy za przesłanie uwag!",
      isRequiredLabel: "(Pole wymagane)",
      showBranding: false,
      colorScheme: "light",
      themeLight: {
        accentBackground: "#f9a825",
      },
    }),
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 0.1,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,

  // TODO: Add option to report error in our error handling toast / or sth so the user is not presented with this always.
  beforeSend(event) {
    if (event.exception && event.event_id) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  enabled: import.meta.env.VITE_MODE === "production" || import.meta.env.VITE_MODE === "staging",
  environment: import.meta.env.VITE_MODE,
});
