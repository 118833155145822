import { useState } from "react";

import { FiltersDrawer } from "@/components";
import { setSearchTerm } from "@/store/slices";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { SearchIcon } from "@/assets/images";

import { Button } from "@/components/ui";

import { FiltersBar } from "./FiltersBar";
import { Search } from "./Search";
import { SortingDrawer } from "./SortingDrawer";

const QueryBar = () => {
  const { t } = useTranslation();
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const dispatch = useDispatch();

  const openSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const closeSearch = () => {
    setIsSearchOpen(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchTerm(e.target.value));
  };

  return (
    <>
      <div className={`flex gap-2 ${isSearchOpen ? "flex-row-reverse" : ""}`}>
        {isSearchOpen ? (
          <Search onClose={closeSearch} handleSearchChange={handleSearchChange} />
        ) : (
          <>
            <Button variant="yellow" className="min-h-10 px-3.5 py-3" onClick={openSearch}>
              <img src={SearchIcon} alt={t("general.alt.search")} className="min-h-3.5 min-w-3.5" />
            </Button>
            <FiltersDrawer />
            <SortingDrawer />
          </>
        )}
      </div>
      <FiltersBar />
    </>
  );
};

export { QueryBar };
