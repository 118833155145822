import { VoucherDto } from "@/types/types";

import { mhhlApi as api } from "./mhhlApi";

type GetVoucherApiResponse = {
  vouchers: VoucherDto[];
};

type GetVoucherApiArg = string;

type ConnectVoucherApiResponse = VoucherDto;

type ConnectVoucherApiArg = {
  id: string;
  code: string;
};

const vouchersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getActiveVouchers: build.query<GetVoucherApiResponse, GetVoucherApiArg>({
      query: (id) => `/api/v1/patients/${id}/vouchers/active`,
    }),
    getInactiveVouchers: build.query<GetVoucherApiResponse, GetVoucherApiArg>({
      query: (id) => `/api/v1/patients/${id}/vouchers/inactive`,
    }),
    connectVoucher: build.mutation<ConnectVoucherApiResponse, ConnectVoucherApiArg>({
      query: ({ id, code }) => ({
        url: `/api/v1/patients/${id}/connect_voucher/${code}`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});

export { vouchersApi };
export const { useGetActiveVouchersQuery, useGetInactiveVouchersQuery, useConnectVoucherMutation } = vouchersApi;
