import React from "react";

import { selectSpecialistsSortOptions } from "@/store/selectors";
import { SortOptions, setSort } from "@/store/slices";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Separator } from "@/components/ui";

const SortingSelect: React.FC = () => {
  const { t } = useTranslation();
  const currentSortOptions = useSelector(selectSpecialistsSortOptions);
  const dispatch = useDispatch();

  const sortMap: SortOptions[] = [
    { sortBy: "last_name", sortOrder: "asc" },
    { sortBy: "last_name", sortOrder: "desc" },
    { sortBy: "availability", sortOrder: "asc" },
  ];

  const translationForSort = (sortItem: SortOptions) => {
    let translationKey = "";
    if (sortItem.sortBy === "last_name") {
      translationKey = sortItem.sortOrder === "asc" ? "az" : "za";
    } else if (sortItem.sortBy === "availability") {
      translationKey = "availability";
    }
    return t(`specialistsPage.sort.options.${translationKey}`);
  };

  const handleSort = (sortKey: string) => {
    const [sortBy, sortOrder] = sortKey.split("-");
    dispatch(setSort({ sortBy, sortOrder } as SortOptions));
  };

  return (
    <Select onValueChange={handleSort} defaultValue={translationForSort(currentSortOptions)}>
      <SelectTrigger className="inline-flex max-w-60 rounded-lg px-4 py-2 text-start font-medium focus:ring-0 focus:ring-offset-0">
        <SelectValue>
          {t("specialistsPage.sort.sortBy")} {translationForSort(currentSortOptions)}
        </SelectValue>
      </SelectTrigger>
      <SelectContent className="rounded-lg shadow-sm">
        {sortMap.map((sortOption, index) => (
          <React.Fragment key={`language-fragment-${index}`}>
            <SelectItem
              value={`${sortOption.sortBy}-${sortOption.sortOrder}`}
              className="font-medium focus:bg-veryLightGray/50 focus:text-secondaryText"
            >
              {translationForSort(sortOption)}
            </SelectItem>
            {index < sortMap.length - 1 && <Separator className="mx-2 my-1 w-auto" />}
          </React.Fragment>
        ))}
      </SelectContent>
    </Select>
  );
};

export { SortingSelect };
