import React from "react";

import { useAuth } from "@/auth";
import { Button, LanguageSwitcher, LocalisedLink } from "@/components";
import routes from "@/constants/routes";
import { useTranslation } from "react-i18next";

import { Logo } from "@/assets/images";

import { NavigationMenu } from "./NavigationMenu";

const Header: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated, login } = useAuth();

  return (
    <div className="w-screen border-b border-veryLightGray bg-white">
      <div className="m-auto flex w-full justify-between p-3 px-5 lg:w-4/5 lg:max-w-screen-xl lg:px-0 lg:pb-5 lg:pt-10">
        <LocalisedLink to={routes.root}>
          <img src={Logo} data-testid="logo" alt="logo" className="w-[62px] lg:ml-[-32px] lg:min-w-[164px]" />
        </LocalisedLink>
        <div className="flex items-center gap-3">
          <LanguageSwitcher />
          {isAuthenticated() ? (
            <NavigationMenu />
          ) : (
            <Button
              variant="yellow"
              className="h-5 rounded-lg uppercase shadow-yellow lg:px-9 lg:py-6 lg:text-sm"
              data-testid="login-button"
              onClick={login}
            >
              <p>{t("navbar.logIn")}</p>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export { Header };
