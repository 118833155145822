import React, { useEffect, useMemo, useState } from "react";

import { useAuth } from "@/auth";
import { Button, Skeleton, StatusBadge, localisedPath, useSession, useSteps } from "@/components";
import routes from "@/constants/routes";
import { CreateVisitApiArg, UpdateVisitApiArg, useCreateVisitMutation, useUpdateVisitMutation } from "@/store/apis";
import { selectUser } from "@/store/slices";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ApiError, cn, formatErrorMessage } from "@/lib/utils";

import { SpecialistServiceDto } from "@/types/types";

import { SelectedService } from "./SelectedService";

const SessionDetailsForm: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { setCurrentStep } = useSteps();
  const navigate = useNavigate();
  const { isAuthenticated, login } = useAuth();
  const [createVisit] = useCreateVisitMutation();
  const [updateVisit] = useUpdateVisitMutation();
  const { visitId } = useParams();
  const user = useSelector(selectUser);
  const [apiError, setApiError] = useState<string | undefined>("");

  useEffect(() => {
    setCurrentStep("step1");
  }, [setCurrentStep]);

  const { specialist, serviceId, slot, setVisitId } = useSession();
  const selectedService = useMemo(() => {
    return specialist?.services.find((service) => service.id === Number(serviceId));
  }, [specialist, serviceId]);

  const canSubmit = () => {
    return specialist && serviceId && slot;
  };

  const handleSubmission = async () => {
    if (!isAuthenticated()) {
      login();
      return;
    }
    if (specialist && serviceId && slot) {
      try {
        const visitData = {
          start_time: slot,
          specialist_id: specialist.id,
          service_id: Number(serviceId),
          status: "draft",
          additional_info: "",
          patient_ids: [Number(user?.patient_id)],
          language: selectedLanguage,
          communication_mode: selectedChannel,
        };

        let resultAction;
        if (!visitId) {
          const newVisit: CreateVisitApiArg = { visit: visitData, lang: i18n.language };
          resultAction = await createVisit(newVisit);
          localStorage.setItem(
            `sessionBooking_phoneNumber_${resultAction.data?.visit.id}`,
            resultAction.data?.phone_number || ""
          );
        } else {
          const updateVisitArg: UpdateVisitApiArg = {
            id: Number(visitId),
            language: visitData.language,
            communication_mode: selectedChannel,
            lang: i18n.language,
          };
          resultAction = await updateVisit(updateVisitArg);
          localStorage.setItem(
            `sessionBooking_phoneNumber_${resultAction.data?.visit.id}`,
            resultAction.data?.phone_number || ""
          );
        }

        if (resultAction.error) {
          throw resultAction.error;
        }

        if (resultAction.data?.visit?.id && setVisitId) {
          setVisitId(resultAction.data.visit.id);
        }

        const nextRoute = routes.schedulePatientData(
          specialist.id.toString(),
          serviceId.toString(),
          slot.toString(),
          resultAction.data.visit.id.toString()
        );
        navigate(localisedPath(nextRoute));
      } catch (error) {
        setApiError(formatErrorMessage(error as ApiError));
      }
    }
  };

  const storageKeyLanguage = `sessionBooking_language_${specialist?.id}${serviceId}${slot}`;
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem(storageKeyLanguage) || specialist?.languages[0]
  );

  function toggleLanguage(language: string) {
    setSelectedLanguage(language);
    localStorage.setItem(storageKeyLanguage, language);
  }

  const storageKeyCommunicationChannel = `sessionBooking_communicationChannel_${specialist?.id}${serviceId}${slot}`;
  const defaultChannel = specialist?.communication_modes?.[0] ? Object.keys(specialist.communication_modes[0])[0] : "";
  const [selectedChannel, setSelectedChannel] = useState(
    localStorage.getItem(storageKeyCommunicationChannel) || defaultChannel
  );

  function toggleCommunicationChannel(channel: string) {
    setSelectedChannel(channel);
    localStorage.setItem(storageKeyCommunicationChannel, channel);
  }

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem(storageKeyLanguage) || specialist?.languages[0]);
    setSelectedChannel(localStorage.getItem(storageKeyCommunicationChannel) || defaultChannel);
  }, [specialist]);

  return (
    <div data-testid="session-details-form" className="flex flex-grow flex-col justify-between space-y-3 lg:space-y-7">
      <div className="flex flex-col space-y-3 lg:space-y-7">
        <div>
          <div className="flex gap-2 pb-1">
            <h3 className="text-base font-bold text-primaryText lg:text-xl">
              {t("sessionScheduling.consultationType")}
            </h3>
            <StatusBadge />
          </div>
          <SelectedService selectedService={selectedService as SpecialistServiceDto} />
        </div>
        <div>
          <h3 className="text-base font-bold text-primaryText lg:text-xl">{t("sessionScheduling.sessionLanguage")}</h3>
          <div className="flex flex-wrap gap-3 py-1">
            {!specialist && (
              <>
                <Skeleton className="h-9 w-20" />
                <Skeleton className="h-9 w-20" />
              </>
            )}
            {specialist?.languages.map((language) => (
              <Button
                type="button"
                key={language}
                className={cn(
                  "hover:bg-veryLightGray/50",
                  selectedLanguage === language ? "bg-veryLightGray hover:bg-veryLightGray/50" : "bg-white"
                )}
                onClick={() => toggleLanguage(language)}
              >
                {t(`general.languages.${language}`)}
              </Button>
            ))}
          </div>
        </div>
        <div>
          <h3 className="text-base font-bold text-primaryText lg:text-xl">
            {t("sessionScheduling.communicationChannel")}
          </h3>
          <div className="flex flex-wrap gap-3 py-1">
            {!specialist && (
              <>
                <Skeleton className="h-9 w-20" />
                <Skeleton className="h-9 w-20" />
              </>
            )}
            {specialist?.communication_modes.map((channel) => {
              const [key, channelName] = Object.entries(channel)[0];
              return (
                <Button
                  type="button"
                  key={key}
                  className={cn(
                    "hover:bg-veryLightGray/50",
                    selectedChannel === key ? "bg-veryLightGray hover:bg-veryLightGray/50" : "bg-white"
                  )}
                  onClick={() => toggleCommunicationChannel(key)}
                >
                  {channelName}
                </Button>
              );
            })}
          </div>
        </div>
        <div>
          <h3 className="text-base font-bold text-primaryText lg:text-xl">{t("sessionScheduling.payment")}</h3>
          <p className="pt-1 text-sm font-normal text-secondaryText">
            {t("sessionScheduling.paymentInfo")}
            <span className="text-sm font-bold text-secondaryText underline">{t("sessionScheduling.here")}</span>
          </p>
        </div>
      </div>
      <div className="space-y-3">
        {apiError && <p className="text-redApp">{apiError}</p>}
        <Button
          data-testid="next-button"
          variant="yellow"
          className="w-full lg:w-52 lg:text-base"
          disabled={!canSubmit()}
          onClick={handleSubmission}
        >
          {t("sessionScheduling.next")}
        </Button>
        {!isAuthenticated() && (
          <p className="text-center text-secondaryText lg:text-left">{t("sessionScheduling.nextStepInfo")}</p>
        )}
      </div>
    </div>
  );
};

export { SessionDetailsForm };
