import { Separator, Skeleton } from "@/components/ui";

const SessionCardSkeleton = () => {
  return (
    <>
      <Skeleton className="my-5 h-4 w-24 lg:!mt-6 lg:h-5" />
      <div className="mx-auto flex max-w-sm flex-col gap-2 rounded-lg border border-solid border-veryLightGray bg-white p-4 sm:max-w-md md:max-w-full md:gap-4">
        <div className="flex flex-row items-center justify-between gap-12 border-b p-0 pb-2">
          <div className="flex flex-row gap-2">
            <div className="hidden w-28 md:block">
              <Skeleton className="h-4 w-24 lg:h-5" />
            </div>
            <Skeleton className="h-4 w-16 lg:h-5" />
            <Separator orientation="vertical" className="h-5 bg-veryLightGray" />
            <Skeleton className="h-4 w-12 lg:h-5" />
          </div>
          <div className="flex flex-row gap-3">
            <Skeleton className="h-4 w-16 lg:hidden" />
            <Skeleton className="h-4 w-2 lg:h-8 lg:w-8 lg:rounded-full" />
          </div>
        </div>
        <div className="p-0">
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-row items-start gap-3.5 p-0 md:grow md:items-center">
              <Skeleton className="h-10 w-10 rounded-full md:h-[72px] md:w-[72px]" />
              <div className="m-0 flex flex-col gap-1">
                <Skeleton className="h-4 w-32 lg:h-5 lg:w-40" />
                <Skeleton className="h-4 w-40 lg:h-5 lg:w-60" />
              </div>
            </div>
            <div className="flex gap-2 py-2 md:ml-auto md:w-40 md:flex-col-reverse md:place-content-end md:py-0">
              <Skeleton className="h-8 w-full" />
              <Skeleton className="h-8 w-full" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export { SessionCardSkeleton };
