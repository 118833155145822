import React from "react";

import { StatusBadge } from "@/components";
import { useTranslation } from "react-i18next";

import { Card, CardContent, CardDescription, CardHeader } from "@/components/ui";

import { formatYearDateFromISO } from "@/lib/utils";

import { VoucherStatus } from "@/types/enums";
import { VoucherDto } from "@/types/types";

type VoucherCardProps = {
  voucher: VoucherDto;
  status: VoucherStatus;
  onClick?: (voucher: VoucherDto) => void;
  selected?: boolean;
};

const VoucherCard = React.memo<VoucherCardProps>(({ voucher, status, onClick, selected }: VoucherCardProps) => {
  const { t } = useTranslation();

  return (
    <Card
      data-testid="voucher-card"
      className={`flex max-w-sm flex-col gap-1 rounded-lg border border-veryLightGray p-4 sm:max-w-md md:max-w-full md:gap-4 ${
        onClick ? "cursor-pointer" : ""
      } ${selected ? "border-white outline outline-2 outline-mossGreen" : ""}`}
      onClick={() => onClick?.(voucher)}
    >
      <CardHeader className="flex flex-row items-center justify-between p-0 pb-2 md:border-b">
        <div className="flex flex-row items-center gap-2">
          <StatusBadge status={status} className="-mt-0.5 mr-10 hidden md:block" />
          <span>{voucher.code}</span>
          {/* <Separator orientation="vertical" className="h-5 bg-veryLightGray" /> */}
        </div>
        <div className="flex flex-row gap-3">
          <span className="text-sm font-semibold text-mossGreen lg:text-base">-{voucher.discount}</span>
        </div>
      </CardHeader>
      <CardContent className="p-0">
        <CardDescription className="flex max-w-60 flex-col break-words font-normal text-secondaryText md:max-w-full md:text-sm">
          <span>
            {t("myVouchersPage.sessionsUsed")} {voucher.patient_redemptions_count}/{voucher.patient_redemptions_limit}{" "}
          </span>
          <span>
            {t("myVouchersPage.issuedBy")} {voucher.issuer}
          </span>
          <span>
            {t("myVouchersPage.activeUntil")}{" "}
            {voucher.expiration_date ? formatYearDateFromISO(voucher.expiration_date) : "-"}
          </span>
        </CardDescription>
      </CardContent>
    </Card>
  );
});
export { VoucherCard };
