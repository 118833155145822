import { useState } from "react";

import { VoucherCardSkeleton, VouchersList } from "@/components";
import { RootState } from "@/store";
import {
  useConnectVoucherMutation,
  useGetActiveVouchersQuery,
  useGetInactiveVouchersQuery,
  vouchersApi,
} from "@/store/apis";
import { selectUser } from "@/store/slices";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Input } from "@/components/ui";

import { ApiError, formatErrorMessage } from "@/lib/utils";

import { VoucherStatus } from "@/types/enums";

function MyVouchersPage() {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, never> = useDispatch();

  const [voucherError, setVoucherError] = useState<string | undefined>("");
  const [voucherCode, setVoucherCode] = useState<string>("");

  const patientId = useSelector(selectUser)?.patient_id;
  const {
    data: activeVouchers,
    error: activeError,
    isLoading: activeLoading,
  } = useGetActiveVouchersQuery(patientId?.toString() || "", { skip: !patientId });

  const {
    data: inactiveVouchers,
    error: inactiveError,
    isLoading: inactiveLoading,
  } = useGetInactiveVouchersQuery(patientId?.toString() || "", { skip: !patientId });

  const [connectVoucher] = useConnectVoucherMutation();

  const patient_id = patientId?.toString() || "";

  const handleSubmit = async () => {
    try {
      const newVoucher = await connectVoucher({ id: patient_id, code: voucherCode }).unwrap();
      dispatch(
        vouchersApi.util.updateQueryData("getActiveVouchers", patient_id, (draft) => {
          draft.vouchers.push(newVoucher);
        })
      );
      setVoucherCode("");
    } catch (error) {
      setVoucherError(formatErrorMessage(error as ApiError));
    }
  };

  return (
    <div className="w-full flex-none items-center justify-center pt-2 [&>*]:mt-2">
      <h2 className="pt-4 text-xl font-bold text-primaryText lg:text-2xl">{t("myVouchersPage.connectVoucher")}</h2>
      <div>
        <div className="lg:max-w-100 my-1 flex h-12 w-full items-center rounded-lg border border-gray-300 bg-white px-2">
          <div className="h-full w-full items-center">
            <Input
              autoFocus
              type="text"
              placeholder={t("sessionScheduling.paymentForm.enterVoucherCode")}
              data-testid="voucher-code-input"
              value={voucherCode}
              onChange={(e) => {
                setVoucherCode(e.target.value);
                setVoucherError("");
              }}
              className="lg:w-stretch h-11 w-full border-0"
            />
          </div>
          <Button
            data-testid="connect-voucher-button"
            type="button"
            variant="yellow"
            onClick={() => handleSubmit()}
            className="font-bold"
          >
            {t("myVouchersPage.connectVoucher")}
          </Button>
        </div>
        {voucherError && (
          <span data-testid="voucher-error" className="text-redApp">
            {voucherError}
          </span>
        )}
      </div>
      <h2 data-testid="active-vouchers-title" className="pt-4 text-xl font-bold text-primaryText lg:text-2xl">
        {t("myVouchersPage.currentVouchers")}
      </h2>
      {t("myVouchersPage.currentVouchersDescription")}
      {activeLoading && (
        <div className="py-2">
          <VoucherCardSkeleton />
        </div>
      )}
      {activeError && <div>{t("myVouchersPage.errorLoadingActiveVouchers")}</div>}
      <VouchersList vouchers={activeVouchers?.vouchers ?? []} status={VoucherStatus.Active} />
      {activeVouchers?.vouchers.length === 0 && <div>{t("myVouchersPage.noCurrentVouchersInfo")}</div>}
      <h2 data-testid="inactive-vouchers-title" className="pt-4 text-xl font-bold text-primaryText lg:text-2xl">
        {t("myVouchersPage.previousVouchers")}
      </h2>
      {t("myVouchersPage.previousVouchersDescription")}
      {inactiveLoading && (
        <div className="py-2">
          <VoucherCardSkeleton />
        </div>
      )}
      {inactiveError && <div>{t("myVouchersPage.errorLoadingInactiveVouchers")}</div>}
      <VouchersList
        data-testid="inactive-voucher-list"
        vouchers={inactiveVouchers?.vouchers ?? []}
        status={VoucherStatus.Inactive}
      />
      {inactiveVouchers?.vouchers.length === 0 && <div>{t("myVouchersPage.noPreviousVouchersInfo")}</div>}
    </div>
  );
}

export { MyVouchersPage };
