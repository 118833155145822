import { StatusBadge } from "@/components";
import { useTranslation } from "react-i18next";

import { formatDateWithTime } from "@/lib/utils";

import { SpecialistDto, SpecialistServiceDto, VisitSpecialistDto } from "@/types/types";

import { Avatar, AvatarFallback, AvatarImage } from "../ui";

interface FormHeaderProps {
  specialist: SpecialistDto | VisitSpecialistDto;
  service: SpecialistServiceDto;
  slot: string;
}

const FormHeader: React.FC<FormHeaderProps> = ({ specialist, service, slot }) => {
  const { t } = useTranslation();

  return (
    <div data-testid="form-header" className="flex gap-3">
      <Avatar className="h-12 w-12 rounded lg:h-[72px] lg:w-[72px] lg:rounded-sm">
        <AvatarImage
          src={specialist?.listing_photo}
          alt={t("general.alt.photo")}
          className="h-12 w-12 object-cover lg:h-[72px] lg:w-[72px]"
        />
        <AvatarFallback>CN</AvatarFallback>
      </Avatar>
      <div className="text-sm font-normal leading-[21px] text-secondaryText lg:space-y-2 lg:text-base">
        <StatusBadge className="no-break hidden w-fit lg:inline" />
        <p className="text-sm font-semibold text-primaryText lg:text-lg">
          {specialist?.first_name} {specialist?.last_name}
        </p>
        <p>{specialist?.specialisations.map((specialisation) => specialisation.display_name).join(", ")}</p>
        <p>{formatDateWithTime(slot)}</p>
        <p>
          <span>{service?.name}, </span>
          <span>{service?.displayed_length}&nbsp;min</span>
        </p>
      </div>
    </div>
  );
};

export { FormHeader };
