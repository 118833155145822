import { Globe, Mail } from "lucide-react";
import { useTranslation } from "react-i18next";

import { BoxItalic } from "@/assets/images";

import { SpecialistDto } from "@/types/types";

const SpecialistAboutTab = ({ specialist }: { specialist: SpecialistDto }) => {
  const { t } = useTranslation();

  const languages = (specialist.languages || []).map((lang: string) => {
    return t(`general.languages.${lang}`);
  });

  return (
    <div className="flex w-full flex-col justify-start gap-5 self-stretch text-secondaryText">
      <div data-testid="about-title" className="text-lg font-semibold text-primaryText lg:text-2xl">
        {t("specialistProfilePage.about")}
      </div>

      <div
        data-testid="key-specialist-info"
        className="space-y-3 rounded-xl bg-white px-3 py-5 lg:border lg:border-veryLightGray lg:p-6"
      >
        <div className="flex flex-col space-y-3 lg:flex-row lg:items-center lg:gap-9 lg:space-y-0 xl:gap-28">
          <div className="flex flex-row items-start gap-2 sm:items-center">
            <Mail size={26} />
            <div className="flex flex-col sm:flex-row sm:gap-2">
              <span>{t("specialistProfilePage.email")}:</span> <span className="font-bold">{specialist.email}</span>
            </div>
          </div>
          <div className="flex flex-row items-start gap-2 sm:items-center">
            <img src={BoxItalic} alt={t("general.alt.languages")} className="inline" />
            <div className="flex flex-col sm:flex-row sm:gap-2">
              <span>{t("specialistProfilePage.pronouns")}:</span>
              <span className="font-bold">{specialist.pronouns}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start gap-2 sm:items-center">
          <Globe size={26} />

          <div className="flex flex-col sm:flex-row sm:gap-2">
            <span>{t("specialistProfilePage.consultationLanguages")}:</span>
            <span className="font-bold">{languages.join(", ")}</span>
          </div>
        </div>
      </div>

      {/* help areas */}
      {specialist.help_areas && (
        <div
          data-testid="help-areas"
          className="rounded-xl bg-white px-3 py-5 lg:space-y-3 lg:border lg:border-veryLightGray lg:p-6"
        >
          <p>
            <span className="font-bold lg:text-lg lg:font-semibold">{t("specialistProfilePage.helpAreas")}</span>
          </p>
          <p className="inner-html" dangerouslySetInnerHTML={{ __html: specialist.help_areas }} />
        </div>
      )}

      {/* professional info */}
      {specialist.professional_info && (
        <div
          data-testid="professional-info"
          className="rounded-xl bg-white px-3 py-5 lg:space-y-3 lg:border lg:border-veryLightGray lg:p-6"
        >
          <p>
            <span className="font-bold lg:text-lg lg:font-semibold">{t("specialistProfilePage.professionalInfo")}</span>
          </p>
          <p className="inner-html" dangerouslySetInnerHTML={{ __html: specialist.professional_info }} />
        </div>
      )}

      {/* specialisation areas */}
      {specialist.specialisation_areas && (
        <div
          data-testid="specialisation-areas"
          className="rounded-xl bg-white px-3 py-5 lg:space-y-3 lg:border lg:border-veryLightGray lg:p-6"
        >
          <p>
            <span className="font-bold lg:text-lg lg:font-semibold">
              {t("specialistProfilePage.specialisationAreas")}
            </span>
          </p>
          <p className="inner-html" dangerouslySetInnerHTML={{ __html: specialist.specialisation_areas }} />
        </div>
      )}

      {/* key skills */}
      {specialist.key_skills && (
        <div
          data-testid="key-skills"
          className="rounded-xl bg-white px-3 py-5 lg:space-y-3 lg:border lg:border-veryLightGray lg:p-6"
        >
          <p>
            <span className="font-bold lg:text-lg lg:font-semibold">{t("specialistProfilePage.keySkills")}</span>
          </p>
          <p className="inner-html" dangerouslySetInnerHTML={{ __html: specialist.key_skills }} />
        </div>
      )}

      {/* experience */}
      {specialist.experience && (
        <div
          data-testid="specialist-experience"
          className="rounded-xl bg-white px-3 py-5 lg:space-y-3 lg:border lg:border-veryLightGray lg:p-6"
        >
          <p>
            <span className="font-bold lg:text-lg lg:font-semibold">{t("specialistProfilePage.experience")}</span>
          </p>
          <p className="inner-html" dangerouslySetInnerHTML={{ __html: specialist.experience }} />
        </div>
      )}

      {/* education */}
      {specialist.education && (
        <div
          data-testid="specialist-education"
          className="rounded-xl bg-white px-3 py-5 lg:space-y-3 lg:border lg:border-veryLightGray lg:p-6"
        >
          <p>
            <span className="font-bold lg:text-lg lg:font-semibold">{t("specialistProfilePage.education")}</span>
          </p>
          <p className="inner-html" dangerouslySetInnerHTML={{ __html: specialist.education }} />
        </div>
      )}
    </div>
  );
};
export { SpecialistAboutTab };
