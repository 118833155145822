import React from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  FormHeader,
  LocalisedLink,
  localisedPath,
} from "@/components";
import routes from "@/constants/routes";
import { useGetPaymentQuery, useGetVisitQuery } from "@/store/apis";
import { ChevronLeft } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { CheckCircle, XCircle } from "@/assets/images";

import { Separator } from "@/components/ui";

import { PaymentStatus } from "@/types/enums";

const BookingConfirmation: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { visitId, referenceId } = useParams<{ visitId: string; referenceId: string }>();
  const {
    data: visit,
    isLoading,
    isError,
  } = useGetVisitQuery({
    id: Number(visitId),
    lang: i18n.language,
  });

  const { data: payment } = useGetPaymentQuery(referenceId ?? "");

  const isCompleted = payment?.status === PaymentStatus.Completed;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error occurred</div>;
  }

  const navigateToPayment = () => {
    const path = routes.paymentFromVisitsPage(
      visit?.specialist.id.toString(),
      visit?.service.id.toString(),
      visit?.start_time,
      visit?.id.toString()
    );
    navigate(localisedPath(path));
  };

  return (
    <div data-testid="booking-confirmation">
      <LocalisedLink
        to={routes.specialists}
        className="-ml-0.5 flex items-center py-3 text-sm font-normal text-primaryText hover:text-secondaryText lg:py-6"
      >
        <ChevronLeft strokeWidth={1.2} />
        {t("specialistProfilePage.backToExperts")}
      </LocalisedLink>
      <div className="flex flex-col items-center">
        <div className="w-full space-y-3 pb-6 pt-10 text-center sm:w-2/3 lg:rounded-lg lg:border lg:border-veryLightGray lg:bg-white lg:px-24">
          <div className="space-y-3 px-5">
            <div className="flex items-center justify-center" data-testid="confirmation-icon">
              {isCompleted ? <img data-testid="check-circle" src={CheckCircle} /> : <img src={XCircle} />}
            </div>
            <h2 className="text-base font-bold text-primaryText">
              {isCompleted ? t("booking.description") : t("booking.issueWithPayment")}
            </h2>
            <p className="text-sm font-normal text-secondaryText">{isCompleted && t("booking.notification")}</p>
          </div>
          {!isCompleted && (
            <>
              <Button variant="yellow" className="w-full p-2 text-sm font-bold" onClick={navigateToPayment}>
                {t("booking.tryAgain")}
              </Button>
              <div className="flex w-full flex-row items-center gap-2 text-secondaryText">
                <Separator className="h-px flex-1 bg-veryLightGray" />
                <div>{t("booking.or")}</div>
                <Separator className="h-px flex-1 bg-veryLightGray" />
              </div>
              <div className="flex justify-center font-bold">
                <Button className="text-sm">
                  <a href="mailto:admin@mhhelpline.com">{t("booking.contactAdministration")}</a>
                </Button>
              </div>
            </>
          )}
          {isCompleted && (
            <div className="flex justify-center font-bold">
              <Button data-testid="go-to-my-sessions-button" className="text-sm">
                <LocalisedLink to={routes.myVisits}>{t("booking.goToMySessions")}</LocalisedLink>
              </Button>
            </div>
          )}
        </div>

        <div className="w-full py-4 sm:w-2/3">
          <span className="text-base font-bold text-primaryText">
            {isCompleted ? t("booking.additionalInformation") : t("booking.troubleshooting")}
          </span>
          <Accordion type="single" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger className="text-start text-sm font-normal text-secondaryText">
                {t("booking.sessionDetails")}
              </AccordionTrigger>
              <AccordionContent>
                <div className="flex flex-wrap content-start items-start gap-[10px_8px] text-secondaryText">
                  <div className="flex flex-wrap content-start items-start gap-[10px_8px] text-secondaryText">
                    {visit && (
                      <FormHeader specialist={visit.specialist} service={visit.service} slot={visit.start_time} />
                    )}
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
              <AccordionTrigger className="text-sm font-normal text-secondaryText">
                {isCompleted ? t("booking.howToConnect") : t("booking.contactUs")}
              </AccordionTrigger>
              <AccordionContent>
                <div className="flex flex-wrap content-start items-start gap-[10px_8px] text-secondaryText">
                  Here should be some content
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
              <AccordionTrigger className="text-sm font-normal text-secondaryText">
                {isCompleted ? t("booking.paymentAndReturns") : t("booking.contactUs")}
              </AccordionTrigger>
              <AccordionContent>
                <div className="flex flex-wrap content-start items-start gap-[10px_8px] text-secondaryText">
                  Here should be some content
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export { BookingConfirmation };
