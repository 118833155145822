import { Dispatch, SetStateAction, useState } from "react";

import {
  Button,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components";
import { useTranslation } from "react-i18next";

import { ChevronDown, ThinXMark } from "@/assets/images";

import { cn } from "@/lib/utils";

import { SpecialistServiceDto } from "@/types/types";

type TherapyTypeDrawerProps = {
  setSelectedService: Dispatch<SetStateAction<SpecialistServiceDto>>;
  selectedService: SpecialistServiceDto;
  services: SpecialistServiceDto[];
};

const TherapyTypeDrawer: React.FC<TherapyTypeDrawerProps> = ({ services, setSelectedService, selectedService }) => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [serviceClicked, setServiceClicked] = useState<SpecialistServiceDto>(services[0] || null);

  const handleFilter = () => {
    setSelectedService(serviceClicked);
    setDrawerOpen(false);
  };

  const handleServiceClick = (service: SpecialistServiceDto) => {
    setServiceClicked(service);
  };

  const cancel = () => {
    if (services.length > 0) {
      setSelectedService(services[0]);
    }
    setDrawerOpen(false);
  };

  return (
    <Drawer onOpenChange={(open) => setDrawerOpen(open)} open={drawerOpen}>
      <DrawerTrigger asChild>
        <Button
          data-testid="therapy-type-select"
          className="inline-flex h-auto max-w-xs rounded-lg text-start font-medium"
        >
          <div className="flex gap-2">
            {selectedService.name}
            <img src={ChevronDown} />
          </div>
        </Button>
      </DrawerTrigger>
      <DrawerContent className="text-secondaryText">
        <div className="mx-auto w-full max-w-sm p-6">
          <DrawerHeader>
            <DrawerTitle>
              <div className="flex flex-row justify-between">
                <div className="mb-4 flex flex-row gap-3">
                  <DrawerClose asChild>
                    <img
                      src={ThinXMark}
                      alt={t("specialistsPage.filters.close")}
                      className="min-h-6 min-w-6 cursor-pointer"
                      onClick={cancel}
                    />
                  </DrawerClose>
                  <h3 className="text-lg font-normal text-secondaryText">{t("specialistProfilePage.therapyType")}</h3>
                </div>
              </div>
            </DrawerTitle>
          </DrawerHeader>

          <div className="flex flex-wrap content-start items-start gap-[10px_8px]">
            {services.map((service) => (
              <Button
                key={service.id}
                className={cn(" ", service.id === serviceClicked.id ? "bg-veryLightGray" : "")}
                onClick={() => handleServiceClick(service)}
              >
                {service.name}
              </Button>
            ))}
          </div>

          <DrawerFooter className="flex w-full gap-2">
            <DrawerClose asChild>
              <Button className="text-secondaryText" onClick={cancel}>
                {t("specialistsPage.filters.close")}
              </Button>
            </DrawerClose>
            <Button variant="yellow" className="grow py-2" onClick={handleFilter}>
              {t("specialistsPage.filters.showResults")}
            </Button>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export { TherapyTypeDrawer };
