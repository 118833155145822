import { Rating } from "@smastrom/react-rating";
import { useTranslation } from "react-i18next";

import { customStyles } from "@/assets/styles/rating";

import { SpecialistListDto } from "@/types/types";

type SpecialistCardIframeProps = {
  specialist: SpecialistListDto;
  onClick: () => void;
};

const SpecialistCardIframe = ({ specialist, onClick }: SpecialistCardIframeProps) => {
  const { t } = useTranslation();

  const reviews_count = specialist.reviews_count;

  return (
    <div
      onClick={onClick}
      className="flex h-full cursor-pointer flex-col items-center justify-between space-y-5 rounded-lg border-solid"
    >
      <img
        className="h-[200px] w-[200px] rounded-lg object-cover grayscale transition duration-300 hover:grayscale-0 xl:h-[252px] xl:w-[252px]"
        alt={t("general.alt.photo")}
        src={specialist.listing_photo}
      />
      <div className="text-center">
        <h3 className="text-xl font-semibold hover:text-gray-700">
          {specialist.first_name} {specialist.last_name}
        </h3>
        <p className="text-sm font-normal text-slate-500">
          {specialist.specialisations?.map((s) => s.display_name).join(", ")}
        </p>
      </div>
      <div className="flex w-full flex-row items-center justify-center gap-3">
        <Rating
          readOnly
          value={specialist.average_rating}
          className={"!w-fit [&>div]:aspect-square [&>div]:h-5 [&>div]:w-5"}
          itemStyles={customStyles}
        />
        <div className="text-sm font-bold text-slate-400">
          {reviews_count} {t("specialistsPage.rating", { count: reviews_count })}
        </div>
      </div>
    </div>
  );
};

export { SpecialistCardIframe };
