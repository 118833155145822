import { useEffect, useState } from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components";
import { useGetLanguagesQuery, useGetSpecialisationsQuery } from "@/store/apis";
import { selectSpecialisations, selectSpecialistsFilters, selectTranslatedAvailableLanguage } from "@/store/selectors";
import { initialSpecialistsFilterState, setFilters } from "@/store/slices";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Filters, ThinXMark } from "@/assets/images";

import { cn, toggleInArray } from "@/lib/utils";

const FiltersDrawer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [openItem, setOpenItem] = useState<string>("");
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { isLoading: isLoadingSpecialisations } = useGetSpecialisationsQuery({
    lang: i18n.language,
  });

  const { isLoading: isLoadingLanguages } = useGetLanguagesQuery();

  const selectedFilters = useSelector(selectSpecialistsFilters);

  const specialisations = useSelector(selectSpecialisations);

  const [filtersState, setFiltersState] = useState(selectedFilters);
  const languages = useSelector(selectTranslatedAvailableLanguage);

  function reset() {
    setFiltersState(initialSpecialistsFilterState);
  }

  function toggleLanguage(language: string) {
    const newFilters = { ...filtersState };
    newFilters.languages = toggleInArray(newFilters.languages, language);
    setFiltersState(newFilters);
  }

  function toggleSpecialisation(specialisationId: number) {
    const newFilters = { ...filtersState };
    newFilters.specialisations = toggleInArray(newFilters.specialisations, specialisationId);
    setFiltersState(newFilters);
  }

  function apply() {
    dispatch(setFilters(filtersState));
    setDrawerOpen(false);
  }

  function cancel() {
    setFiltersState(selectedFilters);
  }

  useEffect(() => {
    setFiltersState(selectedFilters);
  }, [selectedFilters]);

  return (
    <Drawer onClose={() => cancel()} onOpenChange={(change) => setDrawerOpen(change)} open={drawerOpen}>
      <DrawerTrigger asChild>
        <Button className="min-w-16 px-2">
          <div className="flex gap-2">
            {t("specialistsPage.filters.filters")}
            <img src={Filters} alt={t("specialistsPage.filters.filters")} />
          </div>
        </Button>
      </DrawerTrigger>
      <DrawerContent className="text-secondaryText">
        <div className="mx-auto w-full max-w-sm p-6">
          {isLoadingSpecialisations || isLoadingLanguages ? "Loading" : <></>}
          <DrawerHeader>
            <DrawerTitle>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-3">
                  <DrawerClose asChild>
                    <img
                      src={ThinXMark}
                      alt={t("specialistsPage.filters.close")}
                      className="min-h-6 min-w-6 cursor-pointer"
                    />
                  </DrawerClose>
                  <h3 className="text-secondaryText2 text-lg font-normal">{t("specialistsPage.filters.filters")}</h3>
                </div>
                <Button variant="gray" size="xs" onClick={() => reset()}>
                  {t("specialistsPage.filters.resetFilters")}
                </Button>
              </div>
            </DrawerTitle>
          </DrawerHeader>
          <Accordion type="single" collapsible value={openItem} onValueChange={setOpenItem}>
            <AccordionItem value="item-2">
              <AccordionTrigger className="text-secondaryText2 text-start text-sm font-normal">
                {t("specialistsPage.filters.specialistType")}
                {": "}
                {openItem !== "item-2" &&
                  filtersState.specialisations
                    .map(
                      (item) =>
                        specialisations.find((specialisation) => specialisation.id === item)?.universal_name || ""
                    )
                    .join(", ")}
              </AccordionTrigger>
              <AccordionContent>
                <div className="flex flex-wrap content-start items-start gap-[10px_8px]">
                  {specialisations.map((specialisation) => (
                    <Button
                      key={specialisation.id}
                      className={cn(
                        "  hover:bg-veryLightGray/50",
                        filtersState.specialisations.includes(specialisation.id)
                          ? "bg-veryLightGray hover:bg-veryLightGray"
                          : "bg-white"
                      )}
                      onClick={() => toggleSpecialisation(specialisation.id)}
                    >
                      {specialisation.universal_name}
                    </Button>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
              <AccordionTrigger className="text-sm font-normal text-secondaryText">
                {t("specialistsPage.filters.language")}
                {": "}
                {openItem !== "item-3" &&
                  filtersState.languages
                    .map((item) => languages.find((language) => language.id === item)?.name || "")
                    .join(", ")}
              </AccordionTrigger>
              <AccordionContent>
                <div className="flex flex-wrap content-start items-start gap-[10px_8px]">
                  {languages.map((language) => (
                    <Button
                      key={language.id}
                      className={cn(
                        "hover:bg-veryLightGray/50",
                        filtersState.languages.includes(language.id)
                          ? "bg-baackgroundApp hover:bg-veryLightGray"
                          : "bg-white"
                      )}
                      onClick={() => toggleLanguage(language.id)}
                    >
                      {language.name}
                    </Button>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
          <DrawerFooter className="flex w-full">
            <DrawerClose asChild>
              <Button className="text-secondaryText">{t("specialistsPage.filters.close")}</Button>
            </DrawerClose>
            <Button variant="yellow" className="grow" onClick={() => apply()}>
              {t("specialistsPage.filters.showResults")}
            </Button>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
export { FiltersDrawer };
