import { Footer, Header } from "@/components";
import { useTranslation } from "react-i18next";

import { Page404 } from "@/assets/images";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="p-6">
        <h1 className="py-6 text-center text-3xl">{t("page404.title")}</h1>
        <div className="flex justify-center">
          <img src={Page404} alt="404" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export { NotFoundPage };
