import { useEffect, useState } from "react";

import routes from "@/constants/routes";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";

import { Role } from "@/types/enums";

import { useAuth } from "./useAuth";

interface AuthGuardProps {
  children: React.ReactNode;
  allowedRoles?: Role[];
}

const AuthGuard: React.FC<AuthGuardProps> = ({ allowedRoles, children }) => {
  const { authState } = useOktaAuth();
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [canRenderChildren, setCanRenderChildren] = useState(false);

  const hasRequiredRole = (userRoles: Role[]) => {
    return !allowedRoles || userRoles.some((role: Role) => allowedRoles.includes(role));
  };

  useEffect(() => {
    if (!authState) {
      return;
    }
    if (!isAuthenticated()) {
      login();
    } else {
      if (!hasRequiredRole(authState?.idToken?.claims.roles as Role[])) {
        navigate(routes.root);
      } else {
        setCanRenderChildren(true);
      }
    }
  }, [login, isAuthenticated]);

  if (!authState || !isAuthenticated() || !canRenderChildren) {
    return null;
  }

  return <>{children}</>;
};

export { AuthGuard };
