import { Rating } from "@smastrom/react-rating";
import { useTranslation } from "react-i18next";

import { customStyles } from "@/assets/styles/rating";

type SpecialistProfileHeroProps = {
  reviews_count: number;
  average_rating: number;
  specialist_name: string;
  main_photo?: string;
  specialisations?: { display_name?: string }[];
};

const SpecialistProfileHero = ({
  reviews_count,
  average_rating,
  specialist_name,
  main_photo,
  specialisations,
}: SpecialistProfileHeroProps) => {
  const { t } = useTranslation();
  return (
    <div data-testid="specialist-profile-hero" className="w-full space-y-4 pb-3 sm:space-x-6 lg:space-x-0">
      <img
        className="relative h-[300px] w-full max-w-[300px] rounded-lg object-cover sm:inline-block lg:h-64 lg:w-64"
        src={main_photo}
      />
      <div className="space-y-2 self-stretch sm:inline-block sm:align-top lg:inline">
        <div className="text-lg font-semibold text-primaryText lg:pt-5">{specialist_name}</div>
        <div className="text-sm font-normal text-secondaryText">
          {specialisations?.map((specialisation) => specialisation.display_name).join(", ")}
        </div>
        <div className="flex w-full flex-row items-center gap-3">
          <Rating
            readOnly
            value={average_rating}
            className={"!w-fit [&>div]:aspect-square [&>div]:h-4 [&>div]:w-4 lg:[&>div]:h-5 lg:[&>div]:w-5"}
            itemStyles={customStyles}
          />
          <div className="font-bold text-secondaryText">
            {reviews_count} {t("specialistsPage.rating", { count: reviews_count })}
          </div>
        </div>
      </div>
    </div>
  );
};
export { SpecialistProfileHero };
