import { Skeleton } from "@/components/ui";

const SpecialistCardGridSkeleton = () => {
  return (
    <div className="space-y-7 rounded-lg border border-solid border-veryLightGray bg-white p-4">
      <div className="flex flex-shrink-0 justify-center">
        <Skeleton className="h-[200px] w-[200px] rounded-lg" />
      </div>
      <div className="flex grow flex-col gap-2">
        <Skeleton className="h-5 w-full" />
        <Skeleton className="h-7 w-full" />
        <Skeleton className="h-5 w-full pt-3" />
      </div>
    </div>
  );
};

export { SpecialistCardGridSkeleton };
