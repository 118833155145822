import React from "react";

import { availableLanguages, defaultLanguage } from "@/i18n";
import { ChevronDown } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarSeparator, MenubarTrigger } from "@/components/ui";

const LanguageSwitcher: React.FC = React.memo(() => {
  const { i18n } = useTranslation();

  const navigate = useNavigate();
  const { pathname, hash, search } = useLocation();

  const changeLanguage = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
    navigate(newPath(languageCode));
  };

  const newPath = (lang: string) => {
    const langRegex = new RegExp(`^/(${availableLanguages.join("|")})(/|$)`);
    const match = pathname.match(langRegex);
    lang = lang === defaultLanguage ? "" : `/${lang}`;

    if (match) {
      if (match[2] === "") {
        return `${lang}${search}${hash}`;
      } else {
        return `${pathname.replace(langRegex, `${lang}/`)}${search}${hash}`;
      }
    } else {
      return `${lang}${pathname}${search}${hash}`;
    }
  };

  return (
    <Menubar>
      <MenubarMenu>
        <MenubarTrigger className="border-none focus:bg-inherit data-[state=open]:bg-inherit">
          {i18n.language.toUpperCase()}
          <ChevronDown strokeWidth={1.5} className="ml-1" />
        </MenubarTrigger>
        <MenubarContent align="end" className="mr-0.5 lg:mr-5">
          <MenubarItem onClick={() => changeLanguage("pl")}>PL</MenubarItem>
          <MenubarSeparator />
          <MenubarItem onClick={() => changeLanguage("en")}>EN</MenubarItem>
          <MenubarSeparator />
          <MenubarItem onClick={() => changeLanguage("uk")}>UK</MenubarItem>
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  );
});

export { LanguageSwitcher };
