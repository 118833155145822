import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IAuthState } from "@/types/types";

const initialState: IAuthState = {
  user: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuthState: (_, action: PayloadAction<IAuthState>) => {
      return action.payload;
    },
  },
  selectors: {
    selectUser: (state) => state.user,
  },
});

export const { updateAuthState } = authSlice.actions;
export const { selectUser } = authSlice.selectors;
