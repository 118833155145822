import { useState } from "react";

import { useUpdateAuthState } from "@/auth";
import {
  Button,
  FormHeader,
  FormHeaderSkeleton,
  Separator,
  StepHeader,
  VoucherCard,
  localisedPath,
} from "@/components";
import routes from "@/constants/routes";
import i18n from "@/i18n";
import { useCreatePaymentMutation, useGetVisitQuery } from "@/store/apis";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ApiError, formatErrorMessage } from "@/lib/utils";

import { VoucherStatus } from "@/types/enums";

function PaymentFromVisitsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useUpdateAuthState();
  const { visitId } = useParams();
  const currentLanguage = i18n.language;
  const [apiError, setApiError] = useState<string | undefined>("");
  const [createPayment] = useCreatePaymentMutation();

  const { data: visit, isLoading } = useGetVisitQuery({ id: Number(visitId), lang: currentLanguage });

  const handlePaymentCreation = async (visitId: number) => {
    try {
      const response = await createPayment({
        payment: {
          visit_id: visitId,
          payment_method: "payu",
        },
      }).unwrap();
      window.location.href = response.redirect_url;
    } catch (error) {
      setApiError(formatErrorMessage(error as ApiError));
    }
  };

  const handleFormSubmit = async () => {
    if (!visitId) return;
    try {
      await handlePaymentCreation(Number(visitId));
    } catch (error) {
      setApiError(formatErrorMessage(error as ApiError));
    }
  };

  const backToVisitsList = () => {
    navigate(localisedPath(routes.myVisits));
  };

  return (
    <div className="flex min-h-screen flex-col bg-backgroundApp pb-3">
      <StepHeader fromSessionsList={true} />
      <div className="flex h-full flex-grow flex-col lg:mx-auto lg:mt-12 lg:w-4/5 lg:max-w-screen-xl lg:grow-0 lg:flex-row-reverse lg:gap-20">
        <div className="h-fit border-b border-veryLightGray bg-white px-6 py-4 lg:max-w-sm lg:basis-2/5 lg:rounded-lg lg:border lg:p-4">
          {isLoading && <FormHeaderSkeleton />}
          {visit && <FormHeader specialist={visit.specialist} service={visit.service} slot={visit.start_time} />}
        </div>
        <div className="mt-6 flex w-full flex-col gap-3 px-6 lg:mt-0 lg:basis-3/5">
          {visit?.voucher && (
            <>
              <h3 className="text-base font-bold text-primaryText lg:text-xl">
                {t("sessionScheduling.paymentForm.voucher")}
              </h3>
              <h3 className="text-sm text-secondaryText">{t("sessionScheduling.paymentForm.usedVoucher")}</h3>
              <VoucherCard
                key={visit?.voucher?.id}
                voucher={visit?.voucher}
                status={VoucherStatus.Active}
                selected={true}
              />
            </>
          )}
          <div className="mt-auto pt-4">
            <h3 className="text-base font-bold text-primaryText lg:text-xl">
              {t("sessionScheduling.paymentForm.summary")}
            </h3>
            <div className="flex justify-between pb-3 pt-2">
              <span className="text-primaryText">{t("sessionScheduling.paymentForm.sessionCost")}</span>
              <span className="text-primaryText">{visit?.initial_price}</span>
            </div>
            {visit?.voucher && visit?.voucher?.discount !== null && (
              <div className="flex justify-between pb-4 pt-2">
                <span className="text-primaryText">{t("sessionScheduling.paymentForm.voucherUsed")}</span>
                <span className="text-mossGreen">-{visit?.discount_value}</span>
              </div>
            )}
            <Separator className="mb-4 h-px bg-veryLightGray" />
            <div className="flex justify-between">
              <span className="text-primaryText">{t("sessionScheduling.paymentForm.VAT")}</span>
              <span className="text-primaryText">{visit?.price}</span>
            </div>
          </div>
          <div className="w-full space-y-3">
            {apiError && <p className=" text-redApp">{apiError}</p>}
            <div className="flex gap-2">
              <Button type="button" className="px-6 lg:text-base" onClick={backToVisitsList}>
                {t("sessionScheduling.back")}
              </Button>
              <Button variant="yellow" onClick={handleFormSubmit} className="w-full lg:w-52 lg:text-base" type="submit">
                {t("sessionScheduling.pay")}
              </Button>
            </div>
            <p className="text-center text-secondaryText lg:text-left">
              {/* # TODO: set proper links once we have them */}
              <Trans
                t={t}
                i18nKey="sessionScheduling.paymentForm.bookingInfo"
                components={{
                  TCLink: <Link className="font-medium underline hover:text-primaryText" to="/terms" />,
                  PrivacyLink: <Link className="font-medium underline hover:text-primaryText" to="/privacy" />,
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export { PaymentFromVisitsPage };
