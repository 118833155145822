import { RootState } from "@/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface VisitsState {
  pastVisitsPage: number;
  upcomingVisitsPage: number;
}

const initialState: VisitsState = {
  pastVisitsPage: 1,
  upcomingVisitsPage: 1,
};

export const visitsSlice = createSlice({
  name: "visits",
  initialState,
  reducers: {
    setPastVisitsPage: (state, action: PayloadAction<number>) => {
      state.pastVisitsPage = action.payload;
    },
    setUpcomingVisitsPage: (state, action: PayloadAction<number>) => {
      state.upcomingVisitsPage = action.payload;
    },
  },
});

export const { setPastVisitsPage, setUpcomingVisitsPage } = visitsSlice.actions;
export const selectPastVisitsPage = (state: RootState) => state.visits.pastVisitsPage;
export const selectUpcomingVisitsPage = (state: RootState) => state.visits.upcomingVisitsPage;

export default visitsSlice.reducer;
