import { Trash2 } from "lucide-react";
import { useTranslation } from "react-i18next";

import { ThinXMark, XCircle } from "@/assets/images";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
} from "@/components/ui";

type CancelSessionDrawerProps = {
  eligibleForRefund: boolean;
  handleVisitCancel: () => void;
  cancel: () => void;
  apiError: string | undefined;
  visitCancelled: boolean;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
};

const CancelSessionDialog: React.FC<CancelSessionDrawerProps> = ({
  eligibleForRefund,
  handleVisitCancel,
  cancel,
  apiError,
  visitCancelled,
  dialogOpen,
  setDialogOpen,
}) => {
  const { t } = useTranslation();

  return (
    <AlertDialog onOpenChange={(open) => setDialogOpen(open)} open={dialogOpen}>
      <AlertDialogTrigger asChild>
        <Button
          variant="link"
          className="m-auto block max-w-sm text-orange-800 underline hover:text-orange-950 lg:m-0 lg:text-base lg:font-medium"
        >
          {t("sessionButtons.cancelSession")}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="max-w-md text-secondaryText">
        <div className="mx-auto w-full p-6">
          <div className="flex flex-row justify-between md:justify-end">
            <AlertDialogHeader>
              <AlertDialogTitle>
                <AlertDialogCancel className="border-none p-0" asChild>
                  <img
                    src={ThinXMark}
                    alt={t("specialistsPage.filters.close")}
                    className="min-h-6 min-w-6 cursor-pointer"
                    onClick={cancel}
                  />
                </AlertDialogCancel>
              </AlertDialogTitle>
            </AlertDialogHeader>
          </div>
          {!visitCancelled && (
            <div className="space-y-3 px-6 text-center">
              <Trash2 color="#607D8B" size={36} strokeWidth={1} className="m-auto" />
              <h3 className="text-lg font-bold">{t("sessionDetails.cancelCofirmationTitle")}</h3>
              {eligibleForRefund ? (
                <AlertDialogDescription>{t("sessionDetails.cancelSessionInfo")}</AlertDialogDescription>
              ) : (
                <AlertDialogDescription>{t("sessionDetails.cancelSessionInfoNoRefund")} </AlertDialogDescription>
              )}
              {apiError && <div className=" text-redApp">{apiError}</div>}
            </div>
          )}
          {visitCancelled && (
            <div className="space-y-3 px-6 text-center">
              <img src={XCircle} className="m-auto" />
              <h3 className=" text-lg font-bold">{t("sessionDetails.cancelCofirmation")}</h3>
              {eligibleForRefund ? (
                <AlertDialogDescription>{t("sessionDetails.cancelCofirmationWithRefund")}</AlertDialogDescription>
              ) : (
                <AlertDialogDescription>{t("sessionDetails.cancelCofirmationWithoutRefund")}</AlertDialogDescription>
              )}
            </div>
          )}
          <AlertDialogFooter className="flex w-full gap-2 pt-5">
            <AlertDialogCancel asChild>
              <Button className="text-secondaryText" onClick={cancel}>
                {t("specialistsPage.filters.close")}
              </Button>
            </AlertDialogCancel>
            {!visitCancelled && (
              <Button variant="destructive" className="px-8" onClick={handleVisitCancel}>
                {t("sessionButtons.cancelSession")}
              </Button>
            )}
          </AlertDialogFooter>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { CancelSessionDialog };
