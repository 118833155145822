import { useEffect } from "react";

import { useTranslation } from "react-i18next";

const SetDocumentTitle = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("appTitle");
  }, [t]);

  return null;
};

export { SetDocumentTitle };
