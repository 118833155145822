import { SpecialistCardGridSkeleton, SpecialistCardIframe } from "@/components";
import { useGetSpecialistsQuery } from "@/store";
import { selectFilteredSpecialists } from "@/store/selectors";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SpecialistsListIframe = () => {
  const { i18n } = useTranslation();
  const { isLoading } = useGetSpecialistsQuery({
    lang: i18n.language,
  });

  const specialists = useSelector(selectFilteredSpecialists);

  const handleCardClick = (slug: string) => {
    if (window.top) {
      window.top.location.href = `https://demo.mhhelpline.com/eksperci/${slug}`;
    }
  };

  return (
    <div className="m-4 grid w-full grid-cols-1 gap-1 gap-x-1 gap-y-8 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-5">
      {isLoading && (
        <>
          {Array.from({ length: 8 }).map((_, index) => (
            <SpecialistCardGridSkeleton key={index} />
          ))}
        </>
      )}
      {specialists?.map((specialist) => (
        <SpecialistCardIframe
          specialist={specialist}
          key={specialist.id}
          onClick={() => handleCardClick(specialist.slug)}
        />
      ))}
    </div>
  );
};

export { SpecialistsListIframe };
