import { SpecialistsListHero, SpecialistsListIframe } from "@/components";

function SpecialistsIframePage() {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 pt-7 lg:gap-7">
      <SpecialistsListHero className="hidden" />
      <SpecialistsListIframe />
    </div>
  );
}

export { SpecialistsIframePage };
