import React, { createContext, useContext, useMemo } from "react";

import { localisedPath } from "@/components";
import routes from "@/constants/routes";
import { useGetSpecialistQuery } from "@/store";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";
import { NavigateFunction } from "react-router-dom";

import { SpecialistDto } from "@/types/types";

import { FormHeader } from "./FormHeader";
import { FormHeaderSkeleton } from "./FormHeaderSkeleton";
import { StepHeader } from "./StepHeader";

const getRoutePath = (step: string, specialistId?: string, serviceId?: string, slot?: string, visitId?: string) => {
  switch (step) {
    case "step1":
      return routes.scheduleSessionDetails(specialistId, serviceId, slot, visitId);
    case "step2":
      return routes.schedulePatientData(specialistId, serviceId, slot, visitId);
    case "step3":
      return routes.schedulePaymentDetails(specialistId, serviceId, slot, visitId);
    default:
      return routes.scheduleSessionDetails(specialistId, serviceId, slot, "");
  }
};

const clearFormStorage = () => {
  Object.keys(localStorage)
    .filter((key) => key.startsWith("sessionBooking"))
    .forEach((key) => localStorage.removeItem(key));
};

const handleBackClick = (
  currentStep: string,
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>,
  navigate: NavigateFunction,
  specialistId?: string,
  serviceId?: string,
  slot?: string,
  visitId?: string
) => {
  const steps = ["step1", "step2", "step3"];
  const currentStepIndex = steps.indexOf(currentStep);
  if (currentStepIndex === 0) {
    clearFormStorage();
    navigate(localisedPath(routes.specialists));
  } else if (currentStepIndex > 0) {
    const previousStep = steps[currentStepIndex - 1];
    setCurrentStep(previousStep);
    const path = getRoutePath(previousStep, specialistId, serviceId, slot, visitId);
    navigate(localisedPath(path));
  }
};

type StepsContextT = {
  currentStep: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
};

const Steps = createContext<StepsContextT>({
  currentStep: "session",
  setCurrentStep: () => {},
});

export const useSteps = () => {
  const context = useContext(Steps);
  if (!context) {
    throw new Error("useSteps must be used within a StepsProvider");
  }
  return context;
};

type SessionSchedulingProps = {
  defaultStep?: string;
};

type SessionContextT = {
  specialist?: SpecialistDto;
  isLoading: boolean;
  isError: boolean;
  serviceId?: string;
  slot?: string;
  visitId?: number;
  setVisitId?: (id: number) => void;
};

const SessionContext = createContext<SessionContextT>({
  isLoading: false,
  isError: false,
});

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error("useSpecialist must be used within a SpecialistProvider");
  }
  return context;
};

const SessionScheduling: React.FC<SessionSchedulingProps> = ({ defaultStep = "step1" }) => {
  const { i18n } = useTranslation();
  const [currentStep, setCurrentStep] = React.useState(defaultStep);
  const { specialistId, serviceId, slot } = useParams<{ specialistId: string; serviceId: string; slot: string }>();
  const currentLanguage = i18n.language;
  const [visitId, setVisitId] = React.useState<number>();

  const {
    data: specialist,
    isLoading,
    isError,
  } = useGetSpecialistQuery({
    id: Number(specialistId),
    lang: currentLanguage,
  });
  const selectedService = useMemo(() => {
    return specialist?.services.find((service) => service.id === Number(serviceId));
  }, [specialist, serviceId]);

  if (isError) {
    return <div>Error occurred</div>;
  }

  return (
    <Steps.Provider value={{ currentStep, setCurrentStep }}>
      <SessionContext.Provider value={{ specialist, isLoading, serviceId, slot, isError, visitId, setVisitId }}>
        <div className="flex min-h-screen flex-col bg-backgroundApp pb-3">
          <StepHeader />
          <div className="flex h-full flex-grow flex-col lg:mx-auto lg:mt-12 lg:w-4/5 lg:max-w-screen-xl lg:grow-0 lg:flex-row-reverse lg:gap-20">
            <div className="h-fit border-b border-veryLightGray bg-white px-6 py-4 lg:max-w-sm lg:basis-2/5 lg:rounded-lg lg:border lg:p-4">
              {isLoading && <FormHeaderSkeleton />}
              {specialist && slot && selectedService && (
                <FormHeader specialist={specialist} service={selectedService} slot={slot} />
              )}
            </div>
            <div className="mt-6 flex w-full flex-grow px-6 lg:mt-0 lg:basis-3/5">
              <Outlet />
            </div>
          </div>
        </div>
      </SessionContext.Provider>
    </Steps.Provider>
  );
};

export { SessionScheduling };
export { handleBackClick };
export { clearFormStorage };
