import { Dispatch, SetStateAction, useState } from "react";

import { localisedPath, useSession } from "@/components";
import routes from "@/constants/routes";
import { useValidatePhoneNumberMutation } from "@/store/apis";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { isDesktop } from "@/lib/mediaQuery";
import { ApiError, formatErrorMessage } from "@/lib/utils";

import { PhoneConfirmationDialog } from "./PhoneConfirmationDialog";
import { PhoneConfirmationDrawer } from "./PhoneConfirmationDrawer";

type PhoneConfirmationProps = {
  isOpen: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  phone_number: string;
};

const PhoneConfirmation = ({ isOpen, onOpenChange, phone_number }: PhoneConfirmationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [validationError, setValidationError] = useState<string | null>(null);
  const { specialist, serviceId, slot, visitId } = useSession();
  const sixDigitNumberSchema = z.object({
    code: z.string().regex(/^\d{6}$/, t("phoneConfirmation.validationError")),
  });
  const [validatePhoneNumber] = useValidatePhoneNumberMutation();
  const [apiError, setApiError] = useState<string | undefined>("");

  const cancel = () => {
    onOpenChange(false);
  };

  const validateInput = () => {
    const validationResult = sixDigitNumberSchema.safeParse({ code });
    if (validationResult.success) {
      setValidationError(null);
      return true;
    } else {
      setValidationError(validationResult.error.errors[0].message);
      return false;
    }
  };

  const handleSubmit = async () => {
    const isValid = validateInput();
    if (!isValid || !specialist) return;
    const response = await validatePhoneNumber({ code: code, phone_number: phone_number });
    if (response.error) {
      setApiError(formatErrorMessage(response.error as ApiError));
      return;
    }
    const nextRoute = routes.schedulePaymentDetails(
      specialist.id.toString(),
      serviceId?.toString(),
      slot?.toString(),
      visitId?.toString()
    );
    navigate(localisedPath(nextRoute));
  };

  const formProps = {
    isOpen,
    onOpenChange,
    cancel,
    handleSubmit,
    apiError,
    setValidationError,
    validationError,
    setCode,
    code,
  };

  if (isDesktop()) {
    return <PhoneConfirmationDialog {...formProps} />;
  }
  return <PhoneConfirmationDrawer {...formProps} />;
};

export { PhoneConfirmation };
