import React from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Email, Facebook, FooterBg, Linkedin, Phone } from "@/assets/images";

import { Button } from "@/components/ui";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div data-testid="footer" style={{ backgroundImage: `url(${FooterBg})` }} className="w-full bg-cover pb-10 pt-28">
      <div
        className="mx-6 grid gap-2 leading-relaxed sm:m-auto sm:w-4/5"
        style={{ gridTemplateColumns: "repeat( auto-fit, minmax(150px, 1fr) )" }}
      >
        <div>
          <p className="text-sm font-semibold">{t("footer.howDoesItWork")}</p>
          <p>
            <Link className="hover:text-primaryApp" to="https://mhhelpline.com/#jak-pomagamy">
              {t("footer.individualSupport")}
            </Link>
          </p>
          <p>
            <Link className="hover:text-primaryApp" to="https://mhhelpline.com/oferta-dla-firm/">
              {t("footer.offerForCompanies")}
            </Link>
          </p>
          <p className="wrap-words">
            <Link className="hover:text-primaryApp" to="https://mhhelpline.com/zespol/#zakres-wsparcia">
              {t("footer.psychologicalServices")}
            </Link>
          </p>
          <p>
            <Link className="hover:text-primaryApp" to="https://mhhelpline.com/#technologia">
              {t("footer.app")}
            </Link>
          </p>
        </div>
        <div>
          <p className="text-sm font-semibold">{t("footer.ourApproach")}</p>
          <p>
            <Link className="hover:text-primaryApp" to="https://mhhelpline.com/#technologia">
              {t("footer.technologySupport")}
            </Link>
          </p>
          <p>
            <Link className="hover:text-primaryApp" to="https://mhhelpline.com/#krok-po-kroku">
              {t("footer.stepByStep")}
            </Link>
          </p>
          <p>
            <Link className="hover:text-primaryApp" to="https://mhhelpline.com/zespol/">
              {t("footer.ourExperts")}
            </Link>
          </p>
        </div>
        <div>
          <p className="text-sm font-semibold">{t("footer.additionalSupport")}</p>
          <p>
            <Link className="hover:text-primaryApp" to="https://mhhelpline.com/baza-wiedzy/#artykuly">
              {t("footer.publications")}
            </Link>
          </p>
          <p>
            <Link className="hover:text-primaryApp" to="https://mhhelpline.com/baza-wiedzy/#webinary">
              {t("footer.webinars")}
            </Link>
          </p>
          <p>
            <Link className="hover:text-primaryApp" to="https://mhhelpline.com/baza-wiedzy/">
              {t("footer.blog")}
            </Link>
          </p>
        </div>
        <div>
          <p className="text-sm font-semibold">{t("footer.mentalHealthHelpline")}</p>
          <p>
            <Link className="hover:text-primaryApp" to="https://mhhelpline.com/o-nas/">
              {t("footer.about")}
            </Link>
          </p>
          <p>
            <Link className="hover:text-primaryApp" to="https://mhhelpline.com/o-nas/#zarzad">
              {t("footer.management")}
            </Link>
          </p>
          <p>
            <Link className="hover:text-primaryApp" to="https://www.diversityhub.pl/" target="_blank" rel="noopener">
              {t("footer.diversityHub")}
            </Link>
          </p>
          <p>
            <Link className="hover:text-primaryApp" to="http://mhcenter.pl/" target="_blank" rel="noopener">
              {t("footer.mentalHealthCenter")}
            </Link>
          </p>
          <p>
            <Link className="hover:text-primaryApp" to="#kontakt">
              {t("footer.contact")}
            </Link>
          </p>
        </div>
      </div>
      <div className="mb-4 mt-10 flex justify-center gap-2">
        <Button
          onClick={() => {
            throw new Error("Sentry Test Error");
          }}
          className="h-[33px] rounded-none bg-veryLightGray px-1 text-primaryText hover:bg-veryLightGray/55 hover:text-primaryApp"
        >
          Sentry
        </Button>
        <Link
          title={t("general.alt.facebook")}
          aria-label={t("general.alt.facebook")}
          target="_blank"
          rel="noopener noreferrer"
          to="https://www.facebook.com/mentalhealthhelplinepl"
        >
          <img src={Facebook} alt={t("general.alt.facebook")} />
        </Link>
        <Link
          title={t("general.alt.linkedin")}
          aria-label={t("general.alt.linkedin")}
          target="_blank"
          rel="noopener noreferrer"
          to="https://www.linkedin.com/company/mental-health-help-line/"
        >
          <img src={Linkedin} alt={t("general.alt.linkedin")} />
        </Link>
        <Link
          title={t("general.alt.email")}
          aria-label={t("general.alt.email")}
          target="_blank"
          rel="noopener noreferrer"
          to="mailto:service@mhhelpline.com"
        >
          <img src={Email} alt={t("general.alt.email")} className="h-8 w-8" />
        </Link>
        <Link
          title={t("general.alt.phone")}
          aria-label={t("general.alt.phone")}
          target="_self"
          rel="noopener noreferrer"
          to="tel:+48 881 555 604"
        >
          <img src={Phone} alt={t("general.alt.phone")} className="h-8 w-8" />
        </Link>
      </div>
      <div className="mx-6 space-y-2">
        <div className="flex flex-wrap justify-center gap-x-6 gap-y-2">
          <Link
            className="text-nowrap hover:text-primaryApp"
            to="https://mhhelpline.com/wp-content/uploads/2021/09/Regulamin-platformy_MHHL.pdf"
            target="_blank"
            rel="noopener"
          >
            {t("footer.termsOfService")}
          </Link>
          <Link
            className=" text-nowrap hover:text-primaryApp"
            to="https://mhhelpline.com/wp-content/uploads/2021/09/Polityka_Prywatnosci_MHHL.pdf"
          >
            {t("footer.privacyAndCookiePolicy")}
          </Link>
          <Link to="#" className="text-nowrap hover:text-primaryApp">
            {t("footer.accessibilityWCAG")}
          </Link>
        </div>
        <div className="text-center">
          <p>{t("footer.allRightsReserved")}</p>
        </div>
      </div>
    </div>
  );
};
export { Footer };
