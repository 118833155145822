import * as React from "react";
import { useState } from "react";
import { cn } from "@/lib/utils";
import { XMark } from "@/assets/images";
import { useTranslation } from "react-i18next";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onClear?: () => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, onClear, ...props }, ref) => {
    const [value, setValue] = useState("");
    const { t } = useTranslation();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      if (props.onChange) {
        props.onChange(e);
      }
    };

    const handleClear = () => {
      setValue("");
      if (onClear) {
        onClear();
      }
    };

    return (
      <div className="relative">
        <input
          type={type}
          value={value}
          onChange={handleChange}
          className={cn(
            "flex h-10 w-full rounded-md border border-veryLightGray bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground placeholder:text-secondaryText focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            className,
          )}
          ref={ref}
          {...props}
        />
        {value && (
          <img
            src={XMark}
            onClick={handleClear}
            className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer"
            alt={t("buttons.close")}
          />
        )}
      </div>
    );
  },
);

Input.displayName = "Input";

export { Input };
