import React, { useState } from "react";

import { CancelSession, RatingDrawer, StatusBadge, localisedPath } from "@/components";
import routes from "@/constants/routes";
import { Clock4, MessageSquare, NotebookPen, Phone, Video } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useGetVisitMeetingQuery } from "@/store/apis/visitApi";

import { Wallet } from "@/assets/images";

import { Avatar, AvatarFallback, AvatarImage, Button, Separator } from "@/components/ui";

import { formatDateWithTime } from "@/lib/utils";

import { CommunicationMode, SessionStatus } from "@/types/enums";
import { SpecialistSpecialisationDto, VisitDto } from "@/types/types";

type SessionDetailsProps = {
  session: VisitDto;
};

const SessionDetails: React.FC<SessionDetailsProps> = ({ session }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sessionStatus, setSessionStatus] = useState<string>(session.status || "");
  const { data: meetingData } = useGetVisitMeetingQuery({ id: session.id });

  const canBeReviewed = session.reviewed;

  const shouldPayForSession = () => {
    return sessionStatus === SessionStatus.Booked;
  };
  const canStartSession = () => {
    return sessionStatus === SessionStatus.Confirmed;
  };

  const canCancelSession = () => {
    if (sessionStatus === SessionStatus.Cancelled.toString()) {
      return false;
    }
    if (sessionStatus === SessionStatus.Started.toString()) {
      return false;
    }
    if (sessionStatus === SessionStatus.Booked) {
      return true;
    }
    if (sessionStatus === SessionStatus.Confirmed) {
      return true;
    }
  };

  const canReviewSession = () => {
    return sessionStatus === SessionStatus.Finished && canBeReviewed;
  };

  const canJoinSession = () => {
    return sessionStatus === SessionStatus.Started;
  };

  const handleStartSessionClick = () => {
    if (meetingData?.user_meeting_url) {
      window.location.href = meetingData?.user_meeting_url;
    }
  };

  const specialist = session.specialist;
  const service = session.service;

  const navigateToPayment = () => {
    const path = routes.paymentFromVisitsPage(
      session.specialist.id.toString(),
      session.service.id.toString(),
      session.start_time,
      session.id.toString()
    );
    navigate(localisedPath(path));
  };

  return (
    <div className="space-y-4 lg:space-y-5 lg:rounded-lg lg:border lg:bg-white lg:p-[30px]">
      <div className="flex justify-between lg:justify-start lg:gap-[30px] lg:pb-4">
        <h1 className="text-lg font-semibold leading-snug text-primaryText lg:text-2xl lg:font-medium">
          {t("sessionDetails.sessionDetails")}
        </h1>
        <StatusBadge
          status={sessionStatus as SessionStatus}
          communicationMode={session.communication_mode[0]}
          className="lg:text-sm"
        />
      </div>
      <div className="flex flex-row gap-2 lg:items-center lg:gap-6 lg:space-x-12">
        <Avatar className="m-2 h-6 min-h-6 w-6 min-w-6 rounded lg:m-0 lg:h-[120px] lg:w-[120px] lg:rounded-lg">
          <AvatarImage
            src={specialist.listing_photo}
            alt={t("general.alt.photo")}
            className="h-6 w-6 object-cover lg:h-[120px] lg:w-[120px]"
          />
          <AvatarFallback>CN</AvatarFallback>
        </Avatar>
        <div>
          <p className="font-semibold text-primaryText lg:text-2xl lg:font-bold">
            {specialist.first_name} {specialist?.last_name}
          </p>
          <p className="lg:text-base">
            {specialist.specialisations
              .map((specialisation: SpecialistSpecialisationDto) => specialisation.display_name)
              .join(", ")}
          </p>
        </div>
      </div>
      <Separator />
      <div className="flex">
        <div className="w-12 min-w-12 px-2">
          <Clock4 className="h-5 w-5 lg:h-6 lg:w-6" strokeWidth={1.2} />
        </div>
        <div>
          <p className="font-semibold text-primaryText lg:text-base lg:font-bold">
            {formatDateWithTime(session.start_time)}
          </p>
          <p className="lg:text-base">
            {service.name}, {service.displayed_length} min
          </p>
        </div>
      </div>
      <Separator />
      <div className="flex">
        <div className="w-12 min-w-12 px-2">
          <img src={Wallet} className="h-5 w-5 lg:h-6 lg:w-6" />
        </div>
        <div>
          <p className="font-semibold text-neutral-800 lg:text-base lg:font-bold">
            {t("sessionDetails.payment")}- {service.price}
          </p>
          <p className="lg:text-base">{t("sessionDetails.paidVia")}</p>
        </div>
      </div>
      <Separator />
      <div className="flex">
        <div className="w-12 min-w-12 px-2">
          {session.communication_mode[0] === CommunicationMode.Phone && (
            <Phone className="h-5 w-5 lg:h-6 lg:w-6" strokeWidth={1.2} />
          )}
          {session.communication_mode[0] === CommunicationMode.Chat && (
            <MessageSquare className="h-5 w-5 lg:h-6 lg:w-6" strokeWidth={1.2} />
          )}
          {session.communication_mode[0] === CommunicationMode.Video && (
            <Video className="h-5 w-5 lg:h-6 lg:w-6" strokeWidth={1.2} />
          )}
        </div>
        <div>
          <p className="font-semibold text-neutral-800 lg:text-base lg:font-bold">
            {t("sessionScheduling.communicationChannel")}
          </p>
          <p className="lg:text-base">{session.communication_mode[1]}</p>
        </div>
      </div>
      {session.additional_info && (
        <>
          <Separator />
          <div className="flex">
            <div className="w-12 min-w-12 px-2">
              <NotebookPen className="h-5 w-5" strokeWidth={1.2} />
            </div>
            <div>
              <p className="font-semibold text-neutral-800 lg:text-base lg:font-bold">
                {t("sessionDetails.additionalInfo")}
              </p>
              <p className="lg:text-base">{session.additional_info}</p>
            </div>
          </div>
          <Separator />
        </>
      )}
      {(canStartSession() || canJoinSession()) && (
        <div className="w-full lg:text-base">
          <div className="flex flex-col gap-6 rounded-lg border bg-white p-6 lg:flex-row lg:items-start lg:justify-between lg:bg-backgroundApp lg:px-12">
            <div className="flex flex-col gap-3 lg:flex-row lg:gap-6">
              <h3 className="text-base font-medium text-primaryText lg:font-medium">
                {t("sessionDetails.phoneVisit")}
              </h3>
              <div className="space-y-4 text-nowrap">
                {meetingData?.phone_numbers.map((phoneNumber, index) => <div key={index}>{phoneNumber}</div>)}
              </div>
            </div>
            <div className="flex flex-col gap-3 lg:flex-row">
              <h3 className="text-base font-medium text-secondaryText lg:font-medium">
                {t("sessionDetails.verificationCode")}
              </h3>
              <div>{meetingData?.phone_code}</div>
            </div>
          </div>
        </div>
      )}
      <div className="w-full space-y-4 text-center lg:flex lg:flex-row-reverse lg:items-center lg:justify-between lg:space-y-0 lg:pt-6">
        {shouldPayForSession() && (
          // TODO: decide on the flow when user still has to pay for the session
          <Button
            variant="yellow"
            size="lg"
            className="m-auto block w-full max-w-sm text-sm lg:m-0 lg:w-auto lg:text-base lg:font-medium"
            onClick={navigateToPayment}
          >
            {t("sessionButtons.payForSession")}
          </Button>
        )}
        {canStartSession() && (
          <div className="space-y-2">
            <Button
              variant="yellow"
              size="lg"
              className="m-auto block w-full max-w-sm text-sm lg:text-base lg:font-medium"
              onClick={handleStartSessionClick}
            >
              {t("sessionButtons.startSession")}
            </Button>
          </div>
        )}
        {canJoinSession() && (
          <div className="space-y-2">
            <Button
              variant="yellow"
              size="lg"
              className="m-auto block w-full max-w-sm text-sm lg:text-base lg:font-medium"
              onClick={handleStartSessionClick}
            >
              {t("sessionButtons.joinSession")}
            </Button>
          </div>
        )}
        {canCancelSession() && (
          <CancelSession
            setSessionStatus={setSessionStatus}
            eligibleForRefund={session.policies.eligible_for_refund_on_cancel}
          />
        )}
      </div>
      {canReviewSession() && (
        <div className="flex w-full flex-col justify-center space-y-4 text-center lg:items-center lg:space-y-0">
          <RatingDrawer
            specialist={specialist}
            triggerButtonClassName="m-auto w-full max-w-sm text-sm rounded-md h-10 px-8 lg:text-base lg:font-medium lg:m-auto"
          />
        </div>
      )}
    </div>
  );
};

export { SessionDetails };
