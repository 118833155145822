import { useEffect } from "react";

import { useUpdateAuthState } from "@/auth";
import { localisedPath } from "@/components";
import routes from "@/constants/routes";
import { useGetPaymentQuery } from "@/store/apis";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { PaymentStatus } from "@/types/enums";

function PaymentLoadingPage() {
  useUpdateAuthState();
  const navigate = useNavigate();
  const { referenceId: reference_id, visitId } = useParams();
  const referenceId = reference_id ?? "";
  const { data: payment, refetch } = useGetPaymentQuery(referenceId, { skip: !referenceId });
  const { t } = useTranslation();

  useEffect(() => {
    if (referenceId) {
      refetch();
      const interval = setInterval(() => {
        refetch();
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [refetch, referenceId]);

  useEffect(() => {
    if (payment) {
      const paymentStatus = payment.status;
      if (paymentStatus === PaymentStatus.Completed || paymentStatus === PaymentStatus.Cancelled) {
        navigate(localisedPath(routes.bookingConfirmation(visitId, referenceId)));
      }
    }
  }, [payment, navigate, visitId]);

  return (
    <div className="flex min-h-screen w-full flex-col items-center justify-center gap-2 bg-backgroundApp text-center">
      <div className="flex space-x-2 py-2">
        <div className="h-2.5 w-2.5 animate-dot-flashing rounded-full bg-secondaryText"></div>
        <div className="h-2.5 w-2.5 animate-dot-flashing rounded-full bg-secondaryText delay-200"></div>
        <div className="delay-400 h-2.5 w-2.5 animate-dot-flashing rounded-full bg-secondaryText"></div>
      </div>
      <h3 className="text-base font-bold text-primaryText">{t("paymentLoading.processingPayment")}</h3>
      <p className="max-w-60 text-secondaryText">{t("paymentLoading.description")}</p>
    </div>
  );
}

export { PaymentLoadingPage };
