import { useEffect } from "react";

import { updateAuthState } from "@/store/slices";
import { useOktaAuth } from "@okta/okta-react";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";

import { Role } from "@/types/enums";

const useUpdateAuthState = () => {
  const { authState } = useOktaAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authState?.isAuthenticated) {
      dispatch(
        updateAuthState({
          user: {
            id: authState.idToken?.claims.user_id as number,
            email: authState.idToken?.claims.email as string,
            first_name: authState.idToken?.claims.first_name as string,
            last_name: authState.idToken?.claims.last_name as string,
            email_confirmed: authState.idToken?.claims.email_confirmed as boolean,
            roles: authState.idToken?.claims.roles as Role[],
            patient_id: authState.idToken?.claims.patient_id as number | null,
            specialist_id: authState.idToken?.claims.specialist_id as number | null,
          },
        })
      );
      Sentry.addBreadcrumb({
        category: "auth",
        message: `Authenticated user ${authState.accessToken?.claims.user_id}`,
        level: "info",
      });
    } else {
      dispatch(updateAuthState({}));
      Sentry.addBreadcrumb({
        category: "auth",
        message: `User logged out`,
        level: "info",
      });
    }
    Sentry.setUser(
      authState
        ? {
            id: authState.accessToken?.claims.user_id as number,
            roles: authState.accessToken?.claims.roles as string[],
            email_confirmed: authState.accessToken?.claims.email_confirmed as boolean,
          }
        : null
    );
  }, [authState, dispatch]);
};

export { useUpdateAuthState };
