import React from "react";

import { Clock4 } from "lucide-react";
import { useTranslation } from "react-i18next";

import { Wallet } from "@/assets/images";

import { Separator, Skeleton } from "@/components/ui";

const SessionDetailsSkeleton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-lg font-semibold leading-snug text-primaryText">{t("sessionDetails.sessionDetails")}</h1>
      </div>
      <div className="flex ">
        <div className="w-12 min-w-12 px-2">
          <Skeleton className="h-6 w-6 rounded-full" />
        </div>
        <div className="space-y-2">
          <Skeleton className="h-4 w-24" />
          <Skeleton className="h-4 w-40" />
        </div>
      </div>
      <Separator />
      <div className="flex">
        <div className="w-12 min-w-12 px-2">
          <Clock4 className="h-5 w-5" strokeWidth={1.2} />
        </div>
        <div className="space-y-2">
          <Skeleton className="h-4 w-24" />
          <Skeleton className="h-4 w-40" />
        </div>
      </div>
      <Separator />
      <div className="flex">
        <div className="w-12 min-w-12 px-2">
          <img src={Wallet} className="h-5 w-5" />
        </div>
        <div className="space-y-2">
          <Skeleton className="h-4 w-24" />
          <Skeleton className="h-4 w-40" />
        </div>
      </div>
      <Separator />
      <div>
        <p className="text-base font-medium text-primaryText">
          {t("sessionDetails.additionalInfo")} <span className="font-normal">({t("sessionDetails.optional")})</span>
        </p>
        <div className="mt-2 rounded-lg border border-veryLightGray bg-backgroundApp p-3 text-secondaryText">
          <Skeleton className="h-4 w-24 lg:h-5" />
        </div>
      </div>
    </>
  );
};

export { SessionDetailsSkeleton };
