import { mhhlApi as api } from "./mhhlApi";

type CreatePaymentApiArg = {
  payment: {
    visit_id: number;
    payment_method: string;
  };
};

type CreatePaymentApiResponse = {
  reference_id: string;
  status: string;
  payment_method: string;
  amount: {
    cents: number;
    currency_iso: string;
  };
  redirect_url: string;
};

type GetPaymentApiResponse = CreatePaymentApiResponse;

const paymentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    createPayment: build.mutation<CreatePaymentApiResponse, CreatePaymentApiArg>({
      query: (payment) => ({
        url: "/api/v1/payments",
        method: "POST",
        body: payment,
      }),
    }),
    getPayment: build.query<GetPaymentApiResponse, string>({
      query: (reference_id) => ({
        url: `/api/v1/payments/${reference_id}/`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export { paymentsApi };
export const { useCreatePaymentMutation, useGetPaymentQuery } = paymentsApi;
