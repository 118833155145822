import i18n from "@/i18n";
import { specialisationsApi } from "@/store/apis";
import { createSelector } from "@reduxjs/toolkit";

const selectSpecialisationsResponse = createSelector([(state) => state], (state) => {
  return specialisationsApi.endpoints.getSpecialisations.select({
    lang: i18n.language,
  })(state);
});

const selectSpecialisations = createSelector(selectSpecialisationsResponse, (specialisationsResult) => {
  return specialisationsResult.data?.specialisations ?? [];
});

export { selectSpecialisationsResponse, selectSpecialisations };
