import { useEffect, useState } from "react";

import { Button } from "@/components";

import { formatTimeInTimeZone } from "@/lib/utils";

import { SlotDto } from "@/types/types";

type SlotButtonProps = {
  slot: SlotDto;
  active: boolean;
  onSlotClick?: (slot: SlotDto) => void;
};

const SlotButton = ({ slot, active, onSlotClick = () => {} }: SlotButtonProps) => {
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const toggleSlot = () => {
    setIsActive(!isActive);
    onSlotClick(slot);
  };

  const buttonVariant = () => {
    return isActive ? "yellow" : "gray";
  };

  return (
    <Button
      data-testid="available-slot"
      variant={buttonVariant()}
      size="xs"
      key={slot.start_time}
      onClick={toggleSlot}
      className="hover:bg-primaryApp hover:text-white lg:text-sm lg:font-medium"
    >
      {formatTimeInTimeZone(slot.start_time)}
    </Button>
  );
};

export { SlotButton };
