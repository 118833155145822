import { OktaAuth } from "@okta/okta-auth-js";

const oktaAuth = new OktaAuth({
  issuer: import.meta.env.VITE_MHHL_API_ENDPOINT + "oauth",
  clientId: import.meta.env.VITE_OAUTH_CLIENT_ID,
  redirectUri: import.meta.env.VITE_OAUTH_REDIRECT_URI,
  authorizeUrl: import.meta.env.VITE_OAUTH + "oauth/authorize",
  tokenUrl: import.meta.env.VITE_OAUTH + "oauth/token",
  revokeUrl: import.meta.env.VITE_OAUTH + "oauth/revoke",
  logoutUrl: import.meta.env.VITE_OAUTH + "oauth/logout",
  devMode: import.meta.env.DEV,
  scopes: ["read", "write"],
});

export { oktaAuth };
