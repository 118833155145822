import { Skeleton } from "../ui";

const FormHeaderSkeleton: React.FC = () => {
  return (
    <div className="flex gap-3">
      <Skeleton className="h-12 w-12 rounded-full lg:h-[72px] lg:w-[72px]" />
      <div className="space-y-2 lg:space-y-3">
        <Skeleton className="h-4 w-16 lg:h-6" />
        <Skeleton className="h-4 w-44 lg:h-6" />
        <Skeleton className="h-4 w-40 lg:h-6" />
        <Skeleton className="h-4 w-36 lg:h-6" />
      </div>
    </div>
  );
};

export { FormHeaderSkeleton };
