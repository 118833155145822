import { FiltersBar } from "./FiltersBar";
import { FiltersSelect } from "./FiltersSelect";
import { Search } from "./Search";
import { SortingSelect } from "./SortingSelect";
import { ViewSelectDesktop } from "./ViewSelectDesktop";

type QueryBarDesktopProps = {
  className?: string;
};

const QueryBarDesktop: React.FC<QueryBarDesktopProps> = ({ className }) => {
  return (
    <>
      <div className="flex items-center gap-2">
        <SortingSelect />
        <FiltersSelect />
        <Search />
        <div className={className}>
          <ViewSelectDesktop />
        </div>
      </div>
      <FiltersBar />
    </>
  );
};

export { QueryBarDesktop };
