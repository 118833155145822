import i18n from "@/i18n.ts";
import { availableLanguagesApi } from "@/store/apis";
import { createSelector } from "@reduxjs/toolkit";

const selectAvailableLanguagesResponse = availableLanguagesApi.endpoints.getLanguages.select();

export const selectAvailableLanguages = createSelector(
  selectAvailableLanguagesResponse,
  (response) => response.data?.languages ?? []
);

export const selectTranslatedAvailableLanguage = createSelector([selectAvailableLanguages], (availableLanguages) =>
  availableLanguages.map((lang) => ({
    id: lang,
    name: i18n.t(`general.languages.${lang}`),
  }))
);
