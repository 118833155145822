import { useEffect } from "react";

import { SessionCardSkeleton, SessionsList } from "@/components";
import { useAppDispatch } from "@/hooks";
import { useLazyGetPastVisitsQuery, useLazyGetUpcomingVisitsQuery, visitsApi } from "@/store/apis";
import {
  selectPastVisitsPage,
  selectUpcomingVisitsPage,
  setPastVisitsPage,
  setUpcomingVisitsPage,
} from "@/store/slices";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ApiError, formatErrorMessage } from "@/lib/utils";

function MySessionsPage() {
  const { t, i18n } = useTranslation();
  const pastVisitsPage = useSelector(selectPastVisitsPage);
  const upcomingVisitsPage = useSelector(selectUpcomingVisitsPage);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      visitsApi.util.updateQueryData("getPastVisits", { page: pastVisitsPage, lang: i18n.language }, () => {
        return { visits: [], next_page: undefined };
      })
    );
    dispatch(
      visitsApi.util.updateQueryData("getUpcomingVisits", { page: upcomingVisitsPage, lang: i18n.language }, () => {
        return { visits: [], next_page: undefined };
      })
    );
    fetchPastVisits({ page: undefined, lang: i18n.language });
    fetchUpcomingVisits({ page: undefined, lang: i18n.language });
    setPastVisitsPage(1);
    setUpcomingVisitsPage(1);
  }, [i18n.language]);

  const [
    fetchPastVisits,
    {
      data: pastVisitsData = { visits: [], next_page: null },
      error: pastVisitsError,
      isFetching: pastVisitsFetching,
      isLoading: pastVisitsLoading,
      isSuccess: pastVisitsSuccess,
    },
  ] = useLazyGetPastVisitsQuery();

  const [
    fetchUpcomingVisits,
    {
      data: upcomingVisitsData = { visits: [], next_page: null },
      error: upcomingVisitsError,
      isFetching: upcomingVisitsFetching,
      isLoading: upcomingVisitsLoading,
      isSuccess: upcomingVisitsSuccess,
    },
  ] = useLazyGetUpcomingVisitsQuery();

  const handleNextUpcomingVisitsPage = () => {
    if (upcomingVisitsData?.next_page != null) {
      fetchUpcomingVisits({ page: upcomingVisitsData.next_page, lang: i18n.language });
      setUpcomingVisitsPage(upcomingVisitsData.next_page);
    }
  };
  const handleNextPastVisitsPage = () => {
    if (pastVisitsData?.next_page != null) {
      fetchPastVisits({ page: pastVisitsData.next_page, lang: i18n.language });
      setPastVisitsPage(pastVisitsData.next_page);
    }
  };

  const upcomingLoaded = !upcomingVisitsFetching && !upcomingVisitsLoading && upcomingVisitsSuccess;
  const upcomingLoading = !upcomingLoaded && !upcomingVisitsError;
  const pastLoaded = !pastVisitsFetching && !pastVisitsLoading && pastVisitsSuccess;
  const pastLoading = !pastLoaded && !pastVisitsError;

  return (
    <div className="w-full flex-none items-center justify-center pt-2 [&>*]:mt-2">
      <h2 className="pt-4 text-xl font-bold text-primaryText lg:text-2xl">{t("mySessionsPage.upcomingSessions")}</h2>

      {upcomingVisitsError && formatErrorMessage(upcomingVisitsError as ApiError)}
      {upcomingLoaded && upcomingVisitsData.visits.length === 0 && (
        <div>{t("mySessionsPage.noUpcomingSessionsInfo")}</div>
      )}
      <SessionsList visits={upcomingVisitsData.visits} order="asc" />
      {upcomingLoading && <SessionCardSkeleton />}
      {upcomingVisitsData.next_page && (
        <div
          onClick={handleNextUpcomingVisitsPage}
          className="cursor-pointer text-center font-bold text-secondaryText underline lg:text-left"
        >
          {t("mySessionsPage.loadMore")}
        </div>
      )}
      <h2 className="pt-4 text-xl font-bold text-primaryText lg:text-2xl">{t("mySessionsPage.history")}</h2>
      {pastVisitsError && formatErrorMessage(pastVisitsError as ApiError)}
      {pastLoaded && pastVisitsData.visits.length === 0 && <div>{t("mySessionsPage.noPastSessionsInfo")}</div>}
      <SessionsList visits={pastVisitsData.visits} order="desc" />
      {pastLoading && <SessionCardSkeleton />}
      {pastVisitsData.next_page && (
        <div
          onClick={handleNextPastVisitsPage}
          className="cursor-pointer text-center font-bold text-secondaryText underline lg:text-left"
        >
          {t("mySessionsPage.loadMore")}
        </div>
      )}
    </div>
  );
}

export { MySessionsPage };
