import { SpecialistsList } from "@/components";
import { SpecialistsListHero } from "@/components";

function SpecialistsListPage() {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 pt-7 lg:gap-7">
      <SpecialistsListHero />
      <SpecialistsList />
    </div>
  );
}

export { SpecialistsListPage };
