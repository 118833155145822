import { mhhlApi as api } from "./mhhlApi";

interface CreateReviewRequest {
  review: {
    author_id: number;
    specialist_id: number;
    rating: number;
    content: string;
  };
}

interface CreateReviewResponse {
  id: number;
  author_id: number;
  specialist_id: number;
  rating: number;
  content: string;
}

const ratingApi = api.injectEndpoints({
  endpoints: (build) => ({
    createReview: build.mutation<CreateReviewResponse, CreateReviewRequest>({
      query: (reviewData) => ({
        url: `/api/v1/reviews`,
        method: "POST",
        body: reviewData,
      }),
    }),
  }),
});

export const { useCreateReviewMutation } = ratingApi;
