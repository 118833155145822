import { Separator, Skeleton } from "@/components/ui";

const SpecialistCardListSkeleton = () => {
  return (
    <div className="hidden gap-7 rounded-lg border border-solid border-veryLightGray bg-white p-4 lg:flex">
      <Skeleton className="h-[200px] w-[200px] rounded-lg" />
      <div className="flex grow gap-7">
        <div className="flex basis-2/3 flex-col gap-1 py-2">
          <Skeleton className="h-6 w-24" />
          <Skeleton className="h-5 w-32" />
          <Skeleton className="h-5 w-60" />
          <Skeleton className="h-5 w-40" />
          <Skeleton className="mt-2 h-5 w-60" />
          <div className="flex items-center gap-4">
            <Skeleton className="ml-3 h-2 w-2" />
            <Skeleton className="h-5 w-60" />
          </div>
          <div className="flex items-center gap-4">
            <Skeleton className="ml-3 h-2 w-2" />
            <Skeleton className="h-5 w-60" />
          </div>
          <div className="mt-2 flex gap-2">
            <Skeleton className="h-8 w-1/3" />
            <Skeleton className="h-8 w-1/3" />
          </div>
        </div>
        <Separator orientation="vertical" className="h-auto bg-veryLightGray" />
        <div className="grow-2 flex basis-1/3 flex-col justify-start gap-4 py-2">
          <Skeleton className="mx-auto h-4 w-24" />
          <div className="mx-auto flex gap-4">
            <Skeleton className="h-6 w-11" />
            <Skeleton className="h-6 w-11" />
            <Skeleton className="h-6 w-11" />
          </div>
          <div className="mx-auto flex gap-4">
            <Skeleton className="h-6 w-11" />
            <Skeleton className="h-6 w-11" />
            <Skeleton className="h-6 w-11" />
          </div>
          <div className="mx-auto flex gap-4">
            <Skeleton className="h-6 w-11" />
          </div>
          <Skeleton className="mx-auto mt-auto h-4 w-32" />
        </div>
      </div>
    </div>
  );
};

export { SpecialistCardListSkeleton };
