import i18n from "@/i18n";
import { type ClassValue, clsx } from "clsx";
import { DateTime, Info, StringUnitLength } from "luxon";
import { twMerge } from "tailwind-merge";

import { SlotDto, VisitDto } from "@/types/types";

function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

function toggleInArray<T>(array: T[], item: T): T[] {
  const index = array.indexOf(item);
  if (index === -1) {
    return [...array, item];
  }

  return [...array.slice(0, index), ...array.slice(index + 1)];
}

type SlotsByDate = {
  [date: string]: SlotDto[];
};

function groupSlotsByDate(slots: SlotDto[]): SlotsByDate {
  return Array.isArray(slots)
    ? slots.reduce((slotsByDate: SlotsByDate, slot: SlotDto) => {
        const formattedDate = DateTime.fromISO(slot.start_time).toISODate() || "";
        if (!slotsByDate[formattedDate]) {
          slotsByDate[formattedDate] = [];
        }
        slotsByDate[formattedDate].push(slot);
        return slotsByDate;
      }, {})
    : {};
}

function formatDayInTimeZone(dateStr: string): string {
  const date = DateTime.fromISO(dateStr, { zone: "utc" }).setZone("local");
  return date.toFormat("yyyy-MM-dd");
}

function formatTimeInTimeZone(dateStr: string): string {
  const date = DateTime.fromISO(dateStr, { zone: "utc" }).setZone("local");
  return date.toFormat("HH:mm");
}

function formatDate(dayStr: string) {
  const day = DateTime.fromFormat(dayStr, "yyyy-MM-dd");
  return day.toFormat("dd.MM");
}

function formatYearDateFromISO(dayStr: string) {
  const day = DateTime.fromISO(dayStr);
  return day.toFormat("dd.MM.yyyy");
}

function formatDateWithTime(dayStr: SlotDto["start_time"]) {
  return `${formatYearDateFromISO(dayStr)}, ${formatTimeInTimeZone(dayStr)}`;
}

function formatDayOfWeek(slots: SlotDto[], format: string = "short") {
  if (slots.length === 0) return "";
  const date = DateTime.fromISO(slots[0].start_time);
  const dayOfWeek = Info.weekdays(format as StringUnitLength, { locale: i18n.language })[date.weekday - 1];
  return dayOfWeek;
}

interface GroupedVisits {
  [month: string]: VisitDto[];
}

function parseDate(dateString: string): DateTime {
  const parsedDate = DateTime.fromISO(dateString);
  if (!parsedDate.isValid) {
    throw new Error(`Invalid DateTime: ${dateString}`);
  }
  return parsedDate;
}

function sortGroupedVisits(groupedVisits: GroupedVisits, order: "asc" | "desc"): void {
  Object.keys(groupedVisits).forEach((month) => {
    groupedVisits[month].sort((a, b) => {
      const dateA = parseDate(a.start_time).toMillis();
      const dateB = parseDate(b.start_time).toMillis();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    });
  });
}

function sortMonths(groupedVisits: GroupedVisits, order: "asc" | "desc"): GroupedVisits {
  const sortedGroupedVisits: GroupedVisits = {};
  Object.keys(groupedVisits)
    .sort((a, b) => {
      const dateA = parseDate(a).toMillis();
      const dateB = parseDate(b).toMillis();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    })
    .forEach((month) => {
      sortedGroupedVisits[month] = groupedVisits[month];
    });
  return sortedGroupedVisits;
}

function groupVisitsByMonth(visits: VisitDto[], order: "asc" | "desc"): GroupedVisits {
  const groupedVisits = visits.reduce((acc: GroupedVisits, visit: VisitDto) => {
    try {
      const parsedDate = parseDate(visit.start_time);
      const month = parsedDate.toFormat("yyyy-MM");

      if (!acc[month]) {
        acc[month] = [];
      }

      acc[month].push(visit);
    } catch (error) {
      console.error("Error parsing date:", error);
    }

    return acc;
  }, {});

  sortGroupedVisits(groupedVisits, order);

  return sortMonths(groupedVisits, order);
}

export interface ApiError {
  status: number;
  data: {
    message?: string;
  };
}

const formatErrorMessage = (error: ApiError) => {
  const { status, data } = error;
  if (status >= 400 && status < 500) {
    return data.message ? data.message : i18n.t("sessionScheduling.errorOccurred");
  } else if (status >= 500) {
    return i18n.t("sessionScheduling.errorOccurred");
  }
};

export {
  cn,
  formatDate,
  formatDayInTimeZone,
  formatDayOfWeek,
  formatTimeInTimeZone,
  formatYearDateFromISO,
  formatDateWithTime,
  groupSlotsByDate,
  toggleInArray,
  groupVisitsByMonth,
  formatErrorMessage,
};
