import { Dispatch, SetStateAction } from "react";

import { useTranslation } from "react-i18next";

import { ThinXMark } from "@/assets/images";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Input,
} from "@/components/ui";

type PhoneConfirmationDrawerProps = {
  isOpen: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  cancel: () => void;
  handleSubmit: () => void;
  apiError: string | undefined;
  setValidationError: Dispatch<SetStateAction<string | null>>;
  validationError: string | null;
  setCode: Dispatch<SetStateAction<string>>;
  code: string;
};

const PhoneConfirmationDialog = ({
  isOpen,
  onOpenChange,
  cancel,
  handleSubmit,
  apiError,
  setValidationError,
  validationError,
  setCode,
  code,
}: PhoneConfirmationDrawerProps) => {
  const { t } = useTranslation();

  return (
    <AlertDialog open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialogContent className="text-secondaryText">
        <div className="mx-auto w-full p-6">
          <AlertDialogHeader>
            <div className="flex w-full justify-end">
              <AlertDialogCancel asChild className="border-none p-0">
                <img src={ThinXMark} alt="close" className="min-h-6 min-w-6 cursor-pointer" />
              </AlertDialogCancel>
            </div>
            <AlertDialogTitle>
              <h3 className="text-lg font-normal text-gray-600">{t("phoneConfirmation.confirmPhoneNumber")}</h3>
            </AlertDialogTitle>
          </AlertDialogHeader>
          <Input
            value={code}
            onChange={(e) => {
              const newValue = e.target.value;
              if (!/^\d*$/.test(newValue) || newValue.length > 6) {
                setValidationError(t("phoneConfirmation.validationError"));
              } else {
                setValidationError(null);
                setCode(newValue);
              }
            }}
          ></Input>
          {validationError && <div className="text-redApp">{validationError}</div>}
          {apiError && <div className="text-redApp">{apiError}</div>}
          <div className="py-3 text-secondaryText">{t("phoneConfirmation.validFor")}</div>
          <AlertDialogFooter className="flex w-full gap-2">
            <AlertDialogCancel asChild>
              <Button className="text-secondaryText" onClick={cancel}>
                {t("phoneConfirmation.cancel")}
              </Button>
            </AlertDialogCancel>
            <Button variant="yellow" className="px-8" onClick={handleSubmit}>
              {t("phoneConfirmation.continue")}
            </Button>
          </AlertDialogFooter>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { PhoneConfirmationDialog };
