import { Separator, Skeleton } from "@/components/ui";

const VoucherCardSkeleton = () => {
  return (
    <div className="mx-auto flex max-w-sm flex-col gap-2 rounded-lg border border-veryLightGray bg-white p-4 pt-5 sm:max-w-md md:max-w-full md:gap-4">
      <div className="flex flex-row items-center justify-between gap-12 border-b p-0 pb-2">
        <div className="flex flex-row gap-2">
          <Skeleton className="mr-10 hidden h-4 w-14 md:block" />
          <Skeleton className="h-4 w-20" />
          <Separator orientation="vertical" className="h-5 bg-slate-200" />
          <Skeleton className="h-4 w-10" />
        </div>
        <div className="flex flex-row gap-3">
          <Skeleton className="h-4 w-14 md:hidden" />
          <Skeleton className="h-4 w-2 md:h-8 md:w-8 md:rounded-full lg:h-8" />
        </div>
      </div>
      <div className="max-w-60 space-y-2 md:max-w-full">
        <Skeleton className="h-3 w-32 md:h-4" />
        <Skeleton className="h-3 w-44 md:h-4" />
        <Skeleton className="h-3 w-24 md:h-4" />
      </div>
    </div>
  );
};
export { VoucherCardSkeleton };
