import { useTranslation } from "react-i18next";

import { SpecialistListDto } from "@/types/types";

type SpecialistCardProps = {
  specialist: SpecialistListDto;
  onClick: () => void;
};

const SpecialistCardDesktopGrid = ({ specialist, onClick }: SpecialistCardProps) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="specialist-card-desktop-grid"
      onClick={onClick}
      className="flex h-full cursor-pointer flex-col items-center justify-between space-y-5 rounded-lg border-solid"
    >
      <img
        className="h-[200px] w-[200px] rounded-lg object-cover grayscale transition duration-300 hover:grayscale-0 xl:h-[252px] xl:w-[252px]"
        alt={t("general.alt.photo")}
        src={specialist.listing_photo}
      />
      <div className="text-center">
        <h3 className="text-xl font-semibold">
          {specialist.first_name} {specialist.last_name}
        </h3>
        <p className="text-sm font-normal text-secondaryText">
          {specialist.specialisations?.map((s) => s.display_name).join(", ")}
        </p>
      </div>
      <div className="flex w-full flex-row items-center justify-center gap-3"></div>
    </div>
  );
};

export { SpecialistCardDesktopGrid };
