import { useEffect } from "react";

import routes from "@/constants/routes";
import { availableLanguages, defaultLanguage } from "@/i18n";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const LanguageHandler: React.FC = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const langSegment = location.pathname.split("/")[1];
    const lang = availableLanguages.includes(langSegment) ? langSegment : defaultLanguage;

    if (location.pathname === routes.authCallback) {
      return;
    }

    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }

    if (lang === defaultLanguage) {
      const newPath = location.pathname.replace(`/${defaultLanguage}`, "");
      navigate(newPath, { replace: true });
    }
  }, [location.pathname, i18n, navigate]);

  return null;
};

export { LanguageHandler };
