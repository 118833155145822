import { oktaAuth } from "@/auth/auth";
import i18n from "@/i18n";
import { toRelativeUrl } from "@okta/okta-auth-js";

export const authLogin = async () => {
  try {
    const originalUri = toRelativeUrl(window.location.href, window.location.origin);
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.options.authorizeUrl = `${import.meta.env.VITE_OAUTH}/${i18n.language}/oauth/authorize`;

    await oktaAuth.signInWithRedirect();
  } catch (error) {
    console.error("Login failed", error);
    throw error;
  }
};
