import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Skeleton } from "@/components/ui";

import { cn } from "@/lib/utils";

import { SpecialistServiceDto } from "@/types/types";

type SelectedServiceProps = {
  selectedService: SpecialistServiceDto | null;
};

const SelectedService: React.FC<SelectedServiceProps> = ({ selectedService }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="max-w-md rounded-lg border border-veryLightGray bg-white p-4 text-sm lg:max-w-full lg:text-base">
      {!selectedService && (
        <div className="space-y-2">
          <Skeleton className="h-4 w-20 lg:h-5" />
          <Skeleton className="h-4 w-32 lg:h-5" />
          <Skeleton className="h-4 w-full lg:h-5" />
          <Skeleton className="h-4 w-full lg:hidden" />
        </div>
      )}
      {selectedService && (
        <>
          <div className="mb-2 flex items-center justify-between lg:mb-0 lg:flex-col lg:items-start lg:gap-1">
            <span className="font-bold text-primaryText">{selectedService?.name}</span>
            <div className="text-secondaryText">
              {selectedService?.displayed_length} min • {selectedService?.price}
            </div>
          </div>
          {selectedService.description != null && (
            <div className={cn("flex flex-col items-start", isExpanded ? "" : "lg:flex-row lg:gap-1")}>
              <p className={`mb-1 text-secondaryText ${isExpanded ? "" : "line-clamp-2 lg:line-clamp-1 lg:max-w-sm"}`}>
                {selectedService?.description}
              </p>

              <button
                type="button"
                onClick={() => setIsExpanded(!isExpanded)}
                className={cn(
                  "text-primarsyText text-nowrap font-normal underline hover:text-secondaryText",
                  isExpanded ? "" : "lg:block"
                )}
              >
                {isExpanded ? `${t(`sessionScheduling.readLess`)}` : `${t(`sessionScheduling.readMore`)}`}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export { SelectedService };
