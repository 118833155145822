import { ReviewCard } from "@/components";
import { useTranslation } from "react-i18next";

import { SpecialistReviewDto } from "@/types/types";

const SpecialistReviewsTab = ({
  reviews,
  reviews_count,
}: {
  reviews: SpecialistReviewDto[];
  reviews_count: number;
}) => {
  const { t } = useTranslation();
  return (
    <div className="w-full justify-start space-y-5 self-stretch text-secondaryText">
      <h2 data-testid="reviews-number" className="text-lg font-semibold text-primaryText lg:text-2xl">
        {" "}
        {reviews_count} {t("specialistsPage.review", { count: reviews_count })}
      </h2>
      {reviews.map((review) => (
        <ReviewCard key={review.id} {...review} />
      ))}
    </div>
  );
};
export { SpecialistReviewsTab };
