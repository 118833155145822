const routes = {
  authCallback: "/auth/callback",
  bookingConfirmation: (visitId = ":visitId", referenceId = ":referenceId") =>
    `/:lang?/booking-confirmation/${visitId}/${referenceId}`,
  myVisits: "/:lang?/my-visits",
  root: "/:lang?",
  schedulePatientData: (
    specialistId = ":specialistId",
    serviceId = ":serviceId",
    slot = ":slot",
    visitId = ":visitId"
  ) => `/:lang?/schedule/patient-data/${specialistId}/${serviceId}/${slot}/${visitId}`,
  schedulePaymentDetails: (
    specialistId = ":specialistId",
    serviceId = ":serviceId",
    slot = ":slot",
    visitId = ":visitId"
  ) => `/:lang?/schedule/payment-details/${specialistId}/${serviceId}/${slot}/${visitId}`,
  scheduleSessionDetails: (
    specialistId = ":specialistId",
    serviceId = ":serviceId",
    slot = ":slot",
    visitId = ":visitId?"
  ) => {
    return `/:lang?/schedule/session-details/${specialistId}/${serviceId}/${slot}/${visitId}`;
  },
  sessionDetails: (visitId = ":visitId") => `/:lang?/my-visits/${visitId}`,
  specialistDetails: (slug = ":slug", tab = "") => `/:lang?/specialists/${slug}${tab ? "#" + tab : ""}`,
  specialistPreview: (id = ":id", tab = "") => `/:lang?/specialist_preview/${id}${tab ? "#" + tab : ""}`,
  specialists: "/:lang?/specialists",
  payment: (referenceId = ":referenceId", visitId = ":visitId") => `/:lang?/payment/${visitId}/${referenceId}`,
  myVouchers: "/:lang?/my-vouchers",
  paymentFromVisitsPage: (
    specialistId = ":specialistId",
    serviceId = ":serviceId",
    slot = ":slot",
    visitId = ":visitId"
  ) => `/:lang?/payment-details/${specialistId}/${serviceId}/${slot}/${visitId}`,
  specialistsListIframe: "/:lang?/specialists/iframe/",
  specialistIframe: (slug = ":slug", tab = "") => `/:lang?/specialists/${slug}/iframe/${tab ? "#" + tab : ""}`,
  login: "/:lang?/login",
};

export default routes;
