import { useCallback, useEffect, useMemo, useState } from "react";

import { SlotButton, StatusBadge, localisedPath } from "@/components";
import routes from "@/constants/routes";
import { useGetSpecialistTimeSlotsQuery } from "@/store/apis";
import { Separator } from "@radix-ui/react-menubar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui";

import { isDesktop } from "@/lib/mediaQuery";
import { formatDate, formatDateWithTime, formatDayOfWeek, groupSlotsByDate } from "@/lib/utils";

import { SlotDto, SpecialistDto, SpecialistServiceDto } from "@/types/types";

import { TherapyTypeDrawer } from "./TherapyTypeDrawer";

const chunkArray = <T,>(array: T[], chunkSize: number): T[][] => {
  return array.reduce((resultArray: T[][], item: T, index: number) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
};

const SpecialistCalendarTab = ({ specialist, iframe }: { specialist: SpecialistDto; iframe?: boolean }) => {
  const { t, i18n } = useTranslation();
  const [selectedService, setSelectedService] = useState<SpecialistServiceDto>(specialist.services[0] || null);
  const [activeSlot, setActiveSlot] = useState<SlotDto | null>(null);
  const navigate = useNavigate();
  const { data: timeSlots } = useGetSpecialistTimeSlotsQuery({
    id: Number(specialist.id),
    serviceId: selectedService.id,
  });
  const desktop = isDesktop();
  const slotsByDay = useMemo(() => groupSlotsByDate(timeSlots?.time_slots || []), [timeSlots]);

  const chunks = chunkArray(Object.entries(slotsByDay), 6);

  useEffect(() => {
    setActiveSlot(null);
  }, [selectedService]);

  useEffect(() => {
    if (specialist.services.length > 0) {
      setSelectedService(specialist.services[0]);
    }
  }, [i18n.language, specialist.services]);

  const onSlotClick = useCallback((slot: SlotDto) => {
    setActiveSlot((prevSlot) => (prevSlot === slot ? null : slot));
  }, []);

  const isActiveSlot = (slot: SlotDto) => {
    return activeSlot === slot;
  };

  const onServiceChange = useCallback(
    (service: string) => {
      const selected = specialist.services.find((s) => s.id === Number(service));
      if (selected) {
        setSelectedService(selected);
      }
    },
    [specialist.services, setSelectedService]
  );

  const onScheduleSessionClick = () => {
    const specialistId = specialist.id.toString();
    const serviceId = selectedService.id.toString();
    const slot = activeSlot?.start_time ?? "";

    if (iframe) {
      const url = localisedPath(routes.scheduleSessionDetails(specialistId, serviceId, slot as "slot", ""));
      window.open(url, "_blank");
    } else {
      navigate(localisedPath(routes.scheduleSessionDetails(specialistId, serviceId, slot as "slot", "")));
    }
  };

  const handleClose = () => {
    setActiveSlot(null);
  };

  return (
    <>
      <div className="mb-[18px] flex justify-between lg:justify-normal lg:gap-7">
        <h2 data-testid="calendar-tab-title" className="text-lg font-semibold text-primaryText lg:text-2xl">
          {t("specialistProfilePage.makeAppointment")}
        </h2>
        <StatusBadge className="lg:text-sm" />
      </div>
      <div className="mb-3 text-sm font-normal leading-[21px] text-secondaryText lg:text-base">
        {t("specialistProfilePage.makeAppointment")}
        <br /> {t("specialistProfilePage.pay")}
        <br /> {t("specialistProfilePage.cancel")}
      </div>
      <Separator className="my-6 hidden h-px bg-veryLightGray lg:block" />
      {(!timeSlots || timeSlots.time_slots.length == 0) && (
        <div className="text-secondaryText lg:text-base">{t("specialistsPage.specialistCard.noAvailableSessions")}</div>
      )}
      {Array.isArray(timeSlots?.time_slots) && timeSlots.time_slots.length > 0 && (
        <>
          <div className="lg:flex lg:flex-row lg:items-center lg:justify-between lg:pb-6">
            <div className="hidden text-secondaryText lg:block lg:text-base ">
              {t("specialistProfilePage.availableDates")}
            </div>
            <div>
              <span className="hidden text-base font-medium text-secondaryText lg:inline-block lg:pr-4">
                {t("specialistProfilePage.filter")}
              </span>
              <div className="mb-4 mt-4 lg:hidden">
                <TherapyTypeDrawer
                  services={specialist.services}
                  setSelectedService={setSelectedService}
                  selectedService={selectedService}
                />
              </div>
              <div className="hidden lg:inline-block">
                <Select onValueChange={onServiceChange} defaultValue={selectedService.id.toString()}>
                  <SelectTrigger
                    data-testid="therapy-type-select"
                    className="inline-flex h-auto max-w-xs rounded-lg px-2 py-2 text-start font-medium text-secondaryText focus:ring-0 focus:ring-offset-0"
                  >
                    <SelectValue>{selectedService.name}</SelectValue>
                  </SelectTrigger>
                  <SelectContent className="rounded-lg shadow-sm">
                    {specialist.services.map((service) => (
                      <SelectItem
                        key={service.id}
                        value={service.id.toString()}
                        className="font-medium text-secondaryText focus:bg-veryLightGray focus:text-secondaryText"
                      >
                        {service.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
          {!desktop && (
            <div className="overflow-x-auto">
              <div className="rounded-md">
                <div className="grid auto-cols-max grid-flow-col gap-4 py-4">
                  {Object.entries(slotsByDay).map(([dayStr, slots]) => {
                    const day = formatDate(dayStr);
                    const dayOfWeek = formatDayOfWeek(slots);
                    return (
                      <div className="flex flex-col gap-4" key={dayStr}>
                        <div>
                          <span className="block text-center font-bold text-primaryText">{`${day}`}</span>
                          <span className="block text-center text-[8px] font-bold uppercase leading-3 text-secondaryText">{`${dayOfWeek}`}</span>
                        </div>
                        {slots.map((slot) => {
                          return (
                            <SlotButton
                              slot={slot}
                              key={slot.start_time}
                              active={isActiveSlot(slot)}
                              onSlotClick={onSlotClick}
                            />
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {desktop && (
            <Carousel className="relative flex rounded-lg border bg-white px-4 py-8">
              <div className="flex w-8 flex-auto pt-5">
                <CarouselPrevious className="static" />
              </div>
              <CarouselContent>
                {chunks.map((chunk, index) => (
                  <CarouselItem key={index} className="start-left flex justify-center overflow-hidden">
                    <div
                      className={`grid grid-cols-6 gap-4 xl:gap-10 ${chunk.length < 6 ? "justify-start" : "justify-between"}`}
                    >
                      {chunk.map(([dayStr, slots]) => {
                        const day = formatDate(dayStr);
                        const dayOfWeek = formatDayOfWeek(slots);
                        return (
                          <div className="flex flex-col gap-4" key={dayStr}>
                            <div>
                              <span className="block text-center font-bold text-primaryText lg:text-base">{`${day}`}</span>
                              <span className="lg: block text-center text-[8px] font-bold uppercase leading-3 text-secondaryText lg:text-xs">{`${dayOfWeek}`}</span>
                            </div>
                            {slots.map((slot) => (
                              <SlotButton
                                slot={slot}
                                key={slot.start_time}
                                active={isActiveSlot(slot)}
                                onSlotClick={onSlotClick}
                              />
                            ))}
                          </div>
                        );
                      })}
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>
              <div className="flex w-8 flex-auto justify-end pt-5">
                <CarouselNext className="static" />
              </div>
            </Carousel>
          )}

          {activeSlot && (
            <div className="flex flex-col gap-2 lg:flex-row lg:justify-between lg:pt-6">
              <div className="mb-2 text-sm text-primaryText lg:text-secondaryText">
                {t("specialistProfilePage.book")}{" "}
                <span className="lg:font-bold">{formatDateWithTime(activeSlot.start_time)}</span>
              </div>
              <div className="flex gap-2">
                <Button className="px-6" onClick={handleClose}>
                  {t("specialistsPage.filters.close")}
                </Button>
                <Button
                  data-testid="schedule-session-button"
                  onClick={onScheduleSessionClick}
                  variant="yellow"
                  className="grow lg:grow-0 lg:px-12"
                >
                  {t("specialistsPage.specialistCard.scheduleSession")}
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export { SpecialistCalendarTab };
