import React, { useEffect, useState } from "react";

import { SessionCard } from "@/components";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { groupVisitsByMonth } from "@/lib/utils";

import { VisitDto } from "@/types/types";

export { SessionsList };

type SessionsListProps = {
  visits: VisitDto[];
  order?: "asc" | "desc";
  next_page?: number;
};

const SessionsList = React.memo<SessionsListProps>(({ visits, order = "asc" }: SessionsListProps) => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const groupedVisits = groupVisitsByMonth(visits, order);

  useEffect(() => {
    const handleLanguageChange = () => {
      setCurrentLanguage(i18n.language);
    };
    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  return (
    <>
      {Object.keys(groupedVisits).map((month) => {
        const date = DateTime.fromFormat(month, "yyyy-MM").setLocale(currentLanguage);
        const monthName = date.toLocaleString({ month: "long" });
        return (
          <div key={month}>
            <h3 className="my-2 font-normal capitalize text-secondaryText lg:mt-6 lg:text-base">{`${monthName} ${date.year}`}</h3>
            <div className="space-y-5 py-2 lg:w-full">
              {groupedVisits[month].map((visit) => (
                <SessionCard key={visit.id} visit={visit} />
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
});
