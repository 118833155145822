import { forwardRef } from "react";

import i18n from "@/i18n";
import { Link, LinkProps } from "react-router-dom";

const localisedPath = (path: string) => {
  const pathname = window.location.pathname;
  const langToUse = pathname.startsWith(`/${i18n.language}`) ? `/${i18n.language}` : "";
  return path.replace(/\/:lang\?/, langToUse);
};

const LocalisedLink = forwardRef<HTMLAnchorElement, LinkProps>(({ to, ...rest }, ref) => {
  const prefixedTo = localisedPath(String(to));

  return <Link to={prefixedTo} ref={ref} {...rest} />;
});

export { LocalisedLink, localisedPath };
